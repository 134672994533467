import React from "react";
import "./Shipping.css";
import { Container, Row, Col } from "react-bootstrap";
import CheckAppVersion from "../components/CheckAppVersion";
import Breadcrums from "../components/Breadcrums";

export default function Shipping() {

  return (
    <div className="Shipping main">
      <CheckAppVersion/>
      <Breadcrums path={[{name: 'Shipping & Returns'}]} />
      <Container>
        <Row>
          <Col sm={0} md={2}></Col>
          <Col sm={12} md={8} className="all">
            <h3>Shipping & Returns</h3>
            <hr className="short" />
            <span className="updated">Last updated: June 1, 2021</span>
            <ol>
            <li>Order processing</li>
            </ol>
            <p>All orders are processed within 1 to 2 business days (excluding weekends and holidays) after receiving your order confirmation email. You will receive another notification when your order has shipped.</p>
            <ol start="2">
            <li>Domestic Shipping Rates and Estimates</li>
            </ol>
            <p>Shipping charges for your order will be calculated and displayed at checkout.</p>
            <ol start="3">
            <li>Curbside pickup</li>
            </ol>
            <p>You can skip the shipping fees with free local pickup at our El Paso, TX East location. After placing your order and selecting local pickup at checkout, your order will be prepared and ready for pick up within 1 to 2 business days. We will send you an email when your order is ready along with instructions. Please have your order confirmation email with you when you come.</p>
            <ol start="4">
            <li>International Shipping</li>
            </ol>
            <p>We do not offer international shipping at this time. We only ship within the US.</p>
            <ol start="5">
            <li>How do I check the status of my order?</li>
            </ol>
            <p>When your order has shipped, you will receive an email notification from us which will include a tracking number you can use to check its status. Please allow 48 hours for the tracking information to become available.If you haven’t received your order within 10 days of receiving your shipping confirmation email, please contact us at support@cuatro.us with your name and order number, and we will look into it for you.</p>
            <ol start="6">
            <li>Refunds, returns, and exchanges</li>
            </ol>
            <p>We accept returns up to 15 days after shipping, if the item is unused and in its original condition, and we will refund the full order amount minus the shipping costs for the return. In the event that your order arrives damaged in any way, please email us as soon as possible at support@cuatro.us with your order number and a photo of the item’s condition. We address these on a case-by-case basis but will try our best to work towards a satisfactory solution. If you have any further questions, please don't hesitate to contact us at support@cuatro.us.</p>
          </Col>
          <Col sm={0} md={2}></Col>
        </Row>
      </Container>
    </div>
  );
}