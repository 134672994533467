import React, { useState, useEffect } from "react";
import logo from './logo.png';
import textlogo192 from './images/textlogo192.png';
import './App.css';
import Routes from "./Routes";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Badge from "react-bootstrap/Badge";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { LinkContainer } from "react-router-bootstrap";
import { AppContext } from "./lib/contextLib";
import { Auth, API } from "aws-amplify";
import { useHistory, Link, withRouter, useLocation } from "react-router-dom";
import { onError } from "./lib/errorLib";
import { GrDown, GrUp, GrMenu, GrClose } from "react-icons/gr";
import { FiShoppingBag } from "react-icons/fi";
import { RiAccountCircleLine } from "react-icons/ri";
import Footer from './components/Footer';
// start Pinterest
import ReactPinterestTag from "react-pinterest-tag";
if (ReactPinterestTag && ReactPinterestTag.verifyInit !== true) {
  ReactPinterestTag.init('2614416420730');
}
// end Pinterest

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function App() {
  const history = useHistory();
  const { search } = useLocation();
  let query = useQuery();
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [placeAddress, setPlaceAddress] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [cartCount, setCartCount] = useState(0);
  const [cartTotal, setCartTotal] = useState('');
  const [navbarExpanded, setNavbarExpanded] = useState(false);
  const [collapseMenuShopShow, setCollapseMenuShopShow] = useState(false);
  const [collapseMenuAboutShow, setCollapseMenuAboutShow] = useState(false);
  const [imageHash] = useState(Date.now());
  const [pageYOffset, setPageYOffset] = useState(0);
  const [isUserEmailVerified, setIsUserEmailVerified] = useState(true);
  useEffect(() => {
    // Repeats below
    const handleLogout = async() => {
      await Auth.signOut();
      userHasAuthenticated(false);
      history.push("/");
      fetchCartCount();
    }
    const onLoad = async() => {
      window.addEventListener("resize", handleResize);
      window.addEventListener("scroll", handleScroll);
      let deliveryPlace = localStorage.getItem('deliveryPlace')
      if (deliveryPlace) {
        deliveryPlace = JSON.parse(deliveryPlace)
        appSetDeliveryPlace(deliveryPlace);
      }
      try {
        await Auth.currentCredentials()
        var apiReturn = await API.post('carts', 'getcart', {
          body: { countOnly: true }
        }).then(response => response).catch(error => error)
        if (apiReturn && apiReturn.status) {
          setCartCount(apiReturn.quantityTotal);
        }
      } catch(e) {
        console.log('Error loading cart.')
      }
      try {
        await Auth.currentSession();
        userHasAuthenticated(true);
        //
        const currentUser = await Auth.currentAuthenticatedUser();
        const userAtts = await Auth.userAttributes(currentUser);
        const emailVerified = userAtts.find(el => el.Name === 'email_verified').Value;
        if (emailVerified === "false") {
          setIsUserEmailVerified(false);
        }
        //
      } catch (e) {
        if (e.code === 'UserNotFoundException') {
          handleLogout();
        } else if (e !== 'No current user') {
          onError(e);
        }
      }
      setIsAuthenticating(false);
      let referralcode = query.get("rc");
      if (referralcode) {
        apiReturn = await API.post('services', 'refalc', {
          body: { code: referralcode }
        }).then(response => response).catch(error => error);
        const queryParams = new URLSearchParams(search);
        queryParams.delete('rc');
        history.replace({
          search: queryParams.toString(),
        });
      }
    }
    const onUnload = async() => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    }
    onLoad();
    return () => {
      // Anything in here is fired on component unmount.
      onUnload();
    }
  }, [history, placeAddress]);
  async function getVersion() {
    let res = await API.post('services', 'getversion', {
      body: {
        type: 'version'
      }
    }).then(response => response).catch(error => null)
    if (res && res.Item && res.Item.value) {
      let version = res.Item.value
      return version
    }
    return null
  }
  async function fetchCartCount() {
    try {
      await Auth.currentCredentials()
      var apiReturn = await API.post('carts', 'getcart', {
        body: { countOnly: true }
      }).then(response => response).catch(error => error)
      if (apiReturn && apiReturn.status) {
        setCartCount(apiReturn.quantityTotal);
      }
    } catch(e) {
      console.log('Error loading cart.')
    }
  }
  // Repeats above
  async function handleLogout() {
    await Auth.signOut();
    userHasAuthenticated(false);
    history.push("/");
    fetchCartCount();
  }
  function handleResize(e) {
    setWindowWidth(window.innerWidth);
  }
  function handleScroll(e) {
    setPageYOffset(window.pageYOffset)
  }
  function updateCartCount(cartCount) {
    setCartCount(cartCount);
  }
  function updateCartTotal(cartTotal) {
    setCartTotal(cartTotal);
  }
  function appSetDeliveryPlace(deliveryPlace) {
    localStorage.setItem('deliveryPlace', JSON.stringify(deliveryPlace))
  }
  function appUnsetDeliveryPlace() {
    localStorage.removeItem('deliveryPlace');
    localStorage.removeItem('placeAddress');
    setPlaceAddress(null);
  }
  function appGetPlaceAddress() {
    let placeAddress = JSON.parse(localStorage.getItem('placeAddress'));
    setPlaceAddress(placeAddress);
  }
  function appSetPlaceAddress(placeAddress) {
    localStorage.setItem('placeAddress', JSON.stringify(placeAddress));
    // console.log('localStorage: ', localStorage);
    setPlaceAddress(placeAddress);
  }
  function toggleCollapseMenuShop() {
    setCollapseMenuAboutShow(false);
    collapseMenuShopShow ? setCollapseMenuShopShow(false) : setCollapseMenuShopShow(true);
  }
  function toggleCollapseMenuAbout() {
    setCollapseMenuShopShow(false);
    collapseMenuAboutShow ? setCollapseMenuAboutShow(false) : setCollapseMenuAboutShow(true);
  }
  function handleHomeOnCLickWhenHome() {
    if (window.location.pathname === '/') {
      window.location.reload()
    }
  }
  function hadleAppOnClick() {
    if (collapseMenuAboutShow) {
      setCollapseMenuAboutShow(false);
    }
    if (collapseMenuShopShow) {
      setCollapseMenuShopShow(false);
    }
  }
  function shopSubmenu() {
    let submenu = null
    if (collapseMenuShopShow) {
      submenu = (
        <div className="inset-submenu">
          <li className="item" onClick={hadleAppOnClick}><LinkContainer to="/collection/all-products" className="link" ><Nav.Link><h3 className="link">All Products</h3></Nav.Link></LinkContainer></li>
          <li className="item" onClick={hadleAppOnClick}><LinkContainer to="/collection/kitchen" className="link" ><Nav.Link><h3 className="link">Kitchen</h3></Nav.Link></LinkContainer></li>
          <li className="item" onClick={hadleAppOnClick}><LinkContainer to="/collection/personal-care" className="link" ><Nav.Link><h3 className="link">Personal Care</h3></Nav.Link></LinkContainer></li>
          <li className="item" onClick={hadleAppOnClick}><LinkContainer to="/collection/accessories" className="link" ><Nav.Link><h3 className="link">Accessories</h3></Nav.Link></LinkContainer></li>
          <li className="item" onClick={hadleAppOnClick}><LinkContainer to="/collection/bundles" className="link" ><Nav.Link><h3 className="link">Bundles</h3></Nav.Link></LinkContainer></li>
        </div>
      )
    }
    return submenu
  }
  function aboutSubmenu() {
    let submenu = null
    if (collapseMenuAboutShow) {
      submenu = (
        <div className="inset-submenu">
          <li className="item" onClick={hadleAppOnClick}><LinkContainer to="/about" className="link" ><Nav.Link><h3 className="link">Our Mission</h3></Nav.Link></LinkContainer></li>
          <li className="item" onClick={hadleAppOnClick}><LinkContainer to="/connect" className="link" ><Nav.Link><h3 className="link">Contact</h3></Nav.Link></LinkContainer></li>
        </div>
      )
    }
    return submenu
  }
  const navToggleBtn = navbarExpanded ? <Navbar.Toggle><GrClose/></Navbar.Toggle> : <Navbar.Toggle><GrMenu/></Navbar.Toggle>;
  const actToggleBtn = (
    <NavDropdown className="account" alignRight title={<RiAccountCircleLine className="icon" />}>
      {isAuthenticated ? (
        <>
          <LinkContainer to="/account">
            <NavDropdown.Item>Account</NavDropdown.Item>
          </LinkContainer>
          <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
        </>
      ) : (
        <>
          <LinkContainer to="/login">
            <NavDropdown.Item>Login</NavDropdown.Item>
          </LinkContainer>
        </>
      )}
    </NavDropdown>
  );

  return (
    !isAuthenticating && (
      <div className="App" onClick={hadleAppOnClick}>
        <Navbar expand="lg" fixed="top" collapseOnSelect onToggle={setNavbarExpanded} >
          {window.location.pathname === '/' && navbarExpanded !== true && pageYOffset < 144 &&
            <div className="header">
              <div className="wrap" onClick={handleHomeOnCLickWhenHome}>
                <img src={logo} className="logo" alt="logo" />
              </div>
            </div>
          }
          {window.location.pathname !== '/checkout' ?
            navToggleBtn
            :
            <Navbar.Text>
              {pageYOffset > 500 &&
              <div className="carttotal d-md-none">{cartTotal}</div>
              }
            </Navbar.Text>
          }
          <Navbar.Text className="center-logo-compact d-lg-none">
            <LinkContainer to="/" className="link" ><Nav.Link><img alt="" src={textlogo192} /></Nav.Link></LinkContainer>
          </Navbar.Text>
          <Navbar.Text className="d-lg-none">
            {window.location.pathname !== '/checkout' &&
              <LinkContainer to="/cart" className="cart" ><Nav.Link active={window.location.pathname === '/cart'}><div><FiShoppingBag className="shopping-bag" /><Badge pill variant={cartCount === 0 ? 'light' : 'dark'}>{cartCount}</Badge></div></Nav.Link></LinkContainer>
            }
            {actToggleBtn}
          </Navbar.Text>
          <Navbar.Collapse>
            <Nav className="menu d-none d-lg-block">
              <Container>
                <Row>
                  <Col xs={5} className="group">
                    <Row className="left">
                      <Col xs={6} className="item first">
                        {window.location.pathname !== '/checkout' &&
                          <h3 className="link cat" onClick={toggleCollapseMenuShop}>Shop <span className="updown">{collapseMenuShopShow ? <GrUp /> : <GrDown />}</span></h3>
                        }
                        {shopSubmenu()}
                      </Col>
                      <Col xs={6} className="item">
                        {window.location.pathname !== '/checkout' &&
                          <Link to="/wellness" onClick={e => setCollapseMenuShopShow(false)}><h3 className="link">Wellness</h3></Link>
                        }
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={2} className="group">
                    <div className="center-logo">
                      <Link to="/" onClick={e => setCollapseMenuShopShow(false)}><img alt="" src={textlogo192} /></Link>
                    </div>
                  </Col>
                  <Col xs={5} className="group">
                    <Row className="right">
                      <Col xs={6} className="item">
                        {window.location.pathname !== '/checkout' &&
                          <h3 className="link cat" onClick={toggleCollapseMenuAbout}>About <span className="updown">{collapseMenuAboutShow ? <GrUp /> : <GrDown />}</span></h3>
                        }
                        {aboutSubmenu()}
                      </Col>
                      <Col xs={6} className="item last">
                        {window.location.pathname !== '/checkout' &&
                          <LinkContainer to="/cart" className="cart" onClick={e => setCollapseMenuShopShow(false)}><Nav.Link active={window.location.pathname === '/cart'}><div><FiShoppingBag className="shopping-bag" /><Badge pill variant={cartCount === 0 ? 'light' : 'dark'}>{cartCount}</Badge></div></Nav.Link></LinkContainer>
                        }
                        {actToggleBtn}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </Nav>
            <Nav className="menucompact d-lg-none">
              <ul>
                {window.location.pathname !== '/checkout' &&
                  <div>
                    <li className="item" onClick={toggleCollapseMenuShop}><h3 className="link cat">Shop <span className="updown">{collapseMenuShopShow ? <GrUp /> : <GrDown />}</span></h3></li>
                    {shopSubmenu()}
                  </div>
                }
                <li className="item">
                  {window.location.pathname !== '/checkout' &&
                    <LinkContainer to="/wellness" className="link" ><Nav.Link><h3 className="link">Wellness</h3></Nav.Link></LinkContainer>
                  }
                </li>
                {window.location.pathname !== '/checkout' &&
                  <div>
                    <li className="item" onClick={toggleCollapseMenuAbout}><h3 className="link cat">About <span className="updown">{collapseMenuAboutShow ? <GrUp /> : <GrDown />}</span></h3></li>
                    {aboutSubmenu()}
                  </div>
                }
              </ul>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <AppContext.Provider value={{ 
          getVersion, imageHash, windowWidth,
          fetchCartCount, setCartCount, updateCartCount, updateCartTotal,
          isAuthenticated, userHasAuthenticated,
          isUserEmailVerified,
          appSetDeliveryPlace, appUnsetDeliveryPlace,
          placeAddress, appGetPlaceAddress, appSetPlaceAddress,
          pageYOffset
        }}>
          <Routes />
        </AppContext.Provider>
        <Footer />
        {(pageYOffset < 144 && window.location.pathname.toLowerCase().includes("wellness") !== true) &&
          <div className="free-shipping">
            <Container>
              <Row>
                <Col>
                  <span>FREE U.S. Shipping on orders over <span className="qty">$49</span></span>
                </Col>
              </Row>
            </Container>
          </div>
        }
      </div>
    )
  );
}

export default withRouter(App);
