import React, { Component } from 'react'
import { Modal, Button, Row, Col } from 'react-bootstrap'
import './AddedToCart.css'
import { Link } from "react-router-dom";
import { FiCheckCircle } from "react-icons/fi"

class AddedToCart extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentDidMount = () => {}
  handleOnHide = () => {}

  render() {
    return (
      <Modal
        className="AddedToCart"
        show={this.props.isAddedToCart}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={this.handleOnHide}
      >
        <Modal.Body>
          <div className="check"><FiCheckCircle /></div>
          <p>{this.props.qtyAdded} item(s) added to Order</p>
          <div className="buttons">
            <Row>
              <Col sm={6}>
                <Button className="continue-shop cuatro-back" variant="outline-dark" size="md" onClick={this.props.closeAddedToCart}>Continue Adding</Button>
              </Col>
              <Col sm={6}>
                <Link className="to-cart" to="/cart"><Button className="cuatro-primary" variant="outline-dark" size="md">Review Order & Checkout</Button></Link>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}

export default AddedToCart;