import React from "react";
import "./About.css";
import Container from "react-bootstrap/Container";
import CheckAppVersion from "../components/CheckAppVersion";
import Breadcrums from "../components/Breadcrums";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { RiCheckboxCircleFill, RiRefreshFill, RiCheckboxBlankCircleLine, RiExternalLinkLine } from "react-icons/ri";
import ReactMapboxGl, { ZoomControl, Marker } from "react-mapbox-gl";
import about_us_icon_use_life from '../images/about-us-icon-use-life.png';
import about_us_icon_convenience from '../images/about-us-icon-convenience.png';
import about_us_icon_fashion from '../images/about-us-icon-fashion.png';
import about_us_icon_recycling from '../images/about-us-icon-recycling.png';
import about_us_icon_quotation_mark from '../images/about-us-icon-quotation-mark.svg'; 
import about_us_icon_star from '../images/about-us-icon-star.png';
import about_us_photo_geedop_park from '../images/about-us-photo-geedop-park.jpg';
import about_us_icon_geedop_park from '../images/about-us-icon-geedop-park.png';
import about_us_icon_pin_geedop from '../images/about-us-icon-pin-geedop.png';

import mapboxgl from 'mapbox-gl';
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
const Map = ReactMapboxGl({
  accessToken: "pk.eyJ1Ijoicm5hdmEiLCJhIjoiY2lpdGU2dWxuMDNlNnVxbTVtN3hzb3M5ZiJ9.22ggfMIwj7IauaUb2XFsIA",
  dragRotate: false,
  dragPan: false,
  scrollZoom: false,
  doubleClickZoom: false,
  touchZoomRotate: false
});

export default function About() {

  return (
    <div className="About main">
      <CheckAppVersion/>
      <Breadcrums path={[{name: 'About Us'}]} />
      <Container>
        <Row className="meetcuatro">
          <Col>
            <h3>Meet Cuatro</h3>
            <hr className="short" />
            <Row>
              <Col lg={2}></Col>
              <Col lg={8}>
                <div className="mission">
                  <span className="subtitle">Our Mission:</span>
                  <h4>Remove the plastic from the products we use every day and the packaging they come in.</h4>
                </div>
                <div className="mt-5"></div>
                <p className="center larger">The incorporation of plastic into our personal lives has been transforming our interactions with the products we use for a long time. Plastic’s malleability and strength allow for considerable reduction in the amount of material used in each product, and for mass-producing these products. This results in cheaper products with sufficient strength for their intended use, but not strong enough for prolonged use; they are, therefore, thrown away after a few uses.</p>
                <div className="mt-4"></div>
              </Col>
              <Col lg={2}></Col>
            </Row>
            <Row className="mt-4">
              <Col className="center">
                <h3 className="fourthings">4 Things</h3>
                <h4>..we consider when replacing plastic in our products:</h4>
              </Col>
            </Row>
            <Row className="missiondets mt-3">
              <Col md={6}>
                <div className="numicon"><img alt="" src={about_us_icon_use_life} /></div>
                <div className="pillwrap"><div className="numpill"><h5>1. Use-life</h5></div></div>
                <p>The short use-life of products for food consumption, hygiene, or essentially anything where a product is used to interact with our bodies, can be beneficial. For example, thoroughly washing non-disposable items can be difficult, which can lead to disease if anything on them remains after each use. Also, washing can be time-consuming, which may inconvenience a lot of the life interactions we have in our fast-paced environments.</p>
              </Col>
              <Col md={6}>
                <div className="numicon"><img alt="" src={about_us_icon_convenience} /></div>
                 <div className="pillwrap"><div className="numpill"><h5>2. Convenience</h5></div></div>
                <p>When replacing a plastic-made product, we don’t want to overlook the aspects of plastic that provide its convenience. We understand that developing products with non-plastic materials can radically alter the way we interact with them and can actually introduce inconvenience to our day-to-day activities. When moving products to a different material we’ll go for small convenience compromises, to no compromise at all — and even to making them more convenient where possible. We’re aiming at improving our lives, not dying of boredom, or clutter.</p>
              </Col>
            </Row>
            <Row className="missiondets">
              <Col md={6}>
                <div className="numicon"><img alt="" src={about_us_icon_fashion} /></div>
                 <div className="pillwrap"><div className="numpill"><h5>3. Fashion</h5></div></div>
                <p>Everything in the physical world goes through cycles of renewal. Fashion, as it relates to personal expression, is a renewal cycle that is very well aligned with most general world patterns. It is therefore understandable that personal products be discarded for new ones that alter our expression, even when the product we discard remains functional. Life is not static; movement and change are the norm. Products that are still functional and are discarded for new ones can be reused by others — with some exceptions in personal care, hygiene, medicine and nourishment.</p>
              </Col>
              <Col md={6}>
                <div className="numicon"><img alt="" src={about_us_icon_recycling} /></div>
                 <div className="pillwrap"><div className="numpill"><h5>4. Recycling</h5></div></div>
                <p>The recycling industry has undergone many improvements over time. The incorporation of highly-recyclable plastics into products can play a big role in the way we can continue to create products without further implications to the natural environment. Plastic still remains a very good material for a lot of use cases. We, however, begin our focus on making sure that new products we create are plastic-free, and move from there to other areas like recycling and reusability as a secondary step.</p>
              </Col>
            </Row>
            <Row>
              <Col lg={2}></Col>
              <Col lg={8}>
                <div className="mt-5"></div>
                <p className="center larger">Cuatro™ strives to be a developer and provider of plastic-less products that improve our life in the short and long terms, keeping in mind that the place to start moving away from plastic is the place where we are in our individual lives, at this particular point in time.</p>
                <div className="quote">
                  <img alt="" src={about_us_icon_quotation_mark} />
                  <h4>We, ourselves, do not want to trash ourselves.</h4>
                  <div className="source">Cuatro Team</div>
                </div>
              </Col>
              <Col lg={2}></Col>
            </Row>       
          </Col>
        </Row>
      </Container>
      <div className="mt-5 pt-4"></div>
      <Container className="showroom">
        <Row>
          <Col>
            <div className="asterisk"><img alt="" src={about_us_icon_star} /></div>
            <span className="subtitle center">Our Showroom in the Desert</span>
          </Col>
        </Row>
      </Container>
      <Container fluid className="pano">
        <div className="mt-4"></div>
        <div className="parklanding" style={{ backgroundImage: `url("` + about_us_photo_geedop_park + `")`}}>
          <div className="iso">
            <img alt="" src={about_us_icon_geedop_park} />
            <div className="coming"><h5>Coming Soon</h5></div>
          </div>
        </div>
        <div className="mt-5"></div>
      </Container>
      <Container className="showroom">
        <Row>
          <Col md={2}></Col>
          <Col md={8}>
            <p className="center larger">We want to show our products to all who want to incorporate them into their lives in a way that conveys the thought we put behind every design decision.</p>
          </Col>
          <Col md={2}></Col>
        </Row>
        <div className="mt-5"></div>
        <Row>
          <Col md={6}>
            <p>We’re building our showroom in El Paso, our home, for anyone to stop by and physically interact with plastic-free items that are, in most cases, lifestyle altering. The only way we can truly transmit how we intend our products to be used is by placing them in the context we, ourselves, are using them in.</p>
            <p>Cuatro’s showroom will be part of Geedop® Park, a collection of buildings made with  materials and technologies that bring together many aspects of sustainability into a physical space.</p>
            <Row className="elpaso">
              <Col>
                <Map
                  className="mt-4"
                  style={`mapbox://styles/rnava/ckvztv8hl6he114p80nuwjc3t`}
                  mapboxApiAccessToken={'pk.eyJ1Ijoicm5hdmEiLCJhIjoiY2lpdGU2dWxuMDNlNnVxbTVtN3hzb3M5ZiJ9.22ggfMIwj7IauaUb2XFsIA'}
                  containerStyle={{
                    height: "25vh",
                    width: "100%"
                  }}
                  center={[-106.492806, 31.77]}
                  zoom={[12.5]}
                  pitch={[80]}
                  >
                  <ZoomControl/>
                  <Marker
                    coordinates={[-106.49514439534454, 31.770162721947404]}
                    anchor="bottom">
                    <img alt="" src={about_us_icon_pin_geedop}/>
                  </Marker>
                </Map>
              </Col>
            </Row>
            <div className="mt-5"></div>
            <p>Geedop® Park will integrate advancements in green energy, environmental design, and will meet high green building standards. The Park's aims are set on:</p>
            <ul>
              <li><a target="_blank" rel="noopener noreferrer" href="https://www.energy.gov/eere/buildings/zero-energy-buildings" >Net-Zero Energy<RiExternalLinkLine/></a> building</li>
              <li><a target="_black" rel="noopener noreferrer" href="https://www.usgbc.org/leed" >LEED Platinum<RiExternalLinkLine/></a> certification</li>
              <li>Energy efficient climate control</li>
              <li>Locally sourced building materials</li>
              <li>Water treatment and reuse</li>
            </ul>
            <div className="mt-5 pt-3"> </div>
          </Col>
          <Col md={6}>
            <Row>
              <Col lg={1}></Col>
              <Col lg={10}>
                <div className="actionplan">
                  <div className="cardTop"></div>
                  <div className="tlwrap"><div className="in"></div></div>
                  <div className="timeline">
                    <h4>Geedop Park</h4>
                    <h3>Action Plan</h3>
                    <div className="box">
                      <div className="contain">
                        <div className="lines">
                          <div className="line first"></div>
                          <div className="dot"></div>
                          <div className="line"></div>
                          <div className="dot"></div>
                          <div className="line"></div>
                          <div className="dot"></div>
                          <div className="line"></div>
                          <div className="dot"></div>
                          <div className="line"></div>
                          <div className="dot"></div>
                          <div className="line last"></div>
                        </div>
                        <div className="cars">
                          <div className="car phase">
                            <span className="name">Phase 1</span>
                          </div>
                          <div className="car">
                          <div className="status"><RiCheckboxCircleFill/></div>
                            <span className="date">2021-08</span>
                            <p>Project definition</p>
                          </div>
                          <div className="car mid">
                            <div className="status"><RiRefreshFill/></div>
                            <span className="date">2022-02</span>
                            <p>Space requirements</p>
                          </div>
                          <div className="car">
                            <div className="status"><RiRefreshFill/></div>
                            <span className="date">2022-02</span>
                            <p>Space dimensions</p>
                          </div>
                          <div className="car">
                            <div className="status"><RiCheckboxBlankCircleLine/></div>
                            <span className="date">2022-02</span>
                            <p>Architectural program (a.k.a. Wish List)</p>
                          </div>
                          <div className="car phase">
                            <span className="name">Phase 2</span>
                          </div>
                          <div className="car">
                            <div className="status"><RiCheckboxBlankCircleLine/></div>
                            <span className="date">2022-04</span>
                            <p>Drafts: Interior, facades, represetation in context (desert)</p>
                          </div>
                          <div className="car phase">
                            <span className="name">Phase 3</span>
                          </div>
                          <div className="car">
                            <div className="status"><RiCheckboxBlankCircleLine/></div>
                            <span className="date">2022</span>
                            <p>Project location</p>
                          </div>
                          <div className="car">
                            <div className="status"><RiCheckboxBlankCircleLine/></div>
                            <span className="date">2022-2023</span>
                            <p>Financing</p>
                          </div>
                          <div className="car phase">
                            <span className="name">Phase 4</span>
                          </div>
                          <div className="car">
                            <div className="status"><RiRefreshFill/></div>
                            <span className="date">2023</span>
                            <p>Property and context analyses</p>
                          </div>
                          <div className="car phase">
                            <span className="name">Phase 5</span>
                          </div>
                          <div className="car">
                            <div className="status"><RiCheckboxBlankCircleLine/></div>
                            <span className="date">2023</span>
                            <p>Analysis of local ordinances</p>
                          </div>
                          <div className="car">
                            <div className="status"><RiCheckboxBlankCircleLine/></div>
                            <span className="date">2023</span>
                            <p>Analysis of local sustainability guidelines</p>
                          </div>
                          <div className="car">
                            <div className="status"><RiCheckboxBlankCircleLine/></div>
                            <span className="date">2023</span>
                            <p>Zoning</p>
                          </div>
                          <div className="car phase">
                            <span className="name">Phase 6 - Blueprint</span>
                          </div>
                          <div className="car">
                            <div className="status"><RiCheckboxBlankCircleLine/></div>
                            <span className="date">2023</span>
                            <p>Architectural plan, elevations, vertical profiles, persepectives, renders and digital flybys</p>
                          </div>
                          <div className="car phase">
                            <span className="name">Phase 7</span>
                          </div>
                          <div className="car">
                            <div className="status"><RiCheckboxBlankCircleLine/></div>
                            <span className="date">2023</span>
                            <p>Descriptive report</p>
                          </div>
                          <div className="car">
                            <div className="status"><RiCheckboxBlankCircleLine/></div>
                            <span className="date">2024</span>
                            <p>Project ready for execution</p>
                          </div>
                          <div className="car phase">
                            <span className="name">Phase 8</span>
                          </div>
                          <div className="car">
                            <div className="status"><RiCheckboxBlankCircleLine/></div>
                            <span className="date">2024</span>
                            <p>Permitting</p>
                          </div>
                          <div className="car">
                            <div className="status"><RiCheckboxBlankCircleLine/></div>
                            <span className="date">2024</span>
                            <p>Construction begins</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={1}></Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}