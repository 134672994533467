import React, { useState, useEffect } from "react";
import config from "../config";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CardForm from "../components/CardForm";
import { useHistory, useLocation } from "react-router-dom";
import Breadcrums from "../components/Breadcrums";
import { Container, Row, Col } from "react-bootstrap";
import "./AddCard.css";
import CheckAppVersion from "../components/CheckAppVersion";
const stripePromise = loadStripe(config.billing.STRIPE_KEY);

export default function AddCard() {
  const history = useHistory();
  const location = useLocation();
  const [clientSecret, setClientSecret] = useState(null);

  useEffect(() => {
    if (location.state && location.state.cs) {
      setClientSecret(location.state.cs)
    } else {
      history.push('/account');
    }
  }, [location, history]);

  const appearance = {
    theme: 'flat',
    variables: {
      fontFamily: 'Jost',
      fontSizeBase: '18px',
      borderRadius: '0',
      colorBackground: 'white',
      colorPrimary: '#495057',
      colorPrimaryText: 'white',
    },
    rules: {
      '.Label': {
        marginBottom: '10px'
      },
      '.Tab, .Input, .Block': {
        border: '1px solid #ced4da',
        boxShadow: 'none',
        padding: '6px 10px'
      },
      '.Input': {
        fontSize: '16px'
      }
    }
  };

  const fonts = [
    { 
      cssSrc: "https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap",
      family: "Jost",
      style: 'normal' 
    }
  ]

  return (
    <div className="AddCard main">
      <CheckAppVersion/>>
      <Breadcrums path={[{name: 'Account', to: '/account'}, {name: 'Add Card'}]} />
      <Container>
        <Row>
          <Col sm={2}></Col>
          <Col sm={8}>
            <div className="narrow">
              <h3>Add Card</h3>
              {clientSecret &&
                <Elements
                  stripe={stripePromise}
                  options={{clientSecret: clientSecret, appearance, fonts }}
                >
                  <CardForm setClientSecret={setClientSecret}/>
                </Elements>
              }
            </div>
          </Col>
          <Col sm={2}></Col>
        </Row>
      </Container>
    </div>
  );
}