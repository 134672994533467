import React, { useState, useEffect } from "react";
import "./Cuatro.css";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import CheckAppVersion from "../components/CheckAppVersion";
import Breadcrums from "../components/Breadcrums";
import { useParams, useHistory, useLocation, Link } from "react-router-dom";
import { API } from "aws-amplify";
import { useWindowWidth } from "@react-hook/window-size";
import { useAppContext } from "../lib/contextLib";
import { webMedia } from "../components/Constants";
import { FaSpinner } from 'react-icons/fa';

const sortOptions = [
  {id: 'price-descending', desc: 'Price, high to low'},
  {id: 'price-ascending', desc: 'Price, low to high'},
  {id: 'title-ascending', desc: 'Alphabetically, A-Z'},
  {id: 'title-descending', desc: 'Alphabetically, Z-A'},
  {id: 'created-descending', desc: 'New Arrivals'}
]

export default function Cuatro(props) {
  const { imageHash } = useAppContext();
  const history = useHistory();
  const location = useLocation();
  const windowWidth = useWindowWidth();
  const { collid } = useParams();
  const [collection, setCollection] = useState(null);
  const [products, setProducts] = useState([]);
  const [sort, setSort] = useState(sortOptions[2]);

  useEffect(() => {

    async function doIt() {
      setCollection(null);
      setProducts([]);
      let sortUrl = new URLSearchParams(location.search).get("sort");
      let sortId = null
      if (sortUrl) {
        let sort = sortOptions.find(o => o.id === sortUrl)
        if (sort) {
          sortId = sort.id
          setSort(sort);
          let pLineRes = await getPLine(collid, sortId);
          let { pproducts, collection } = pLineRes;
          setProducts(pproducts);
          setCollection(collection);
        }
      } else {
        sortId = sort.id
        history.replace({
          search: '?sort=' + sortId
        })
      }
    }
    doIt();
  }, [location, history, collid, sort]);

  async function getPLine(collid, sortId) {
    try {
      let apiReturn = await API.post('products', 'getpline', {
        body: { collid, sortId }
      }).then(response => response).catch(error => error)
      return apiReturn
    } catch (e) {
      console.log(e)
    }
  }

  async function handleSortOnSelect(e) {
    let psort = sortOptions.find(o => o.id === e)
    setSort(psort);
    setProducts([]);
    history.replace({ search: '?sort=' + psort.id })
    // await loadProducts(collection, psort.id)
  }

  function renderProductsMobile() {
    return (
      <Container className="product-categories-mobile">{
        products.map((product, pk) => {
          if (product) {
            let { id, images, multi, price, title } = product
            return (
              <Link key={pk} to={'/collection/' + collid + '/' + id}>
                <Row className="product">
                  <Col xs={6} className="preview-wrap">
                    <div className="preview">
                      <img key={pk} src={images[0] ? webMedia + '/340x340/' + images[0] + '?' + imageHash : 'https://via.placeholder.com/340x340?' + imageHash} alt="" />
                    </div>
                  </Col>
                  <Col xs={6} className="desc-col">
                    <Row>
                      <Col>
                        <div className="desc">
                          <div className="title">{title}</div>
                          <div className="price-tag">
                            {multi &&
                              <span className="from">from</span>
                            }
                            <span className="price">${price.toFixed(2)}</span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Link>
            )
          }
          return null
        })
      }</Container>
    )
  }

  function renderProductsDesktop() {
    return(
      <Container className="product-categories">
        <Row className="products">{
          products.map((product, pk) => {
            if (product) {
              let { id, images, multi, price, title } = product
              return (
                <Col key={pk} sm={4} className="product">
                  <Link to={'/collection/' + collid + '/' + id}>
                    <div className="preview">
                      <img key={pk} src={images[0] ? webMedia + '/340x340/' + images[0] + '?' + imageHash : 'https://via.placeholder.com/340x340?' + imageHash} alt="" />
                    </div>
                    <div className="desc">
                      <span className="title">{title}</span>
                      <div className="price-tag">
                        {multi &&
                          <span className="from">from </span>
                        }
                        <span className="price"> ${price.toFixed(2)}</span>
                      </div>
                    </div>
                  </Link>
                </Col>
              )
            }
            return null
          })
        }</Row>
        <Row className="last-row"></Row>
      </Container>
    )
  }

  function renderProducts() {
    if (windowWidth <= 575) {
      return renderProductsMobile()
    } else {
      return renderProductsDesktop()
    }
  }

  let collDesc = collection ? collection.desc : null;

  return (
    <div className="Cuatro main">
      <CheckAppVersion/>
      <Breadcrums path={[{ name: collid.replace(/-/g, " ") }]} />
      <Container>
        <Row>
          <Col>
            <h2 className="collection-header">{collection ? collection.title : ''}</h2>
            {collDesc &&
              <p className="collection-desc">{collDesc}</p>
            }
          </Col>
        </Row>
        {products && products.length > 0 &&
          <Row>
            <Col className="head-tools">
              <Dropdown alignRight={true} onSelect={handleSortOnSelect}>
                <Dropdown.Toggle size="sm" variant="outline-dark" id="dropdown-x1">
                  {'Sort: ' + sort.desc}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {sortOptions.map((sItem, idx) => {
                    return(
                      <Dropdown.Item key={sItem.id} eventKey={sItem.id}>{sItem.desc}</Dropdown.Item>
                    )
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
        }
      </Container>
      {products && products.length > 0 ?
        renderProducts()
      :
        <Container>
          <Row>
            <Col xs={3}></Col>
            <Col xs={6}>
              <div className="loadingPs">
                <div className="loading">
                  <FaSpinner className="spin" />
                </div>
              </div>
            </Col>
            <Col xs={3}></Col>
          </Row>
        </Container>
      }
    </div>
  );
}