import React from "react";
import "./WellnessProfile.css";
import CheckAppVersion from "../components/CheckAppVersion";
import Breadcrums from "../components/Breadcrums";
import { Container, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";

export default function Wellness() {
  const history = useHistory();
  
  return (
    <div className="WellnessProfile main">
      <CheckAppVersion/>
      <Breadcrums path={[{ name: "Wellness", to: "/wellness" }, { name: "Profile" }]} />
      <Container>
        <Row className="">
          <Col>
            <h3 className="">Profile</h3>
          </Col>
        </Row>
      </Container>
    </div>
  );
}