import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import config from './config';
import ScrollToTop from './components/ScrollToTop';

var domain = 'localhost'
var secure = false

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // dev code
  domain = 'localhost'
  secure = true
} else {
  if (process.env.REACT_APP_STAGE === 'dev') {
    domain = 'dev.cuatro.us'
    secure = true
  } else if (process.env.REACT_APP_STAGE === 'prod')  {
    domain = 'www.cuatro.us'
    secure = true
  }
}

Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    // OPTIONAL - Configuration for cookie storage
    cookieStorage: {
    // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        domain: domain,
    // OPTIONAL - Cookie path
        path: '/',
    // OPTIONAL - Cookie expiration in days
        expires: 1,
    // OPTIONAL - Cookie secure flag
        secure: secure
    },
  },
  API: {
    endpoints: [
      {
        name: "carts",
        endpoint: config.api.URL,
        region: config.api.REGION
      },
      {
        name: "products",
        endpoint: config.api.URL,
        region: config.api.REGION
      },
      {
        name: "billing",
        endpoint: config.api.URL,
        region: config.api.REGION
      },
      {
        name: "services",
        endpoint: config.api.URL,
        region: config.api.REGION
      },
      {
        name: "customer",
        endpoint: config.api.URL,
        region: config.api.REGION
      }
    ]
  },
  Storage: {
    AWSS3: {
      bucket: config.storage.BUCKET, //REQUIRED -  Amazon S3 bucket
      region: config.storage.REGION, //OPTIONAL -  Amazon service region
    }
  }
});

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ScrollToTop />
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
