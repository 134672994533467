import React from "react"
import { InputGroup, Button, FormControl } from "react-bootstrap"
import "./QuantityControl.css"
import { FiChevronUp, FiChevronDown } from "react-icons/fi"

const QuantityControl = ({onUp, onDown, disabledDown = false, disabledUp = false, disabledText = false, quantity = 1, onQtyChange, ...props}) =>
  <InputGroup size="sm">
    <InputGroup.Prepend>
      <Button disabled={disabledDown} variant="outline-dark" onClick={onDown}><FiChevronDown /></Button>
    </InputGroup.Prepend>
    <FormControl type="text" className="qty-value" value={quantity} onChange={onQtyChange} disabled={disabledText} />
    <InputGroup.Append>
      <Button disabled={disabledUp} variant="outline-dark" onClick={onUp}><FiChevronUp /></Button>
    </InputGroup.Append>
  </InputGroup>;
  
export default QuantityControl;