import React, { Component } from "react";
import Slider from "react-slick";
import "./ProductImageSlider.css";

export default class ProductImageSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }

  renderCarouselItemsNThumbs = () => {
    let carouselItems = [];
    let carouselThumbs = [];
    let { images, source, imageHash } = this.props;
    for (var i = 0; i < images.length; i++) {
      carouselItems.push(
        <div key={i}>
          <img src={source + '/550x550/' + images[i] + '?' + imageHash} alt="" />
        </div>
      )
      carouselThumbs.push(
        <div key={i}>
          <img src={source + '/150x150/' + images[i] + '?' + imageHash} alt="" />
        </div>
      )
    }
    if (carouselItems.length === 0) {
      carouselItems.push(
        <div key={i}>
          <img src={'https://via.placeholder.com/481x481?' + imageHash} alt="" />
        </div>
      )
      carouselThumbs.push(
        <div key={i}>
          <img src={'https://via.placeholder.com/150x150?' + imageHash} alt="" />
        </div>
      )
    }
    return { carouselItems, carouselThumbs };
  }

  render() {
    let { carouselItems, carouselThumbs } = this.renderCarouselItemsNThumbs();
    return (
      <div className="ProductImageSlider">
        <Slider
          className="one"
          asNavFor={this.state.nav2}
          ref={slider => (this.slider1 = slider)}
          arrows={false}
        >
          {carouselItems}
        </Slider>
        <Slider
          className="two"
          asNavFor={this.state.nav1}
          ref={slider => (this.slider2 = slider)}
          arrows={false}
          infinite={false}
          rows={1}
          swipeToSlide={true}
          focusOnSelect={true}
          slidesToShow={6}
          responsive={[
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 5,
              }
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 4,
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 6,
              }
            },
            {
              breakpoint: 576,
              settings: {
                slidesToShow: 5,
              }
            }
          ]}
        >
          {carouselThumbs}
        </Slider>
      </div>
    );
  }
}