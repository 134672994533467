import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { API } from "aws-amplify";
import { FormText, FormGroup, FormControl, FormLabel, Container, Row, Col } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import Breadcrums from "../components/Breadcrums";
import { useFormFields } from "../lib/hooksLib";
import { onError } from "../lib/errorLib";
import CheckAppVersion from "../components/CheckAppVersion";
import "./ChangeEmail.css";

// https://serverless-stack.com/chapters/allow-users-to-change-their-email.html

export default function ChangeEmail() {
  const history = useHistory();
  const [codeSent, setCodeSent] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    code: "",
    email: "",
  });
  const [isConfirming, setIsConfirming] = useState(false);
  const [isEmailChanging, setIsEmailChanging] = useState(false);

  function validateEmailForm() {
    return fields.email.length > 0;
  }

  function validateConfirmForm() {
    return fields.code.length > 0;
  }

  async function handleChangeEmailClick(event) {
    event.preventDefault();
    setIsEmailChanging(true);
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, { email: fields.email });
      await customerUpdateEmail(fields.email);
      setCodeSent(true);
    } catch (error) {
      onError(error);
    }
    setIsEmailChanging(false);
  }

  async function handleConfirmClick(event) {
    event.preventDefault();
    setIsConfirming(true);
    try {
      await Auth.verifyCurrentUserAttributeSubmit("email", fields.code);
      history.push("/account");
    } catch (error) {
      onError(error);
    }
    setIsConfirming(false);
  }

  async function customerUpdateEmail(email) {
    const customerCall = await API.post("customer", "customer", {
      body: {
        method: 'update',
        email: email
      }
    });
    if (customerCall.status) {
      alert('Email successfully changed.');
    } else {
      alert('There was an error. Please contact support or try later.');
    }
  }

  function renderUpdateForm() {
    return (
      <form onSubmit={handleChangeEmailClick}>
        <FormGroup controlId="email">
          <FormLabel>New Email</FormLabel>
          <FormControl
            autoFocus
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <div className="feedback error"></div>
        <LoaderButton
          block
          className="cuatro-primary"
          variant="outline-dark"
          disabled={!validateEmailForm()}
          type="submit"
          size="md"
          isLoading={isEmailChanging}
          text={'Change'}
          loadingText="Changing.."
        />
      </form>
    );
  }

  function renderConfirmationForm() {
    return (
      <form onSubmit={handleConfirmClick}>
        <FormGroup controlId="code">
          <FormLabel>Confirmation Code</FormLabel>
          <FormControl
            autoFocus
            type="tel"
            value={fields.code}
            onChange={handleFieldChange}
          />
          <div className="feedback error"></div>
          <FormText>
            Please check your email ({fields.email}) for the confirmation code.
          </FormText>
        </FormGroup>
        <LoaderButton
          block
          className="cuatro-primary"
          variant="outline-dark"
          disabled={!validateConfirmForm()}
          type="submit"
          size="md"
          isLoading={isConfirming}
          text={'Confirm'}
          loadingText="Confirming.."
        />
      </form>
    );
  }

  return (
    <div className="ChangeEmail main">
      <CheckAppVersion/>
      <Breadcrums path={[{name: 'Account', to: '/account'}, {name: 'Change Email'}]} />
      <Container>
        <Row>
          <Col sm={2}></Col>
          <Col sm={8}>
            <div className="narrow">
              <h3>Change Email</h3>
              {!codeSent ? renderUpdateForm() : renderConfirmationForm()}
            </div>
          </Col>
          <Col sm={2}></Col>
        </Row>
      </Container>
    </div>
  );
}