import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import LoaderButton from "./LoaderButton";
import { useFormFields } from "../lib/hooksLib";
import { FaSpinner } from 'react-icons/fa';
import "./CardForm.css";

export default function CardForm({ isLoading, onSubmit, setClientSecret }) {
  const stripe = useStripe();
  const elements = useElements();
  const [fields, handleFieldChange] = useFormFields({
    name: ""
  });
  const [isProcessing, setIsProcessing] = useState(false);
  const [isCardComplete, setIsCardComplete] = useState(false);
  const [isElemLoading, setIsElemLoading] = useState(true);
  isLoading = isProcessing || isLoading;

  useEffect(() => {

  }, []);

  function validateForm() {
    return (
      stripe &&
      elements &&
      fields.name !== "" &&
      isCardComplete
    );
  }

  async function handleSubmitClick(event) {
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    setIsProcessing(true);
    const { error } = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: window.location.href,
        payment_method_data: {
          billing_details: {
            name: fields.name,
          }
        }
      },

    });
    if (error) {
      alert(error.message);
    } else {
      
    }
    setIsProcessing(false);
  }

  function handlePayElemOnReady() {
    setIsElemLoading(false)
  }

  const elStyle = {
    base: {
      fontFamily: 'Jost'
    }
  };

  function renderCardForm() {
    return (
      <Form onSubmit={handleSubmitClick}>
        {!isElemLoading &&
          <Form.Group size="lg" controlId="name" className="cardHsN">
            <Form.Label>Cardholder&apos;s name</Form.Label>
            <Form.Control
              type="text"
              value={fields.name}
              onChange={handleFieldChange}
              placeholder="Name on the card"
            />
          </Form.Group>
        }
        <PaymentElement
          onReady={() => handlePayElemOnReady()}
          className="card-field"
          onChange={(e) => setIsCardComplete(e.complete)}
          options={{
            style: elStyle,
            wallets: {
              applePay: 'never',
              googlePay: 'never'
            }
          }}
        />
        {!isElemLoading &&
          <LoaderButton
            block
            className="cuatro-primary"
            variant="outline-dark"
            disabled={!validateForm()}
            type="submit"
            size="md"
            isLoading={isLoading}
            text={'Add Card'}
            loadingText="Processing.."
          />
        }
      </Form>
    )
  }


  return (
    <div className="CardForm">
      {isElemLoading &&
        <div className="loading">
          <FaSpinner className="spin" />
        </div>
      }
      <>
        {renderCardForm()}
      </>
    </div>
  );
}