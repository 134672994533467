import React, { useState, useEffect } from "react";
import "./Connect.css";
import { Container, Row, Col, FormGroup, FormControl } from "react-bootstrap";
import CheckAppVersion from "../components/CheckAppVersion";
import Breadcrums from "../components/Breadcrums";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../lib/hooksLib";
import { onError } from "../lib/errorLib";
import { xValidateEmail } from "../components/XFunctions"
import { webSource } from '../components/Constants'
import { API } from "aws-amplify";

export default function Connect() {
  const [contactConfirmation, setContactConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    yourName: "",
    yourEmail: "",
    yourMessage: "",
  });

  useEffect(() => {

  }, []);

  function validateForm() {
    return (
      fields.yourName.length > 0 &&
      fields.yourEmail.length > 0 &&
      fields.yourMessage.length > 0
    );
  }

  async function handleSubmitClick(e) {
    e.preventDefault();
    setIsLoading(true);
    let { yourName, yourEmail, yourMessage } = fields;
    try {
      let contactCall = await API.post('services', 'contactus', {
        body: {
          yourName,
          yourEmail,
          yourMessage,
          auxOrigin: window.location.origin,
          auxLogo: webSource + 'branding/logoemail.png'
        }
      });
      setContactConfirmation(contactCall.contactConfirmation);
    } catch (err) {
      onError(err);
      setIsLoading(false);
    }

  }

  return (
    <div className="Connect main">
      <CheckAppVersion/>
      <Breadcrums path={[{name: 'Contact'}]} />
      <Container>
        <Row>
          <Col sm={2}></Col>
          <Col sm={8} className="contactcontent">
            <h3>Contact Us</h3>
            <hr className="short" />
            {contactConfirmation ?
              <div className="confirmation">
                <p className="message">Thank you for your message. We'll be in touch shortly!</p>
                <p>Contact Confirmation # <span className="number">{contactConfirmation}</span></p>
              </div>
            :
            <div>
              <div className="talk">
                <p>Do you have a general question, comment or concern?<br/>We are here to help</p>
                <p>Our customer service hours are 9AM - 5PM MT Monday - Friday<br/>We'll get back to you as soon as we can!</p>
              </div>
              <form className="ContactForm" onSubmit={handleSubmitClick}>
                <Row>
                  <FormGroup as={Col} sm={6} controlId="yourName">
                    <FormControl size="md" type="text" placeholder="Your Name" onChange={handleFieldChange} />
                    <div className="feedback error"></div>
                  </FormGroup>
                  <FormGroup as={Col} sm={6} controlId="yourEmail">
                    <FormControl size="md" type="email" placeholder="Your Email" onChange={handleFieldChange} />
                    <div className="feedback error">{fields.yourEmail && !xValidateEmail(fields.yourEmail) ? 'Enter a valid email: name@example.com' : ' '}</div>
                  </FormGroup>
                </Row>
                <Row>
                  <FormGroup as={Col} controlId="yourMessage">
                    <FormControl size="md" as="textarea" maxLength="500" rows="6" placeholder="Your message..." onChange={handleFieldChange} />
                    <div className="feedback text-muted text-right">Maximum 500 characters</div>
                  </FormGroup>
                </Row>
                <Row>
                  <FormGroup as={Col}>
                    <LoaderButton
                      className="cuatro-primary"
                      size="md"
                      variant="outline-dark"
                      block
                      type="submit"
                      text={<span>Send Message</span>}
                      isLoading={isLoading}
                      loadingText="Sending Message..."
                      disabled={!validateForm()}
                    />
                  </FormGroup>
                </Row>
              </form>
            </div>
            }
          </Col>
          <Col sm={2}></Col>
        </Row>
      </Container>
    </div>
  );
}