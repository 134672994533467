import React from "react";
import CheckAppVersion from "../components/CheckAppVersion";
import "./NotFound.css";

export default function NotFound() {
  return (
    <div className="NotFound text-center main">
      <CheckAppVersion/>
      <h3>Sorry, page not found!</h3>
    </div>
  );
}
