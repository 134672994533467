const dev = {
  stage: process.env.REACT_APP_STAGE,
  api: {
    REGION: "us-east-1",
    URL: "https://dnkevs06bf.execute-api.us-east-1.amazonaws.com/dev/"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_rWJ1U5BDO",
    APP_CLIENT_ID: "64vuhbp7j38alpch9h5a8l7da2",
    IDENTITY_POOL_ID: "us-east-1:eff1737e-e42c-4de1-975d-d3e7fa2b8774"
  },
  billing: {
    STRIPE_KEY: "pk_test_egB50J4mAgwNXrD6aeU8L6c100IaoqHdMT"
  },
  storage: {
    REGION: "us-east-1",
    BUCKET: "cuatroc-product-lines",
  },
};

const prod = {
  stage: process.env.REACT_APP_STAGE,
  api: {
    REGION: "us-east-1",
    URL: "https://zv71aq1qui.execute-api.us-east-1.amazonaws.com/prod/"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_HolZgNLsa",
    APP_CLIENT_ID: "5vhukk890r62m7qtue93pbqmu3",
    IDENTITY_POOL_ID: "us-east-1:dab1c4c2-1612-4782-9c73-8bda18f2320a"
  },
  billing: {
    STRIPE_KEY: "pk_live_EYmENyHDJZKY9BCNNgeugK5H00gLeHjXr9"
  },
  storage: {
    REGION: "us-east-1",
    BUCKET: "geedop-product-lines",
  },
};

var config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;
config = {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
export default config;

// aws cognito-idp sign-up \
//   --region us-east-1 \
//   --client-id 64vuhbp7j38alpch9h5a8l7da2 \
//   --username admin@example.com \
//   --password Passw0rd! \
//   --profile rnava4cocinas

// aws cognito-idp admin-confirm-sign-up \
//   --region us-east-1 \
//   --user-pool-id us-east-1_rWJ1U5BDO \
//   --username admin@example.com \
//   --profile rnava4cocinas