import React, { useState, useEffect } from "react";
import logo from '../logo.png';
import './Footer.css'
import facebook from '../images/facebook50.png'
import instagram from '../images/instagram50.png'
import tiktok from '../images/tiktok50.png'
import pinterest from '../images/pinterest50.png'
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function Footer() {
  const [year, setYear] = useState('2021');
  useEffect(() => {
    // Anything in here is fired on component mount.
    onLoad();
    return () => {
      // Anything in here is fired on component unmount.
      // onUnload();
    }
  }, []);
  function onLoad() {
    var nYear = new Date().getFullYear();
    setYear(nYear);
  }
  return (
    <div fluid="true" className="Footer">
      <div className="cuatro">
        <Container>
          <Row>
            <Col sm={4} className="left">
              <ul className="list-inline">
                <li className="list-inline-item">
                  <div className="logo"><Link to="/"><img src={logo} alt=""/></Link></div>
                </li>
              </ul>
            </Col>
            <Col sm={4} className="center">
              <ul className="list-inline">
                <li className="list-inline-item social">
                  <div className="head">Follow us</div>
                  <div className="social-icons">
                    <a className="si" href="https://www.facebook.com/cuatro.us/" target="_blank" rel="noopener noreferrer" ><img src={facebook} alt=""/></a>
                    <a className="si" href="https://www.instagram.com/cuatro_us/" target="_blank" rel="noopener noreferrer" ><img src={instagram} alt=""/></a>
                    <a className="si" href="https://www.tiktok.com/@cuatro_us/" target="_blank" rel="noopener noreferrer" ><img src={tiktok} alt=""/></a>
                    <a className="si" href="https://www.pinterest.com/cuatro_us/" target="_blank" rel="noopener noreferrer" ><img src={pinterest} alt=""/></a>
                  </div>
                </li>
                <li className="list-inline-item"></li>
              </ul>
            </Col>
            <Col sm={4} className="right">
              <ul className="list-inline">
                <li className="list-inline-item left">
                  <div className="head">Contact us</div>
                  <div className="contact-info">
                    <a href="mailto:hello@cuatro.us" target="_blank" rel="noopener noreferrer">hello@cuatro.us</a>
                  </div>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="bottom">
        <Container>
          <Row>
            <Col sm={8} className="left d-none d-sm-block">
              <ul className="list-inline policies">
                 <li className="list-inline-item"><a href={window.location.origin + "/terms"} target="_blank" rel="noopener noreferrer">Terms & Conditions</a></li>
                 <li className="list-inline-item"><a href={window.location.origin + "/shipping"} target="_blank" rel="noopener noreferrer">Shipping & Returns</a></li>
                 <li className="list-inline-item"><a href={window.location.origin + "/privacy"} target="_blank" rel="noopener noreferrer">Privacy Policy</a></li>
              </ul>
            </Col>
            <Col sm={8} className="left d-block d-sm-none">
              <ul className="list-vertical policies">
                 <li className="list-vert-item"><a href={window.location.origin + "/terms"} target="_blank" rel="noopener noreferrer">Terms & Conditions</a></li>
                 <li className="list-vert-item"><a href={window.location.origin + "/shipping"} target="_blank" rel="noopener noreferrer">Shipping & Returns</a></li>
                 <li className="list-vert-item"><a href={window.location.origin + "/privacy"} target="_blank" rel="noopener noreferrer">Privacy Policy</a></li>
              </ul>
            </Col>
            <Col sm={4} className="right">
              <ul className="list-inline">
                <li className="list-inline-item"><span className="legal">&copy; {year} Cuatro LLC</span></li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}