import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { API } from "aws-amplify";
import { FormGroup, FormControl, FormLabel, Container, Row, Col } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import Breadcrums from "../components/Breadcrums";
import { useFormFields } from "../lib/hooksLib";
import CheckAppVersion from "../components/CheckAppVersion";
import "./ChangeName.css";

export default function ChangeName() {
  const history = useHistory();
  const [fields, handleFieldChange] = useFormFields({
    name: "",
  });
  const [isNameChanging, setIsNameChanging] = useState(false);

  function validateNameChangeForm() {
    return fields.name.length > 0;
  }

  async function handleNameChangeSubmit(event) {
    event.preventDefault();
    setIsNameChanging(true);
    await customerUpdateName(fields.name);
    history.push("/account");
  }

  async function customerUpdateName(name) {
    const customerCall = await API.post("customer", "customer", {
      body: {
        method: 'update',
        name: name
      }
    });
    if (customerCall.status) {
      alert('Name successfully changed.');
    } else {
      alert('There was an error. Please contact support or try later.');
    }
  }

  function renderNameChangeForm() {
    return (
      <form onSubmit={handleNameChangeSubmit}>
        <FormGroup controlId="name">
          <FormLabel>New Name</FormLabel>
          <FormControl
            autoFocus
            type="text"
            value={fields.name}
            onChange={handleFieldChange}
          />
          <div className="feedback error"></div>
        </FormGroup>
        <LoaderButton
          block
          className="cuatro-primary"
          variant="outline-dark"
          disabled={!validateNameChangeForm()}
          type="submit"
          size="md"
          isLoading={isNameChanging}
          text={'Change'}
          loadingText="Changing.."
        />
      </form>
    );
  }

  return (
    <div className="ChangeName main">
      <CheckAppVersion/>
      <Breadcrums path={[{name: 'Account', to: '/account'}, {name: 'Change Name'}]} />
      <Container>
        <Row>
          <Col sm={2}></Col>
          <Col sm={8}>
            <div className="narrow">
              <h3>Change Name</h3>
              {renderNameChangeForm()}
            </div>
          </Col>
          <Col sm={2}></Col>
        </Row>
      </Container>
    </div>
  );
}