import React, { useState, useEffect } from "react";
import { Form, Col, Button, ListGroup, Row } from "react-bootstrap";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import LoaderButton from "./LoaderButton";
import { useFormFields } from "../lib/hooksLib";
import { FaMapMarkerAlt, FaCcDinersClub, FaRegCreditCard } from 'react-icons/fa';
import { AiOutlinePlus } from "react-icons/ai";
import { FiChevronDown, FiLock } from 'react-icons/fi';
import { GrAmex } from 'react-icons/gr';
import { SiDiscover, SiJcb } from 'react-icons/si';
import { RiMastercardLine, RiVisaLine } from 'react-icons/ri';
import { BsCreditCard2Front } from 'react-icons/bs';

import { MdRadioButtonChecked, MdRadioButtonUnchecked } from "react-icons/md";
import "./BillingForm.css";
import { useHistory, Link } from "react-router-dom";
import { xFormatMoney, xValidateEmail, xFullName2Parts } from '../components/XFunctions';

export default function BillingForm({ 
  isLoading, onSubmit, setClientSecret, placeAddress, updateSummary, 
  shippingOpts, selectedShipOpt, handleShipOptOnClick, userName, userEmail, 
  userPayMs, isAuthenticated, promoCodeApplied 
}) {
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const [fields, handleFieldChange] = useFormFields({
    shipAddressFirstName: userName ? xFullName2Parts(userName).first : "",
    shipAddressLastName: userName ? xFullName2Parts(userName).last : "",
    email: userEmail ? userEmail : "",
    saveNewCard: false,
    promoCode: ""
  });
  const [saveNewCard, setSaveNewCard] = useState(false);
  const [shipAddress, setShipAddress] = useState({
    AddressLine1: "",
    AddressLine2: "",
    City: "",
    State: "",
    ZipCode: "",
    Country: "",
    Residential: false
  });
  const [fieldPhone, setFieldPhone] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [isCardComplete, setIsCardComplete] = useState(false);
  const [showAllShipOpts, setShowAllShipOpts] = useState(false);
  const [selectedPayM, setSelectedPayM] = useState('new');
  isLoading = isProcessing || isLoading;

  useEffect( () => {
    async function doIt() {
      if (!placeAddress) {
        history.push('/cart')
        return
      }
      setShipAddress({
        AddressLine1: placeAddress.street,
        AddressLine2: placeAddress.street2,
        City: placeAddress.city,
        State: placeAddress.state,
        ZipCode: placeAddress.postal_code,
        Country: placeAddress.country,
        Residential: placeAddress.residential,
      })
      updateSummary(placeAddress.state, null, null);
    }
    doIt();
  }, []);

  function validateForm() {
    return (
      stripe &&
      elements &&
      fields.shipAddressFirstName !== "" &&
      fields.shipAddressLastName !== "" &&
      xValidateEmail(fields.email) &&
      fieldPhone && (fieldPhone.length === 14) &&
      ((selectedPayM && selectedPayM !== 'new') || isCardComplete)
    );
  }

  function validatePromoCode() {
    return (
      fields.promoCode !== ""
    );
  }

  async function handleSubmitClick(event) {
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    setIsProcessing(true);
    var token = null;
    var error = null;
    if (!isAuthenticated || selectedPayM === "new") {
      let card = elements.getElement(CardElement);
      let createTokenRes = await stripe.createToken(card);
      token = createTokenRes.token;
      error = createTokenRes.error;
    } else {
      token = selectedPayM;
    }
    let formData = JSON.parse(JSON.stringify({
      shipAddressFirstName:     fields.shipAddressFirstName,
      shipAddressLastName:      fields.shipAddressLastName,
      shipAddressAddressLine1:  shipAddress.AddressLine1,
      shipAddressAddressLine2:  shipAddress.AddressLine2,
      shipAddressCity:          shipAddress.City,
      shipAddressState:         shipAddress.State,
      shipAddressZipCode:       shipAddress.ZipCode,
      shipAddressCountry:       shipAddress.Country,
      email:                    fields.email,
      shipAddressPhoneNumber:   fieldPhone,
      isCardNumberComplete:     isCardComplete,
      isCardExpiryComplete:     isCardComplete,
      isCardCVCComplete:        isCardComplete,
      selectedPayM:             selectedPayM,
      saveNewCard:              saveNewCard,
      promoCode:                fields.promoCode,
      personalMessage:          fields.personalMessage
    }));
    onSubmit(formData, { token, error });
    setIsProcessing(false);
  }

  function handlePayElemOnReady() {
  }

  function handlePhoneInputChange(event) {
    let value = event.target.value;
    setFieldPhone(normalizeInput(value));
  }

  function handleShowAllShipOptsClick(event) {
    event.preventDefault();
    setShowAllShipOpts(true);
  }

  function handlePayMOnClick(event, id) {
    event.preventDefault();
    setSelectedPayM(id);
  }

  function handleSaveNewCardChange(event) {
    let value = event.target.checked;
    setSaveNewCard(value);
  }

  function handlePromoCodeApply() {
    updateSummary(placeAddress.state, null, fields.promoCode);
  }

  function handlePromoCodeRemove() {
    updateSummary(placeAddress.state, null, null, true);
    let event = { target: {
      id: 'promoCode',
      value: ''
    }};
    handleFieldChange(event);
  }

  const normalizeInput = (value, previousValue) => {
    // return nothing if no value
    if (!value) return value; 
    // only allows 0-9 inputs
    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length; 
    if (!previousValue || value.length > previousValue.length) {
      // returns: "x", "xx", "xxx"
      if (cvLength < 4) return currentValue; 
      // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
      if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`; 
      // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`; 
    }
  };

  function renderShippingOpts() {
    let shipOpts = JSON.parse(JSON.stringify(shippingOpts));
    let moreOptsCount = shippingOpts.length - 1;
    if (!showAllShipOpts) {
      shipOpts.length = 1;
    }
    return(
      <>
        <ListGroup className="shipping-opts local" >
          {shipOpts.map((it, idx) => {
            if (it.serviceType === 'LOCAL_PICKUP') {
              return (
                <ListGroup.Item as="li" key={idx} onClick={e => handleShipOptOnClick(it.serviceType, shipAddress.State)} className={selectedShipOpt && selectedShipOpt.serviceType === it.serviceType ? 'selected' : ''} >
                  <div className="service-active">{selectedShipOpt && selectedShipOpt.serviceType === it.serviceType ? <MdRadioButtonChecked /> : <MdRadioButtonUnchecked />}</div>
                  <div className="service-left">
                    <div className="name">{it.serviceName}</div>
                    <div className="delivery-days">
                      <span>11450 James Watt Dr Ste B7<br/>El Paso, TX 79936 <a href="https://g.page/cuatro_us?share" target="_blank" rel="noopener noreferrer"><FaMapMarkerAlt /> Map</a></span>
                    </div>
                  </div>
                  <div className="service-price">${xFormatMoney(it.price)}</div>
                </ListGroup.Item>
              )
            } else {
              return null
            }
          })}
        </ListGroup>
        <ListGroup className="shipping-opts" >
          {shipOpts.map((it, idx) => {
            if (it.serviceType !== 'LOCAL_PICKUP') {
              return (
                <ListGroup.Item as="li" key={idx} onClick={e => handleShipOptOnClick(it.serviceType, shipAddress.State)} className={selectedShipOpt && selectedShipOpt.serviceType === it.serviceType ? 'selected' : ''} >
                  <div className="service-active">{selectedShipOpt && selectedShipOpt.serviceType === it.serviceType ? <MdRadioButtonChecked /> : <MdRadioButtonUnchecked />}</div>
                  <div className="service-left">
                    <div className="name">{it.serviceName}</div>
                    {it.deliveryDays &&
                      <div className="delivery-days">{it.deliveryDays} days</div>
                    }
                  </div>
                  <div className="service-price">${xFormatMoney(it.price)}</div>
                </ListGroup.Item>
              )
            } else {
              return null
            }
          })}
        </ListGroup>
        {!showAllShipOpts && moreOptsCount > 0 &&
          <div className="showAllShipOpts"><Button variant="link" onClick={handleShowAllShipOptsClick}>{"Show more (" + moreOptsCount + ") "}<FiChevronDown/></Button></div>
        }
      </>
    )
  }

  function renderPaymentMethods() {
    let upms = JSON.parse(JSON.stringify(userPayMs));
    if (!upms || upms.length < 1) {
      return null;
    }
    upms[upms.length - 1].push({id: 'new'});
    const items = [];
    for (var i = 0; i < upms.length; i++) {
      let it = upms[i];
      const cols = [];
      for (var j = 0; j < it.length; j++) {
        let cl = it[j];
        cols.push(
          <Col sm={4} key={j} className="paymcol">
            <div className={"paymcard " + (selectedPayM === cl.id ? "active" : "")} onClick={(e) => handlePayMOnClick(e, cl.id)}>
              <div className="info">
                {cl.id === 'new' ?
                  <Row>
                    <Col xs={4}>
                      <div className="imgwrap">{<AiOutlinePlus />}</div>
                    </Col>
                    <Col xs={8}>
                      <div className="numexp">
                        <div className="last4">New card</div>
                      </div>
                    </Col>
                  </Row>
                :
                  <Row>
                    <Col xs={4}>
                      <div className="imgwrap">{renderCreditCard(cl.brand)}</div>
                    </Col>
                    <Col xs={8}>
                      <div className="numexp">
                        <div className="last4">{'****' + cl.last4}</div>
                        <div className="expires">{'Expires ' + cl.expmo + '/' + cl.expyr}</div>
                      </div>
                    </Col>
                  </Row>
                }
              </div>
            </div>
          </Col>
        )
      }
      items.push(
        <Row key={i} className="paymrow">
          {cols}
        </Row>
      );
    }
    return items;
  }

  function renderCreditCard(brand) {
    let elem = null;
    switch (brand) {
      case 'amex':
        elem = <GrAmex/>
      break;
      case 'diners':
        elem = <FaCcDinersClub/>
      break;
      case 'discover':
        elem = <SiDiscover/>
      break;
      case 'jcb':
        elem = <SiJcb/>
      break;
      case 'mastercard':
        elem = <RiMastercardLine/>
      break;
      case 'unionpay':
        elem = <BsCreditCard2Front/>
      break;
      case 'visa':
        elem = <RiVisaLine/>
      break;
      default:
        elem = <FaRegCreditCard/>
    }
    return elem;
  }

  function renderBillingForm() {
    const cardStyle = {
      style: {
        base: {
          color: "#495057",
          fontFamily: 'Jost',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#6D757D"
          }
        },
        invalid: {
          fontFamily: 'Arial, sans-serif',
          color: "#dc3545",
          iconColor: "#dc3545"
        },
      }
    };
    return (
      <Form onSubmit={handleSubmitClick}>
        <Form.Row className="mt-4">
          <Form.Group as={Col}>
            <h5>Your Information</h5>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} sm={6} controlId="shipAddressFirstName">
            <Form.Label>First Name</Form.Label>
            <Form.Control type="text" placeholder="First Name" onChange={handleFieldChange} defaultValue={fields.shipAddressFirstName} />
          </Form.Group>
          <Form.Group as={Col} sm={6} controlId="shipAddressLastName">
            <Form.Label>Last Name</Form.Label>
            <Form.Control type="text" placeholder="Last Name" onChange={handleFieldChange} defaultValue={fields.shipAddressLastName} />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} className="email-header">
            <Form.Label>Email</Form.Label>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} sm={6} controlId="email">
            <Form.Control type="email" placeholder="Email" onChange={handleFieldChange} defaultValue={fields.email} />

          </Form.Group>
          <Form.Group as={Col} sm={6}>
            <Form.Label className="first" >We need your email address to send your order and delivery notifications.</Form.Label>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="shipAddressPhoneNumber">
            <Form.Label>Phone (10 numbers)</Form.Label>
            <Form.Control type="text" placeholder="(000) 000-0000" onChange={handlePhoneInputChange} value={fieldPhone} />
          </Form.Group>
        </Form.Row>
        <Form.Row className="mt-4">
          <Form.Group as={Col}>
            <h5>Delivery Address</h5>
            <div className="mt-1" ></div>
            <div>{fields.shipAddressFirstName + ' ' + fields.shipAddressLastName}</div>
            <div>{shipAddress.AddressLine1}</div>
            <div>{shipAddress.AddressLine2}</div>
            <div>{shipAddress.City + ', ' + shipAddress.State + ' ' + shipAddress.ZipCode}</div>
            <div>{shipAddress.Country}</div>
            <Link to="/cart" className="edit-address"><Button variant="link">Edit address</Button></Link>
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>Delivery method</Form.Label>
            {renderShippingOpts()}
          </Form.Group>
        </Form.Row>
        <Form.Row className="mt-4">
          <Form.Group as={Col}>
            <h5>Payment</h5>
          </Form.Group>
        </Form.Row>
        {userPayMs &&
          <Form.Row>
            <Form.Group as={Col}>
              {renderPaymentMethods()}
            </Form.Group>
          </Form.Row>
        }
        {((userPayMs && selectedPayM === 'new') || (!userPayMs)) &&
          <Form.Row>
            <Form.Group as={Col} controlId="newCard" className="newCard">
              <Form.Label>Credit Card Info</Form.Label>
              <CardElement 
                className="card-field"
                id="card-element" 
                options={cardStyle}
                onReady={() => handlePayElemOnReady()}
                onChange={(e) => setIsCardComplete(e.complete)}
              />
              {isAuthenticated &&
                <div className="saveCard">
                  {isCardComplete &&
                  <Form.Check className="mt-2" type="checkbox" label="Save card" checked={saveNewCard} onChange={handleSaveNewCardChange} />
                  }
                </div>
              }
            </Form.Group>
          </Form.Row>
        }
        <Form.Row className="mt-4">
          <Form.Group as={Col}>
            <h5>Personal Message</h5>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="personalMessage">
            <Form.Control as="textarea" maxLength="500" rows="3" placeholder="Add message here..." onChange={handleFieldChange} />
            <Form.Text className="text-muted text-right">Maximum 500 characters</Form.Text>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <h5>Promo Code</h5>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} sm={9} controlId="promoCode" className="promoCode">
            <Form.Control type="text" placeholder="" onChange={handleFieldChange} value={(promoCodeApplied && promoCodeApplied !== '') ? promoCodeApplied : fields.promoCode} disabled={promoCodeApplied && promoCodeApplied !== ''} />
          </Form.Group>
          {(promoCodeApplied && promoCodeApplied !== '') ?
            <Form.Group as={Col} sm={3} controlId="promoCodeRemove" className="promoCodeRemove">
              <Button block variant="outline-dark cuatro-back" onClick={handlePromoCodeRemove}>Remove</Button>
            </Form.Group>
          :
            <Form.Group as={Col} sm={3} controlId="promoCodeApply" className="promoCodeApply">
              <Button block variant="outline-dark cuatro-back" disabled={!validatePromoCode()} onClick={handlePromoCodeApply}>Apply</Button>
            </Form.Group>
          }
        </Form.Row>
        <Form.Row>
          <p className="agree-notice">By clicking "Place Order", you confirm that you have read and agreed to our <a href={window.location.origin + "/privacy"} target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</p>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <LoaderButton
              className="mt-4 cuatro-primary"
              variant="outline-dark"
              block
              type="submit"
              text={<span><FiLock /> Place Order</span>}
              isLoading={isLoading}
              loadingText="Placing Order..."
              disabled={!validateForm()}
            />
          </Form.Group>
        </Form.Row>
      </Form>
    )
  }


  return (
    <div className="BillingForm">
      <>
        {renderBillingForm()}
      </>
    </div>
  );
}