import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import LoaderButton from "../components/LoaderButton";
import "./Login.css";
import { Auth } from "aws-amplify";
import { useAppContext } from "../lib/contextLib";
import { Link } from "react-router-dom";
import { useFormFields } from "../lib/hooksLib";
import { onError } from "../lib/errorLib";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useHistory } from "react-router-dom";
import CheckAppVersion from "../components/CheckAppVersion";

export default function Login() {
  const { userHasAuthenticated, fetchCartCount } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: ""
  });
  const history = useHistory();

  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }
  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    try {
      await Auth.signIn(fields.email, fields.password);
      fetchCartCount();
      userHasAuthenticated(true);
    } catch (e) {
      if (e.code && e.code === 'UserNotConfirmedException') {
        console.log('Code: ', e.code)
        history.push({ pathname: "/signup", state: { confirmEmail: fields.email } });
      } else {
        console.log('ERROR: ', e)
        onError(e);
      }
      setIsLoading(false);
    }
  }

  return (
    <div className="Login main">
      <CheckAppVersion/>
      <Container>
        <Row>
          <Col>
            <Form onSubmit={handleSubmit}>
              <h2>Sign In</h2>
              <Form.Group size="lg" controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  autoFocus
                  type="email"
                  value={fields.email}
                  onChange={handleFieldChange}
                />
                <div className="feedback"></div>
              </Form.Group>
              <Form.Group size="lg" controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  value={fields.password}
                  onChange={handleFieldChange}
                />
                <div className="feedback"></div>
              </Form.Group>
              <Link to="/login/reset">Forgot password?</Link>
              <LoaderButton
                block
                className="cuatro-primary"
                variant="outline-dark"
                disabled={!validateForm()}
                type="submit"
                size="md"
                isLoading={isLoading}
                text={'Login'}
                loadingText="Logging in.."
              />
              <div className="createAccount">
                <Link to="/signup"><Button block className="cuatro-back" variant="outline-dark">Create an Account</Button></Link>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
