import React from "react";
import "./Terms.css";
import { Container, Row, Col } from "react-bootstrap";
import CheckAppVersion from "../components/CheckAppVersion";
import Breadcrums from "../components/Breadcrums";
import { Link } from "react-router-dom";

export default function Terms() {

  return (
    <div className="Terms main">
      <CheckAppVersion/>
      <Breadcrums path={[{name: 'Terms & Conditions'}]} />
      <Container>
        <Row>
          <Col sm={0} md={2}></Col>
          <Col sm={12} md={8} className="all">
            <h3>Terms & Conditions</h3>
            <hr className="short" />
            <span className="updated">Last updated: June 1, 2021</span>
            <p className="intro">This Agreement sets forth the terms and conditions that apply to your access and use of the information, tools, software, features and functionality including content, updates and new releases located at the Internet Web sites: https://cuatro.us and https://www.cuatro.us, collectively refferred to as the "Cuatro Website" or the “Service”, as owned by Cuatro LLC (“Cuatro”). The term “you” or “User” refers to a Visitor or a Member. The term “we” refers to Cuatro.</p>
            <ol>
            <li>Payment Policy</li>
            </ol>
            <p>The Service is paid for in advance.</p>
            <ol start="2">
            <li>Modifying and Terminating our Service</li>
            </ol>
            <p>We may add or remove functionalities or features, and we may suspend or stop our Service altogether. We reserve the right to suspend or cancel any User's access to any portion or all our Service when we deem that the Service has been used inappropriately. Cuatro reserves the right to refuse its Service to anyone.</p>
            <ol start="3">
            <li>Refund Policy</li>
            </ol>
            <ul>
            <li>The Service is paid for in advance and is non-refundable once your account has been charged.</li>
            <li>If Cuatro terminates an account due to policy violations, there will be no refund.</li>
            </ul>
            <p>Cuatro’s <Link to="/shipping">Refund Policy</Link> explain how we process returs and refunds.</p>
            <ol start="4">
            <li>Shipping Policy/Delivery Policy</li>
            </ol>
            <p>The Service is delivered electronically as a hosted “Software as a Service” solution. Delivery of the Service is web based. While we strive for our best, Cuatro makes no guarantee to the availability or uptime of its Service.</p>
            <ol start="5">
            <li>Cancellation Policy</li>
            </ol>
            <p>Upon Service or account cancellation, data located on Cuatro servers may no longer be available. It is the end-user’s responsibility to retrieve these data prior to the request for cancellation of the Service. Cuatro cannot guarantee retrieval of data after an account is cancelled.</p>
            <ol start="6">
            <li>Policy Violation</li>
            </ol>
            <p>Cuatro reserves the right to terminate, refuse or discontinue its Service to anyone violating its policies. If an account or Service is terminated for a policy violation there will be no refund.</p>
            <ol start="7">
            <li>Support</li>
            </ol>
            <p>Technical Support is provided via email for all paying clients. Technical support emails will be answered within 3 business days. Most support issues are resolved the same day reported. Billing inquiries will be answered within 3 business days. You agree not to spam or abuse support.</p>
            <p>Technical Support representatives will diagnose issues related to user ID and passwords.</p>
            <ol start="8">
            <li>Securities and Reliability</li>
            </ol>
            <p>The security and reliability of the Service is our highest priority. Cuatro strives to maintain a hassle-free Service. However, there are occurrences when Cuatro cannot guarantee 100% reliability:</p>
            <ul>
            <li>Internet congestion</li>
            <li>Automatic System Updates – The Service may be subject to regular updates, patches and bug fixes.<br /> (These updates can affect the Service in an unexpected way).</li>
            <li>Terrorist Attack</li>
            <li>Acts of God</li>
            <li>Virus/Worm/Denial of Service Attack</li>
            </ul>
            <p>User agrees that Cuatro is not responsible for missing or corrupted data, transmission errors, failure to provide the Service, delay of the Service, provision, or anything in any way connected to the Service beyond the cost of service provided.</p>
            <ol start="9">
            <li>Passwords</li>
            </ol>
            <p>User is responsible for password confidentiality, and to regularly change the password. Cuatro is not responsible for any data losses or security issues due to stolen passwords.</p>
            <ol start="10">
            <li>International</li>
            </ol>
            <p>Orders from Users in Foreign Countries</p>
            <p>Cuatro welcomes orders from any country. All payments are in US Dollars. The Service is started once full payment has been received.</p>
            <ol start="11">
            <li>Legal</li>
            </ol>
            <p>Marketing</p>
            <p>You agree that during the term of this Agreement Cuatro may publicly refer to you, orally and in writing, as a Client of Cuatro. Any other public reference to Client by Cuatro requires the written consent of Client.</p>
            <p>Intellectual Property</p>
            <p>Cuatro hereby grants you a non-exclusive, non-transferable, royalty-free license, exercisable solely during the term of this Agreement, to use applicable Cuatro Technology solely for the purpose of accessing and using its Service. You may not use the Cuatro Technology for any purpose other than accessing and using our Service. Except for the rights expressly granted above, this Agreement does not transfer from Cuatro to you any Cuatro Technology, and all rights, titles and interests in and to the Cuatro Technology shall remain solely with Cuatro. You shall not, directly or indirectly, reverse engineer, decompile, disassemble or otherwise attempt to derive source code or other trade secrets from any of the Cuatro Website.</p>
            <p>Cuatro’s trademarks, trade names, Service marks, logos, other names and marks, and related product and Service names, design marks and slogans are the sole and exclusive property of Cuatro. You may not use any of the foregoing in any advertising, publicity or in any other commercial manner without the prior written consent of Cuatro. Any feedback, data, answers, questions, comments, suggestions, or ideas sent to Cuatro by you relating to our Service will be treated as being non-confidential and non-proprietary. Cuatro may use, disclose or publish any ideas, concepts, or techniques contained in such information for any purpose whatsoever.</p>
            <p>Disclaimer</p>
            <p>Cuatro makes no warranties or guarantees of any kind, expressed or implied for the Service provided. Use the Cuatro’s Service at your sole risk. Cuatro's Service is provided on an "as is, as available" basis. Cuatro does not guarantee that its Service will be uninterrupted or error free. Cuatro will use good faith effort to ensure that its Service is available to as many Internet users as possible with minimal service interruptions.</p>
            <p>In no circumstance will Cuatro be liable for any claims resulting from the use or inability to use the Service - including, but not limited to, Service interruptions, user errors, Internet connectivity problems, miscommunications, unauthorized access to the Cuatro Service, Internet bandwidth congestion, power failures, vandalism and natural disasters.</p>
            <p>Warranties and Indemnities</p>
            <p>EXCEPT AS OTHERWISE EXPRESSLY STATED HEREIN, THERE ARE NO OTHER WARRANTIES, EXPRESS OR IMPLIED, AS TO THE VALUE, CONDITION, DESIGN, FUNCTIONING, OF THE SERVICE OR ANY USE OF THE SERVICE, MERCHANTABILITY, FITNESS FOR ANY PURPOSE OR USE OF THE SERVICE, FREEDOM FROM INFRINGEMENT OR ANY OTHER REPRESENTATION OR WARRANTY WHATSOEVER WITH RESPECT TO THE SERVICE. CUATRO SHALL NOT BE LIABLE TO ANY USERS OF THE SERVICE, INCLUDING USER, FOR ANY INCIDENTAL, INDIRECT, SPECIAL OR CONSEQUENTIAL DAMAGES, LIABILITY, LOSS OR DAMAGE CAUSED OR ALLEGED TO HAVE BEEN CAUSED BY THE SERVICE.</p>
            <p>You further agree to indemnify, hold harmless, and defend Cuatro from and against any and all claims or lawsuits including attorney's fees that arise or result from the use or distribution of applications or services provided by you.</p>
            <p>Controlling Law and Severability</p>
            <p>The courts of the State of Texas, County of El Paso, shall have exclusive jurisdiction and venue over any dispute, proceeding or action arising out of or in connection with this Agreement or use of the Service. If for any reason a court of competent jurisdiction finds any provision of this Agreement, or portion thereof, to be unenforceable, that provision of the Agreement shall be enforced to the maximum extent permissible so as to affect the intent of the parties, and the remainder of this Agreement shall continue in full force and effect.</p>
            <ol start="12">
            <li>Privacy Statement</li>
            </ol>
            <p>Cuatro’s <Link to="/privacy">Privacy Policy</Link> explains how we treat your personal data and protect your privacy when you use our Service. By using our Service, you agree that Cuatro can use such data in accordance with our privacy policies.</p>
            <ol start="13">
            <li>Third Parties</li>
            </ol>
            <p>Except as described in this statement, we will not disclose personal information outside of Cuatro and its controlled subsidiaries and affiliates without consent.</p>
            <ol start="14">
            <li>Agreement</li>
            </ol>
            <p>Complete Agreement</p>
            <p>This Agreement constitutes the complete agreement between User and Cuatro.</p>
            <p>Non-Waiver</p>
            <p>Failure by either party at any time to enforce any of the provisions of this Agreement or any right or remedy available hereunder or at law or in equity, or to exercise any option herein provided, shall not constitute a waiver of such provision, right, remedy or option or in any way affect the validity of this Agreement. The waiver of any default by either party shall not be deemed a continuing waiver, but shall apply solely to the instance to which such waiver is directed.</p>
            <p>For information about how to contact Cuatro, please visit our <Link to="/connect">Contact page</Link>.</p>
          </Col>
          <Col sm={0} md={2}></Col>
        </Row>
      </Container>
    </div>
  );
}