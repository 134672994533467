import React from "react";
import "./Wellness.css";
import CheckAppVersion from "../components/CheckAppVersion";
import Breadcrums from "../components/Breadcrums";
import { Container, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";

export default function Wellness() {
  const history = useHistory();
  
  function handleVidOnClick() {
    history.push("/wellness/show");
  }
  
  function renderTemp() {
    return(
      <div className="Wellness main">
        <CheckAppVersion/>
        <Breadcrums path={[{name: 'Wellness'}]} />
        <Container>
          <Row className="">
            <Col>
              <h3 className="center">Wellness</h3>
              <hr className="short" />
            </Col>
          </Row>
          <Row className="mt-5">
            <Col md={3}></Col>
            <Col md={6}>
              <div className="tmpmsg center">A new way to transfer knowledge about delicious food and healthy habits.</div>
              <h3 className="tmpmsg up center mt-4">Stay tuned</h3>
            </Col>
            <Col md={3}></Col>
          </Row>
        </Container>
      </div>
    );
  };

  function renderWellness() {
    return (
      <div className="Wellness main">
        <CheckAppVersion/>
        <Breadcrums path={[{name: 'Wellness'}]} />
        <Container>
          <Row className="">
            <Col>
              <h3 className="center">Wellness</h3>
            </Col>
          </Row>
          <Row><Col><h5>Latest Processes</h5></Col></Row>
          <Row className="mt-3">
            <Col lg={6}>
              <Row>
                <Col xs={6} className="vidcol">
                  <div className="wrap" onClick={handleVidOnClick}>
                    <div className="vid">
                      <div className="new">New</div>
                    </div>
                    <div className="name mt-2">
                      <div className="title">Quinoa Salad with Fresh Herbs and Seasonal Fruit</div>
                    </div>
                    <div className="meta mt-1">
                      <span className="coll">Zamira</span>
                      <span className="divi"> - </span>
                      <span className="dura">6:52min</span>
                      <span className="divi"> - </span>
                      <span className="views">0 views</span>
                    </div>
                  </div>
                </Col>
                <Col xs={6} className="vidcol">
                  <div className="wrap" onClick={handleVidOnClick}>
                    <div className="vid">
                      <div className="new">New</div>
                    </div>
                    <div className="name mt-2">
                      <div className="title">Use-It-Up Vegetable Soup</div>
                    </div>
                    <div className="meta mt-1">
                      <span className="coll">Zamira</span>
                      <span className="divi"> - </span>
                      <span className="dura">5min</span>
                      <span className="divi"> - </span>
                      <span className="views">96k views</span>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              <Row>
                <Col xs={6} className="vidcol">
                  <div className="wrap" onClick={handleVidOnClick}>
                    <div className="vid"></div>
                    <div className="name mt-2">
                      <div className="title">Caesar Salad with Cashew Dressing & Tofu "Croutons"</div>
                    </div>
                    <div className="meta mt-1">
                      <span className="coll">Lillian</span>
                      <span className="divi"> - </span>
                      <span className="dura">5min</span>
                      <span className="divi"> - </span>
                      <span className="views">53 views</span>
                    </div>
                  </div>
                </Col>
                <Col xs={6} className="vidcol">
                  <div className="wrap" onClick={handleVidOnClick}>
                    <div className="vid"></div>
                    <div className="name mt-2">
                      <div className="title">Whole-Grain Salad with Charred Broccoli, Spring Onions & Parsley-Sumac Vinaigrette</div>
                    </div>
                    <div className="meta mt-1">
                      <span className="coll">Karen</span>
                      <span className="divi"> - </span>
                      <span className="dura">7min</span>
                      <span className="divi"> - </span>
                      <span className="views">2 views</span>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col lg={6}>
              <Row>
                <Col xs={6} className="vidcol">
                  <div className="wrap" onClick={handleVidOnClick}>
                    <div className="vid"></div>
                    <div className="name mt-2">
                      <div className="title">Spring Vegetable Minestra with Mint & Basil Pistou</div>
                    </div>
                    <div className="meta mt-1">
                      <span className="coll">Zamira</span>
                      <span className="divi"> - </span>
                      <span className="dura">8min</span>
                      <span className="divi"> - </span>
                      <span className="views">10k views</span>
                    </div>
                  </div>
                </Col>
                <Col xs={6} className="vidcol">
                  <div className="wrap" onClick={handleVidOnClick}>
                    <div className="vid"></div>
                    <div className="name mt-2">
                      <div className="title">Spanakopita-Stuffed Portobello Mushrooms</div>
                    </div>
                    <div className="meta mt-1">
                      <span className="coll">Aiden</span>
                      <span className="divi"> - </span>
                      <span className="dura">5min</span>
                      <span className="divi"> - </span>
                      <span className="views">96k views</span>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              <Row>
                <Col xs={6} className="vidcol">
                  <div className="wrap" onClick={handleVidOnClick}>
                    <div className="vid"></div>
                    <div className="name mt-2">
                      <div className="title">Vegan Pesto Spaghetti Squash with Mushrooms & Sun-Dried Tomatoes</div>
                    </div>
                    <div className="meta mt-1">
                      <span className="coll">Lachlan</span>
                      <span className="divi"> - </span>
                      <span className="dura">5min</span>
                      <span className="divi"> - </span>
                      <span className="views">53 views</span>
                    </div>
                  </div>
                </Col>
                <Col xs={6} className="vidcol">
                  <div className="wrap" onClick={handleVidOnClick}>
                    <div className="vid"></div>
                    <div className="name mt-2">
                      <div className="title">Veggie-Packed Okonomiyaki (Japanese Pancake)</div>
                    </div>
                    <div className="meta mt-1">
                      <span className="coll">Lachlan</span>
                      <span className="divi"> - </span>
                      <span className="dura">7min</span>
                      <span className="divi"> - </span>
                      <span className="views">3M views</span>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  return(
    renderTemp()
  );
}