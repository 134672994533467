import { useState, useEffect, useRef } from "react";
import { useAppContext } from "../lib/contextLib";
import BuyTheLookModal from "../components/BuyTheLookModal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GrInstagram, GrPrevious, GrNext } from 'react-icons/gr';
import { webMedia } from "../components/Constants";

export default function Carousel({ data, handleAddToXOnClick, isAddingToCart }) {
  const { imageHash, windowWidth } = useAppContext();
  const carousel = useRef(null);
  const [prevDisabled, setPrevDisabled] = useState(true);
  const [carouselNextDisabled, setNextDisabled] = useState(false);
  const scroll = (scroolOffset) => {
    carousel.current.scrollLeft += scroolOffset;
  };
  const [selected, setSelected] = useState(-1);
  const scrollTravel = Math.floor(windowWidth / 180) * 180;

  useEffect(() => {
    const onLoad = () => {
      window.addEventListener("resize", handleResize);
    }
    const onUnload = () => {
      window.removeEventListener("resize", handleResize);
    }
    onLoad();
    return () => {
      onUnload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  function handleOnScroll(e) {
    if (carousel.current) {
      const carouselOffsetWidth = carousel.current.offsetWidth;
      const carouselScrollLeft = carousel.current.scrollLeft;
      const carouselScrollWidth = carousel.current.scrollWidth;
      let cpd = (carouselScrollLeft <= 20);
      let cnd = (carouselScrollLeft + carouselOffsetWidth + 20 >= carouselScrollWidth);
      setPrevDisabled(cpd);
      setNextDisabled(cnd);
    }
  }
  function handleResize() {
    handleOnScroll()
  }
  function handleSlideClick(idx) {
    setSelected(idx);
  }
  function handleSetSelIdx(newIdx) {
    setSelected(newIdx);
  }

  return (
      <>
        <Container fluid className="carousel-title" >
          <Row>
            <Col>
              <div className="desc">Follow our sustainable journey</div>
              <div className="handle">@cuatro_us</div>
            </Col>
          </Row>
        </Container>
        <div className="carousel-wrap">
          <div className="sw-container">
            <div className="sw-wrapper" ref={carousel} onScroll={handleOnScroll}>
              {!prevDisabled &&
                <div className="prev" onClick={() => scroll(scrollTravel * -1)}>
                  <GrPrevious className="icon" />
                </div>
              }
              {data.map((item, idx) => {
                return(
                  <div key={idx} onClick={(e) => handleSlideClick(idx)} className="sw-slide" style={{ backgroundImage: `url("` + webMedia + `/1000x1000/` + item.img + `?` + imageHash + `")`}}>
                    <div className="board"><div className="icon"><GrInstagram /></div></div>
                  </div>
                );
              })}
              {!carouselNextDisabled &&
                <div className="next" onClick={() => scroll(scrollTravel)}>
                  <GrNext className="icon" />
                </div>
              }
            </div>
          </div>
        </div>
      {selected > -1 &&
        <BuyTheLookModal 
          show={selected > -1 ? true : false} 
          selected={{ item: data[selected], index: selected}} 
          setShow={setSelected} 
          isAddingToCart={isAddingToCart} 
          handleAddToXOnClick={handleAddToXOnClick}
          changeSelIdx={handleSetSelIdx}
          carouselLength={data.length} 
        />
      }
    </>
  );
}