import React, { useState, useEffect } from "react";
import "./OrderConfirm.css";
import { Container } from "react-bootstrap";
import CheckAppVersion from "../components/CheckAppVersion";
import Breadcrums from "../components/Breadcrums";
import { useHistory, useLocation } from "react-router-dom";
import { FaSpinner } from "react-icons/fa";
import { API } from "aws-amplify";
import { useAppContext } from "../lib/contextLib";

export default function OrderConfirm() {
  const { updateCartCount } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const [orderConfirmation, setOrderConfirmation] = useState(null);
  const [receiptUrl, setReceiptUrl] = useState(null);

  useEffect(() => {
    async function doIt() {
      if (location.state && location.state.data) {
        setOrderConfirmation(location.state.data.orderConfirmation);
        setReceiptUrl(location.state.data.receiptUrl);
        const apiReturn = await API.post('carts', 'updatecart', {
          body: { state: 'NONE', cartItems: [], removePromoCode: true }
        }).then(response => response).catch(error => error)
        if (apiReturn && apiReturn.status && !apiReturn.nocart) {
          updateCartCount(apiReturn.cart.quantityTotal)
        }
        setIsLoading(false);
      }
    }
    doIt();
  }, [location, history, updateCartCount]);

  return (
    <div className="OrderConfirm main">
      <CheckAppVersion/>
      <Breadcrums path={[{ name: 'Order Confirmation' }]} />
      <Container className="main">
        <h2 className="center">Order Confirmation</h2>
        {isLoading ?
          <div className="loading"><FaSpinner className="spin" /></div>
        :
          <div className="message center">
            <h3 className="thankyou">Thank You</h3>
            <p>Order # <strong>{orderConfirmation ? orderConfirmation : null}</strong></p>
            <p>You will receive an email confirmation shortly.</p>
            <p><a className="btn-link" href={receiptUrl ? receiptUrl : "/"} target="_blank" rel="noopener noreferrer">Payment Receipt</a></p>
          </div>
        }
      </Container>
    </div>
  );
}