import React from "react";
import { Route, Switch } from "react-router-dom";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import Home from "./containers/Home";
import Cuatro from "./containers/Cuatro";
import Product from "./containers/Product";
import Cart from "./containers/Cart";
import Checkout from "./containers/Checkout";
import About from "./containers/About";
import Connect from "./containers/Connect";
import Login from "./containers/Login";
import Signup from "./containers/Signup";
import ResetPassword from "./containers/ResetPassword";
import Account from "./containers/Account";
import ChangePassword from "./containers/ChangePassword";
import ChangeEmail from "./containers/ChangeEmail";
import ChangeName from "./containers/ChangeName";
import AddCard from "./containers/AddCard";
import OrderConfirm from "./containers/OrderConfirm";
import NotFound from "./containers/NotFound";
import Terms from "./containers/Terms";
import Shipping from "./containers/Shipping";
import Privacy from "./containers/Privacy";
import Wellness from "./containers/Wellness";
import WellnessShow from "./containers/WellnessShow";
import WellnessProfile from "./containers/WellnessProfile";

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/collection/:collid">
        <Cuatro />
      </Route>
      <Route exact path="/collection/:collid/:productid">
        <Product />
      </Route>
      <Route exact path="/wellness">
        <Wellness />
      </Route>
      <Route exact path="/wellness/show">
        <WellnessShow />
      </Route>
      <Route exact path="/wellness/profile">
        <WellnessProfile />
      </Route>
      <Route exact path="/cart">
        <Cart />
      </Route>
      <Route exact path="/checkout">
        <Checkout />
      </Route>
      <Route exact path="/about">
        <About />
      </Route>
      <Route exact path="/connect">
        <Connect />
      </Route>
      <UnauthenticatedRoute exact path="/login">
        <Login />
      </UnauthenticatedRoute>
      <Route exact path="/signup">
        <Signup />
      </Route>
      <UnauthenticatedRoute exact path="/login/reset">
        <ResetPassword />
      </UnauthenticatedRoute>
      <AuthenticatedRoute exact path="/account">
        <Account />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/account/password">
        <ChangePassword />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/account/email">
        <ChangeEmail />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/account/name">
        <ChangeName />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/account/addcard">
        <AddCard />
      </AuthenticatedRoute>
      <Route exact path="/order-confirmation">
        <OrderConfirm />
      </Route>
      <Route exact path="/terms">
        <Terms />
      </Route>
      <Route exact path="/shipping">
        <Shipping />
      </Route>
      <Route exact path="/privacy">
        <Privacy />
      </Route>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}
