import React, { useState, useEffect } from "react";
import "./Product.css";
import { Container, Row, Col, Button, ButtonToolbar, Accordion, Card } from "react-bootstrap";
import CheckAppVersion from "../components/CheckAppVersion";
import Breadcrums from "../components/Breadcrums";
import { useAppContext } from "../lib/contextLib";
import { xFormatMoney, xHumanize } from '../components/XFunctions';
import { useParams, useHistory, useLocation } from "react-router-dom";
import { API, Auth } from "aws-amplify";
import { webMedia } from "../components/Constants";
import QuantityControl from "../components/QuantityControl";
import { MdCheck } from "react-icons/md";
import { GrDown, GrUp, GrCircleInformation } from "react-icons/gr";
import LoaderButton from "../components/LoaderButton";
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import ProductImageSlider from "../components/ProductImageSlider";
import AddedToCart from "../components/AddedToCart";
// start Pinterest
import ReactPinterestTag from "react-pinterest-tag";
// end Pinterest

export default function Product() {
  const { imageHash, updateCartCount } = useAppContext();
  const history = useHistory();
  const location = useLocation();

  const { collid, productid } = useParams();
  const [product, setProduct] = useState(null);
  const [productVars, setProductVars] = useState(null);
  const [selectPVar, setSelectPVar] = useState(null);
  const [price, setPrice] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [isAddingToCart, setIsAddingToCart] = useState(false);
  const [qtyAdded, setQtyAdded] = useState(0);

  const [selectedTab, setSelectedTab] = useState(1);
  const [inStock, setInStock] = useState(true);
  const [isAddedToCart, setIsAddedToCart] = useState(false);

  useEffect( () => {
    async function doIt() {
      let pproduct = await getProduct(productid);
      if (!pproduct) {
        history.push('/')
        return
      }
      setProduct(pproduct);
      let { variants, variantsDetails } = pproduct;
      let pproductVars = [];
      for(let i=0; i < variants.length; i++) {
        pproductVars.push({
         ...variants[i], 
         ...(variantsDetails.find((itmInner) => itmInner.sku === variants[i].sku))}
        );
      }
      let pselectPVar = pproductVars[0];
      let variantUrl = new URLSearchParams(location.search).get("variant");
      if (variantUrl) {
        let findVariant = pproductVars.find(x => x.sku === variantUrl);
        if (findVariant) {
          pselectPVar = findVariant;
        }
        setProductVars(pproductVars);
        setSelectPVar(pselectPVar); 
        setPrice(pselectPVar.price);
        setInStock(pselectPVar.inStock);
        if (!pselectPVar.inStock) {
          setQuantity(0);
        }
        // start Pinterest
        ReactPinterestTag.track('PageVisit', {
         product_id: 'SKU-' + variantUrl,
        });
        // end Pinterest
      } else {
        history.replace({
          search: '?variant=' + pselectPVar.sku
        })
      }
    }
    doIt();
  }, [history, location, productid]);

  async function getProduct(productId) {
    try {
      let apiReturn = await API.post('products', 'getpvariants', {
        body: { productId }
      }).then(response => response).catch(error => error)
      if (apiReturn) {
        return apiReturn.product
      }
    } catch (e) {
      console.log(e)
    }
  }

  function handleVariantOnClick(sku) {
    let pselectPVar = productVars.find(pv => pv.sku === sku);
    let pprice = pselectPVar.price;
    let pinStock = pselectPVar.inStock;
    setSelectPVar(pselectPVar);
    setPrice(pprice);
    setInStock(pinStock);
    history.replace({
      search: '?variant=' + sku
    });
  }

  function handleQuantityUpOnClick(e) {
    e.stopPropagation()
    let newVal = parseInt(quantity) + 1
    if (newVal < 0) {
      newVal = 1
    }
    setQuantity(newVal);
  }

  function handleQuantityDownOnClick(e) {
    e.stopPropagation()
    let newVal = parseInt(quantity) - 1
    if (newVal < 1) {
      newVal = 1
    }
    setQuantity(newVal);
  }

  function handleQuantityOnChange(e) {
    setQuantity(e.target.value);
  }

  async function handleAddToXOnClick() {
    let productId = product.id;
    let apiBase = 'carts';
    let apiPath = 'addtocart';
    setIsAddingToCart(true);
    try {
      await Auth.currentCredentials()
      let newItem = {
        productId,
        sku: selectPVar.sku,
        quantity: quantity,
        title: selectPVar.nameen,
        image: webMedia + '/200x200/' + selectPVar.images[0],
        url: window.location.href
      }
      const apiReturn = await API.post(apiBase, apiPath, {
        body: newItem
      }).then(response => response).catch(error => error)
      if (apiReturn.nostock) {
        setIsAddingToCart(false);
        alert(apiReturn.message);
        window.location.reload(true);
        return;
      }
      if (apiReturn.unique) {
        setIsAddingToCart(false);
        alert(apiReturn.message);
        return;
      }
      updateCartCount(apiReturn.cart.quantityTotal)
      setIsAddingToCart(false);
      setIsAddedToCart(true);
      setQtyAdded(quantity);
      // start Pinterest
      ReactPinterestTag.track('AddToCart', {
       product_id: 'SKU-' + newItem.sku,
       product_quantity: newItem.quantity
      });
      // end Pinterest
    } catch(e) {
      setIsAddingToCart(false);
      console.log('Error. Please contact us.', e)
    }
  }

  function handleCloseAddedToCart() {
    setIsAddedToCart(false);
    setQtyAdded(1);
  }

  function CustomToggle({ children, eventKey }) {
    const custOnClick = useAccordionToggle(eventKey, () =>
      setSelectedTab(eventKey),
    );
    return (
      <div
        className="in"
        onClick={custOnClick}
      >
        {children}
      </div>
    );
  }
  
  return (
    <div className="Product main">
      <CheckAppVersion/>
      <Breadcrums path={[{ name: xHumanize(collid), to: "/collection/" + collid }, { name: product ? product.title : '' }]} />
      {product && <Container>
        <Row>
          <Col md={6} className="carousel-wrap">
            <div className="sticky">
              {selectPVar && selectPVar.images &&
                <ProductImageSlider images={selectPVar.images} source={webMedia} imageHash={imageHash} />
              }
            </div>
          </Col>
          <Col md={6}>
            <div className="vendor">{product.vendor}</div>
            <div className="title">{product.title}</div>
            <div className="price">${price ? xFormatMoney(price) : ''}</div>
            <div className="sku">Code: {selectPVar ? selectPVar.sku : null}</div>
            <div className="intro" dangerouslySetInnerHTML={{ __html: product.intro }} />
            {productVars && selectPVar &&
              <div className="variants">
                <div className="variants-title">{productVars[0] ? productVars[0].title : ''}</div>
                {productVars.map((pvar, idx) => (
                  <Button className={pvar.sku === selectPVar.sku ? "active" : null} size="sm" variant="outline-dark" key={idx} onClick={(e) => handleVariantOnClick(pvar.sku)} >{pvar.label}</Button>
                ))}
              </div>
            }
            <div className="qty">
              <span>Quantity</span>
              <Row>
                <Col xs={12}>
                  <div className="quantity">
                    <QuantityControl
                      onUp={handleQuantityUpOnClick}
                      onDown={handleQuantityDownOnClick} 
                      disabledDown={!inStock}
                      disabledUp={!inStock}
                      disabledText={!inStock}
                      quantity={quantity}
                      onQtyChange={handleQuantityOnChange}
                    />
                  </div>
                  <div className="stock">
                  {inStock ?
                    <span className="in"><MdCheck className="icon" /> In Stock</span>
                    :
                    <span className="out"><GrCircleInformation className="icon"/> Out of Stock</span>
                  }
                  </div>
                </Col>
              </Row>
            </div>
            <ButtonToolbar>
              <LoaderButton
                block
                className="add-to-cart cuatro-primary"
                variant="outline-dark"
                disabled={!inStock}
                type="button"
                size="md"
                isLoading={isAddingToCart}
                text={'Add to Bag • $' + xFormatMoney(parseFloat(price) * parseInt(quantity)) + ' (' + quantity + ')'}
                loadingText="Adding.."
                onClick={(e) => handleAddToXOnClick('cart')}
              />
            </ButtonToolbar>
            <div className="tabs">
              <Accordion defaultActiveKey={1}>
                {product.tabs.map((tab, idx) => {
                  return (
                    <Card key={idx + 1}>
                      <Card.Header>
                        <CustomToggle eventKey={idx + 1}>
                          {tab.title} <div className="updown">{selectedTab === idx + 1 ? <GrUp /> : <GrDown/>}</div>
                        </CustomToggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey={idx + 1}>
                        <Card.Body><div className="tab-value" dangerouslySetInnerHTML={{ __html: tab.value }} /></Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  )
                })}
              </Accordion>
            </div>
          </Col>
        </Row>
      </Container>}
      {isAddedToCart &&
        <AddedToCart 
          qtyAdded={qtyAdded} 
          isAddedToCart={isAddedToCart}
          closeAddedToCart={handleCloseAddedToCart}
        />
      }
    </div>
  );
}