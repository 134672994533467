import React, { useState, useEffect } from "react";
import "./Cart.css";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import CheckAppVersion from "../components/CheckAppVersion";
import Breadcrums from "../components/Breadcrums";
import { useAppContext } from "../lib/contextLib";
import { jamesWattLocJSON } from "../components/Constants";
import { xGetAddressObject, xFormatMoney } from "../components/XFunctions";
import { API, Auth } from "aws-amplify";
import { useHistory, Link } from "react-router-dom";
import QuantityControl from "../components/QuantityControl";
import { FiTrash2, FiArrowRight, FiPackage } from "react-icons/fi";
import { FaSpinner } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import PlaceSearchBar from "../components/PlaceSearchBar";
import ShippingForm from "../components/ShippingForm";

export default function Cart() {
  const { 
    imageHash, 
    updateCartCount, 
    placeAddress, 
    appGetPlaceAddress, 
    appSetPlaceAddress, 
    appSetDeliveryPlace, 
    appUnsetDeliveryPlace
  } = useAppContext();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [cart, setCart] = useState(null);
  const [showShipAddInputModal, setShowShipAddInputModal] = useState(false);

  useEffect( () => {
    async function doIt() {
      setIsLoading(true);
      try {
        await Auth.currentCredentials()
        const apiReturn = await API.post('carts', 'updatecart', {
          body: { values: false }
        }).then(response => response).catch(error => error)
        if (apiReturn && apiReturn.status && !apiReturn.nocart) {
          setCart(apiReturn.cart);
          updateCartCount(apiReturn.cart.quantityTotal);
        } else {
          updateCartCount(0)
        }
        setIsLoading(false)
      } catch(e) {
        setIsLoading(false);
        console.log('Error. Please contact Geedop.', e);
      }
    }
    doIt();
  }, []);

  async function removeCartItem(idx) {
    let cartItems = JSON.parse(JSON.stringify(cart.cartItems));
    cartItems.splice(idx, 1);
    try {
      const apiReturn = await API.post('carts', 'updatecart', {
        body: { cartId: cart.cartId, cartItems: cartItems }
      }).then(response => response).catch(error => error)
      if (apiReturn && apiReturn.status && !apiReturn.nocart) {
        let pcart = apiReturn.cart;
        pcart.subtotal = localCartSubtotal(pcart.cartItems);
        setCart(pcart);
        updateCartCount(apiReturn.cart.quantityTotal);
      }
    } catch (e) {
      setIsLoading(false);
      console.log('Error. Please contact Geedop.', e);
    }
  }

  function handleQuantityUpOnClick(idx) {
    let pcart = JSON.parse(JSON.stringify(cart));
    pcart.cartItems[idx].quantity += 1;
    updateCart(pcart.cartItems);
  }

  function handleQuantityDownOnClick(idx) {
    let pcart = JSON.parse(JSON.stringify(cart));
    pcart.cartItems[idx].quantity -= 1
    updateCart(pcart.cartItems);
  }

  function handleQuantityOnChange(idx) {}

  function handleQuantityDisabledDown(idx) {
    return (cart.cartItems[idx].quantity < 2);
  }

  async function updateCart(cartItems) {
    try {
      let apiReturn = await API.post('carts', 'updatecart', {
        body: { cartId: cart.cartId, cartItems: cartItems }
      }).then(response => response).catch(error => error);
      if (apiReturn) {
        if (apiReturn.cart) {
          setCart(apiReturn.cart);
          updateCartCount(apiReturn.cart.quantityTotal);
        }
        if (apiReturn.message) {
          alert(apiReturn.message);
        }
      } else {
        // updateCartCount(0)
      }
    } catch(e) {
      setIsLoading(false);
      console.log('Error. Please contact Geedop.', e);
    }
  }

  function localCartSubtotal(cartItems) {
    let subtotal = 0.0;
    cartItems.forEach((cItem) => {
      subtotal += parseFloat(cItem.price) * parseInt(cItem.quantity)
    });
    subtotal = Math.round(subtotal * 100) / 100;
    return subtotal;
  }

  function handleSecureCheckoutClick() {
    appGetPlaceAddress();
    setShowShipAddInputModal(true);
  }

  function handleShippingAddressInputModalOnHide() {
    setShowShipAddInputModal(false);
  }

  function handleOnPlaceLoaded(deliveryPlace) {
    // console.log('deliveryPlace: ', deliveryPlace);
    appSetDeliveryPlace(deliveryPlace);
    appSetPlaceAddress(xGetAddressObject(deliveryPlace.address_components));
  }

  function handleLocalPickUpOnClick() {
    let deliveryPlace = { address_components: jamesWattLocJSON };
    handleOnPlaceLoaded(deliveryPlace)
    let pa = xGetAddressObject(deliveryPlace.address_components)
    pa.street2 = 'Ste B7'
    pa.localPickup = true
    appSetPlaceAddress(pa)
    setShowShipAddInputModal(false);
    history.push('/checkout');
  }

  function handleOnShippingNextClick(formData) {
    let pa = JSON.parse(JSON.stringify(placeAddress));
    pa.city         = formData.shipAddressCity;
    pa.country      = formData.shipAddressCountry;
    pa.postal_code  = formData.shipAddressZipCode;
    pa.state        = formData.shipAddressState;
    pa.street       = formData.shipAddressAddressLine1;
    pa.street2      = formData.shipAddressAddressLine2;
    pa.residential  = formData.shipAddressResidential;
    appSetPlaceAddress(pa);
    setShowShipAddInputModal(false);
    history.push('/checkout');
  }

  function handleShippingAddressChange() {
    appUnsetDeliveryPlace();
  }

  function renderCartItemsElements() {
    let cartItemsElements = [];
    let { cartItems } = cart;
    cartItems.sort((a, b) => {
      return a.lineid - b.lineid;
    });
    cartItemsElements = cartItems.map((item, idx) => {
      return (
        <Row key={idx + 1} className="item">
          <Col xs={4}>
            <Row>
              <Col xs={2}>
                {item.lineid}
              </Col>
              <Col xs={10}>
                <div className="preview">
                  <a href={item.url}>
                    <img alt="" src={item.image + '?' + imageHash} />
                  </a>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={8}>
            <Row>
              <Col>
                <div className="title"><a href={item.url}>{item.title}</a></div>
                <div className="sku">sku: {item.sku}</div>
              </Col>
            </Row>
            <Row>
              <Col lg={8}>
                <div className="quantity">
                  <QuantityControl
                    onUp={(e) => handleQuantityUpOnClick(idx)}
                    onDown={(e) => handleQuantityDownOnClick(idx)} 
                    disabledDown={handleQuantityDisabledDown(idx)}
                    disabledText={true}
                    quantity={item.quantity}
                    onQtyChange={(e) => handleQuantityOnChange(idx)}
                  />
                </div>
                <div className="each">
                  {'@ $' + xFormatMoney(item.price) + ' / each'}
                </div>
              </Col>
              <Col lg={2}>
                <div className="linetotal">
                  {'$' + xFormatMoney(parseInt(item.quantity) * parseFloat(item.price))}
                </div>
              </Col>
              <Col lg={2}><FiTrash2 className="remove-item" onClick={(e) => removeCartItem(idx)} /></Col>
            </Row>
          </Col>
        </Row>
      )
    })
    return cartItemsElements;
  }

  return (
    <div className="Cart main">
      <CheckAppVersion/>
      <Breadcrums path={[{ name: 'Cart' }]} />
      <Container className="main-2">
        {isLoading ?
          <div>
            <div><h2>My Order</h2></div>
            <div className="loading"><FaSpinner className="spin" /></div>
          </div>
        :
          <div>
          {(cart && cart.cartItems.length > 0) ?
            <div>
              <h2>My Order</h2>
              <Container>
                {renderCartItemsElements()}
                <Row>
                  <Col className="cart-summary">
                    <div className="subtotal">Order Subtotal: <span className="amount">&nbsp;USD ${xFormatMoney(cart.subtotal)}</span></div>
                    <div className="tax">Tax: <span className="amount">Calculated at checkout</span></div>
                    <div className="shipping">Delivery: <span className="amount">Calculated at checkout</span></div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Link to="/"><Button className="continue-shopping cuatro-back" variant="outline-dark" block >Add More Items</Button></Link>
                  </Col>
                  <Col></Col>
                  <Col>
                    <Button className="checkout cuatro-primary" variant="outline-dark" block onClick={handleSecureCheckoutClick} >Secure Checkout</Button>
                  </Col>
                </Row>
              </Container>
            </div>
          :
             <div>
              <h2>There are no items on your Order</h2>
              <Link to="/"><Button className="shop-now cuatro-back" variant="outline-dark">Add Items Now <FiArrowRight/></Button></Link>
            </div>
          }
          </div>
        }
        {showShipAddInputModal &&
          <Modal
            className="ShippingAddressInput"
            show={showShipAddInputModal}
            size="lg"
            onHide={handleShippingAddressInputModalOnHide}
          >
            <Modal.Body>
              <div className="close" onClick={handleShippingAddressInputModalOnHide}><IoMdClose/></div>
              {(placeAddress && !placeAddress.localPickup) ?
                <ShippingForm 
                  placeAddress={placeAddress}
                  onSubmit={handleOnShippingNextClick}
                  onChange={handleShippingAddressChange}
                />
              :
                <div>
                  <div className="add-location"><FiPackage /></div>
                  <p>Please enter your delivery address:</p>
                  <PlaceSearchBar onPlaceLoaded={handleOnPlaceLoaded} />
                  <div className="or">- OR - </div>
                  <Button onClick={handleLocalPickUpOnClick} className="local-pickup" variant="outline-dark" block >Contactless Pickup<br/>El Paso, Texas</Button>
                </div>
              }
            </Modal.Body>
          </Modal>
        }
      </Container>
    </div>
  );
}