import React, { Component } from "react";
import { Form, Col, Button } from "react-bootstrap";
import "./ShippingForm.css";
import { US_STATES } from './Constants'
import validator from "validator";

class ShippingForm extends Component {
  constructor(props) {
    super(props);
    this.validator = new FormValidator([
      {
        field: 'shipAddressAddressLine1',
        method: 'isEmpty',
        validWhen: false,
        message: 'Required' 
      },
      {
        field: 'shipAddressCity',
        method: 'isEmpty',
        validWhen: false,
        message: 'Required' 
      },
      {
        field: 'shipAddressState',
        method: 'isEmpty',
        validWhen: false,
        message: 'Required' 
      },
      {
        field: 'shipAddressZipCode',
        method: 'isEmpty',
        validWhen: false,
        message: 'Required' 
      },
      {
        field: 'shipAddressZipCode', 
        method: 'matches',
        args: [/^\d\d\d\d\d$/],
        validWhen: true, 
        message: '5 digits required'
      },
    ])
    this.state = {
      shipAddressAddressLine1:  '',
      shipAddressAddressLine2:  '',
      shipAddressCity:          '',
      shipAddressState:         '',
      shipAddressZipCode:       '',
      shipAddressCountry:       'United States',
      shipAddressResidential:   false,
      validation: this.validator.valid(),
    };
    this.submitted = false;
  }
  componentDidMount = () => {
    let { placeAddress } = this.props
    this.setState({ 
      shipAddressAddressLine1:  placeAddress.street,
      shipAddressAddressLine2:  placeAddress.street2,
      shipAddressCity:          placeAddress.city,
      shipAddressState:         placeAddress.state,
      shipAddressZipCode:       placeAddress.postal_code,
      shipAddressResidential:   placeAddress.residential
    })
  }
  handleInputChange = (e, targetId) => {
    e.preventDefault();
    this.setState({
      [targetId]: e.target.value
    });
  }
  handleShipAddressStateChange = (e, targetId) => {
    this.handleInputChange(e, targetId)
  }
  handleCheckChange = (e, targetId) => {
    this.setState({
      [targetId]: e.target.checked
    });
  }
  handleSubmitClick = async (e) => {
    e.preventDefault();
    this.continueSubmit()
  }
  continueSubmit = async() => {
    const validation = JSON.parse(JSON.stringify(this.validator.validate(this.state)));
    this.setState({ validation }, async() => {
      this.submitted = true;
      if (validation.isValid) {
        // handle actual form submission here
        this.setState({ isProcessing: true });
        let formData = JSON.parse(JSON.stringify(this.state))
        this.props.onSubmit(formData);
        this.setState({ isProcessing: false });
      }
    });
  }

  render() {
    let validation = this.submitted ?                         // if the form has been submitted at least once
                     this.validator.validate(this.state) :    // then check validity every time we render
                     this.state.validation                    // otherwise just use what's in state
    return (
      <div>
        <Form className="ShippingForm" onSubmit={this.handleSubmitClick}>
          <Form.Row className="mt-4">
            <Form.Group as={Col}>
              <h5>Delivery Address</h5>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} className={validation.shipAddressAddressLine1.isInvalid && 'has-error'}>
              <Form.Label>Address Line 1</Form.Label>
              <Form.Control value={this.state.shipAddressAddressLine1} type="text" placeholder="Address Line 1" onChange={(e) => this.handleInputChange(e, 'shipAddressAddressLine1')} />
              <Form.Control.Feedback>{validation.shipAddressAddressLine1.message}</Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Address Line 2</Form.Label>
              <Form.Control value={this.state.shipAddressAddressLine2} type="text" placeholder="Address Line 2 (optional)" onChange={(e) => this.handleInputChange(e, 'shipAddressAddressLine2')} />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} sm={6} className={validation.shipAddressCity.isInvalid && 'has-error'}>
              <Form.Label>City</Form.Label>
              <Form.Control value={this.state.shipAddressCity} type="text" placeholder="City" onChange={(e) => this.handleInputChange(e, 'shipAddressCity')} />
              <Form.Control.Feedback>{validation.shipAddressCity.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} sm={6} className={validation.shipAddressState.isInvalid && 'has-error'}>
              <Form.Label>State</Form.Label>
              <Form.Control value={this.state.shipAddressState} as="select" onChange={(e) => this.handleShipAddressStateChange(e, 'shipAddressState')}>
                {US_STATES.map((state, idx) => {
                  return <option key={idx} value={state.id}>{state.text}</option>
                })}
              </Form.Control>
              <Form.Control.Feedback>{validation.shipAddressState.message}</Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} className={validation.shipAddressZipCode.isInvalid && 'has-error'}>
              <Form.Label>Postal Code</Form.Label>
              <Form.Control value={this.state.shipAddressZipCode} type="text" placeholder="ZIP Code/Postal Code" onChange={(e) => this.handleInputChange(e, 'shipAddressZipCode')} />
              <Form.Control.Feedback>{validation.shipAddressZipCode.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} className="">
              <Form.Label>Country</Form.Label>
              <Form.Control value={this.state.shipAddressCountry} type="text" disabled />
              <Form.Control.Feedback>{validation.shipAddressZipCode.message}</Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Check type="checkbox" label="Residential" checked={this.state.shipAddressResidential} onChange={(e) => this.handleCheckChange(e, 'shipAddressResidential')} />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <div className="buttons">
                <Button className="change-modal cuatro-back" variant="outline-dark" onClick={this.props.onChange}>Change</Button>
                <Button className="next-modal cuatro-primary" variant="outline-dark" type="submit">Next</Button>
              </div>
            </Form.Group>
          </Form.Row>
        </Form>
      </div>
    );
  }
}

export default ShippingForm;

class FormValidator {
  constructor(validations) {
    // validations is an array of validation rules specific to a form
    this.validations = validations;
  }

  validate(state) {
    // start out assuming valid
    let validation = this.valid();

    // for each validation rule
    this.validations.forEach(rule => {

      // if the field hasn't already been marked invalid by an earlier rule
      if (!validation[rule.field].isInvalid) {
        // determine the field value, the method to invoke and optional args from 
        // the rule definition
        const field_value = state[rule.field].toString();
        const args = rule.args || [];
        const validation_method = 
              typeof rule.method === 'string' ?
              validator[rule.method] : 
              rule.method
              
        // call the validation_method with the current field value as the first
        // argument, any additional arguments, and the whole state as a final
        // argument.  If the result doesn't match the rule.validWhen property,
        // then modify the validation object for the field and set the isValid
        // field to false
        if(validation_method(field_value, ...args, state) !== rule.validWhen) {
          validation[rule.field] = { isInvalid: true, message: rule.message }
          validation.isValid = false;
        }
      }
    });

    return validation;
  }

  valid() {
    const validation = {}

    this.validations.map(rule => (
      validation[rule.field] = { isInvalid: false, message: '' }
    ));

    return { isValid: true, ...validation };
  }
}

