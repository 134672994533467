export const webSource = 'https://websource.cuatro.us/'
export const webMedia = 'https://84mbjzuf67.execute-api.us-east-1.amazonaws.com/dev'
export const US_STATES = [
  {
    text: "",
    id: null
  },
  {
    text: "Alabama",
    id: "AL"
  },
  {
    text: "Alaska",
    id: "AK"
  },
  {
    text: "American Samoa",
    id: "AS"
  },
  {
    text: "Arizona",
    id: "AZ"
  },
  {
    text: "Arkansas",
    id: "AR"
  },
  {
    text: "California",
    id: "CA"
  },
  {
    text: "Colorado",
    id: "CO"
  },
  {
    text: "Connecticut",
    id: "CT"
  },
  {
    text: "Delaware",
    id: "DE"
  },
  {
    text: "District of Columbia",
    id: "DC"
  },
  {
    text: "Florida",
    id: "FL"
  },
  {
    text: "Georgia",
    id: "GA"
  },
  {
    text: "Guam",
    id: "GU"
  },
  {
    text: "Hawaii",
    id: "HI"
  },
  {
    text: "Idaho",
    id: "ID"
  },
  {
    text: "Illinois",
    id: "IL"
  },
  {
    text: "Indiana",
    id: "IN"
  },
  {
    text: "Iowa",
    id: "IA"
  },
  {
    text: "Kansas",
    id: "KS"
  },
  {
    text: "Kentucky",
    id: "KY"
  },
  {
    text: "Louisiana",
    id: "LA"
  },
  {
    text: "Maine",
    id: "ME"
  },
  {
    text: "Maryland",
    id: "MD"
  },
  {
    text: "Massachusetts",
    id: "MA"
  },
  {
    text: "Michigan",
    id: "MI"
  },
  {
    text: "Minnesota",
    id: "MN"
  },
  {
    text: "Mississippi",
    id: "MS"
  },
  {
    text: "Missouri",
    id: "MO"
  },
  {
    text: "Montana",
    id: "MT"
  },
  {
    text: "Nebraska",
    id: "NE"
  },
  {
    text: "Nevada",
    id: "NV"
  },
  {
    text: "New Hampshire",
    id: "NH"
  },
  {
    text: "New Jersey",
    id: "NJ"
  },
  {
    text: "New Mexico",
    id: "NM"
  },
  {
    text: "New York",
    id: "NY"
  },
  {
    text: "North Carolina",
    id: "NC"
  },
  {
    text: "North Dakota",
    id: "ND"
  },
  {
    text: "Northern Mariana Islands",
    id: "MP"
  },
  {
    text: "Ohio",
    id: "OH"
  },
  {
    text: "Oklahoma",
    id: "OK"
  },
  {
    text: "Oregon",
    id: "OR"
  },
  {
    text: "Pennsylvania",
    id: "PA"
  },
  {
    text: "Rhode Island",
    id: "RI"
  },
  {
    text: "South Carolina",
    id: "SC"
  },
  {
    text: "South Dakota",
    id: "SD"
  },
  {
    text: "Tennessee",
    id: "TN"
  },
  {
    text: "Texas",
    id: "TX"
  },
  {
    text: "United States Minor Outlying Islands",
    id: "UM"
  },
  {
    text: "Utah",
    id: "UT"
  },
  {
    text: "Vermont",
    id: "VT"
  },
  {
    text: "Virgin Islands, U.S.",
    id: "VI"
  },
  {
    text: "Virginia",
    id: "VA"
  },
  {
    text: "Washington",
    id: "WA"
  },
  {
    text: "West Virginia",
    id: "WV"
  },
  {
    text: "Wisconsin",
    id: "WI"
  },
  {
    text: "Wyoming",
    id: "WY"
  }
]
export const jamesWattLocJSON = [
  { 
    "long_name":"11450",
    "short_name":"11450",
    "types":["street_number"]
  },
  {
    "long_name":"James Watt Drive",
    "short_name":"James Watt Dr",
    "types":["route"]
  },
  {
    "long_name":"East El Paso",
    "short_name":"East El Paso",
    "types":["neighborhood","political"]
  },
  {
    "long_name":"El Paso",
    "short_name":"El Paso",
    "types":["locality","political"]
  },
  {
    "long_name":"El Paso County",
    "short_name":"El Paso County",
    "types":["administrative_area_level_2","political"]
  },
  {
    "long_name":"Texas",
    "short_name":"TX",
    "types":["administrative_area_level_1","political"]
  },
  {
    "long_name":"United States",
    "short_name":"US",
    "types":["country","political"]
  },
  {
    "long_name":"79936",
    "short_name":"79936",
    "types":["postal_code"]
  },
  {
    "long_name":"6409",
    "short_name":"6409",
    "types":["postal_code_suffix"]
  }
]