export function xValidateEmail(email) {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
}

export function xHumanize(str) {
  var i, frags = str.split('-');
  for (i=0; i<frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(' ');
}

export function xFormatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
    const negativeSign = amount < 0 ? "-" : "";
    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;
    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
  } catch (e) {
    console.log(e)
    return '0.00';
  }
}

export function xFormatDate(dateStr, noTime = false) {
  let date = new Date(dateStr);
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  let result = monthNames[date.getMonth()] + " " + xZeroPad(date.getDate()) + ", " + date.getFullYear();
  if (!noTime) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12 ? hours % 12 : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    result = result + " " + strTime;
  }
  return result;
}
function xZeroPad(number) {
  if (number < 10) {
    return '0' + number;
  }
  return number;
}

export function xGet4DaysFromToday() {
  var dayAbbrs = ['SUN','MON','TUE','WED','THU','FRI','SAT'];
  var days = [];
  for (var daysToAdd = 1; daysToAdd < 5; daysToAdd++) {
    var someDate = new Date();
    someDate.setHours(23,59,59,999);
    someDate.setDate(someDate.getDate() + daysToAdd); 
    var dd = someDate.getDate();
    var dayAbbr = dayAbbrs[someDate.getDay()]
    var mTime = someDate.getTime()
    days.push({ quick: daysToAdd, num: dd, abbr: dayAbbr, time: mTime })
  }
  return days;
}

export function xAddDaysToToday(daysToAdd) {
  var now = new Date();
  now.setHours(23,59,59,999);
  now.setDate(now.getDate() + daysToAdd)
  return now
}

export function xFullName2Parts(fullname) {
  let first = '';
  let last = '';
  let nameArr = fullname.split(' ');
  if (nameArr.length > 1) {
    first = fullname.substr(0,fullname.indexOf(' '));
    last = fullname.substr(fullname.indexOf(' ')+1);
  } else if (nameArr.length === 1) {
    first = nameArr[0];
  }
  return {first, last};
}

export function xGetAddressObject(address_components) {
  var ShouldBeComponent = {
    number: ["street_number"],
    street: ["street_address", "route"],
    city: [
      "locality",
      "sublocality",
      "sublocality_level_1",
      "sublocality_level_2",
      "sublocality_level_3",
      "sublocality_level_4"
    ],
    state: [
      "administrative_area_level_1",
      "administrative_area_level_2",
      "administrative_area_level_3",
      "administrative_area_level_4",
      "administrative_area_level_5"
    ],
    postal_code: ["postal_code"],
    country: ["country"]
  };
  var address = {
    number: "",
    street: "",
    street2: "",
    city: "",
    state: "",
    postal_code: "",
    country: "",
    residential: false,
  };
  address_components.forEach(component => {
    for (var shouldBe in ShouldBeComponent) {
      if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
        if (shouldBe === "country" || shouldBe === "state") {
          address[shouldBe] = component.short_name;
        } else {
          address[shouldBe] = component.long_name;
        }
      }
    }
  });
  address.street = address.number + ' ' + address.street;
  delete address.number;
  return address;
}