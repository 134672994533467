import React from "react";
import "./Privacy.css";
import { Container, Row, Col } from "react-bootstrap";
import CheckAppVersion from "../components/CheckAppVersion";
import Breadcrums from "../components/Breadcrums";
import { Link } from "react-router-dom";

export default function Privacy() {
  return (
    <div className="Privacy main">
      <CheckAppVersion/>
      <Breadcrums path={[{name: 'Privacy Policy'}]} />
      <Container>
        <Row>
          <Col sm={0} md={2}></Col>
          <Col sm={12} md={8} className="all">
            <h3>Privacy Policy</h3>
            <hr className="short" />
            <span className="updated">Last updated: June 1, 2021</span>
            <p className="intro">This Privacy Policy governs the manner in which Cuatro LLC (Cuatro) collects, uses, maintains and discloses information collected from you, the user of its Internet Web sites: https://cuatro.us and https://www.cuatro.us, collectively refferred to as the "Cuatro Website" or the “Service”. The term “you” or “User” refers to a Visitor, Member of Client. The term “we” refers to Cuatro.</p>
            <ol>
            <li>Information we collect</li>
            </ol>
            <p>We collect information from you when you register for our Service or place an order.</p>
            <p>When ordering or registering for our Service, as appropriate, you may be asked to enter your name, e-mail address, mailing address or credit card information. You may, however, visit our site anonymously.</p>
            <ol start="2">
            <li>How we use information we collect</li>
            </ol>
            <p>Any of the information we collect from you may be used in one of the following ways:</p>
            <p>To process transactions</p>
            <p>Your information, whether public or private, will not be sold, exchanged, transferred, or given to any other company for any reason whatsoever, without your consent, other than for the express purpose of delivering the Service requested.</p>
            <p>To send periodic emails</p>
            <p>The email address you provide for order processing will only be used to send you information and updates pertaining to your order, it may also be used to respond to inquiries, and/or other requests or questions.</p>
            <ol start="3">
            <li>How we protect your information</li>
            </ol>
            <p>We implement a variety of security measures to maintain the safety of your personal information when you place an order or enter, submit, or access your personal information.</p>
            <p>All supplied sensitive/credit information is transmitted via Secure Socket Layer (SSL) technology and then encrypted into our Payment gateway providers database only to be accessible by those authorized with special access rights to such systems.</p>
            <p>After a transaction, your financial information (credit cards, financials, etc.) will not be stored on our servers.</p>
            <ol start="4">
            <li>Web browser cookies</li>
            </ol>
            <p>We may use "cookies" to enhance user experience when you use our Service. Your web browser places cookies on your hard drive for record-keeping purposes and sometimes to track information about you. You may choose to set your web browser to refuse cookies, or to alert you when cookies are being sent. If you do so, note that the Service may not function properly.</p>
            <ol start="5">
            <li>Information we share</li>
            </ol>
            <p>In order to help provide our Service, we may occasionally provide information to other companies that provide limited services on our behalf. These companies are permitted to use any personal information that we provide to them only in connection with the services they provide to us and subject to their compliance with our privacy practices.</p>
            <p>If partner features are requested, Cuatro will, with your permission, share personal information with those partners. Cuatro is not responsible for the privacy and information handling practices of these partners. Information that is collected by or sent to Cuatro may be stored and processed in the United States or in any other country in which Cuatro, or its affiliates, subsidiaries or service providers maintain facilities.</p>
            <p>Cuatro may access or disclose your personal information, including the content of communications, in order to: (a) comply with the law or respond to lawful requests or legal process; (b) protect the rights or property of Cuatro or its clients, including the enforcement of our agreements or policies governing the use of our Service; or (c) act on a good faith belief that such access or disclosure is necessary to protect the personal safety of Cuatro employees, clients, or the public.</p>
            <ol start="6">
            <li>Third party links</li>
            </ol>
            <p>Occasionally, at our discretion, we may include or offer third party products or services on our sites. These third party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.</p>
            <ol start="7">
            <li>Online Privacy Policy Only</li>
            </ol>
            <p>This online privacy policy applies only to information collected through our Service and not to information collected offline.</p>
            <ol start="8">
            <li>Terms and Conditions</li>
            </ol>
            <p>Please also visit our <Link to="/terms">Terms and Conditions</Link> establishing the use, disclaimers, and limitations of liability governing the use of our Service.</p>
            <ol start="9">
            <li>Your acceptance of these terms</li>
            </ol>
            <p>By using our Service, you consent to this Privacy Policy.</p>
            <ol start="10">
            <li>Changes to our Privacy Policy</li>
            </ol>
            <p>If we decide to change our Privacy Policy, we will post those changes on this page and/or update the Privacy Policy modification date at the top of this document.</p>
            <ol start="11">
            <li>Contacting Us</li>
            </ol>
            <p>If you have any questions about this Privacy Policy, please visit our <Link to="/connect">contact page</Link>.</p>
          </Col>
          <Col sm={0} md={2}></Col>
        </Row>
      </Container>
    </div>
  );
}