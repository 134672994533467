import React, { useState, useEffect } from "react";
import "./Home.css";
import config from "../config";
import { API, Auth } from "aws-amplify";
import { webMedia } from "../components/Constants";
import { useAppContext } from "../lib/contextLib";
import CheckAppVersion from "../components/CheckAppVersion";
import { Container, Button, Row, Col } from "react-bootstrap";
import landing_separator from '../images/curvesseparator.png';
import landing_with_love from '../images/landing-with-love.png';
import { Link } from "react-router-dom";
import { xFormatMoney } from '../components/XFunctions';
import LoaderButton from "../components/LoaderButton";
import kitchen from '../images/kitchen.png';
import personalcare from '../images/personalcare.png';
import accessories from '../images/accessories.png';
import bundles from '../images/bundles.png';
import home_candles from '../images/home-candles.png';
import home_candle_white from '../images/home-candle-white.png';
import home_candle_pink from '../images/home-candle-pink.png';
import home_landing from '../images/home-landing.png';
import home_toothbrush from '../images/home-toothbrush.png';
import home_icon_developed from '../images/home-icon-developed.png';
import home_icon_packaging from '../images/home-icon-packaging.png';
import home_icon_sustainable from '../images/home-icon-sustainable.png';
import AddedToCart from "../components/AddedToCart";
import Carousel from "../components/Carousel";
// start Pinterest
import ReactPinterestTag from "react-pinterest-tag";
// end Pinterest

export default function Home() {
  const { setCartCount, imageHash } = useAppContext();
  const [isAddingToCart, setIsAddingToCart] = useState(false);
  const [bestSellers, setBestSellers] = useState([]);
  const [buyLookMediaCarousel, setBuyLookMediaCarousel] = useState(null);
  const [isAddedToCart, setIsAddedToCart] = useState(false);
  const [qtyAdded, setQtyAdded] = useState(0);
  
  useEffect(() => {
    // Anything in here is fired on component mount.
    const onLoad = async() => {
      let bestSellersCall = await getSection('best-sellers', config.stage);
      setBestSellers(bestSellersCall.bests);
      let buyLookMediaCarouselCall = await getSection('buylook-media-carousel', config.stage);
      setBuyLookMediaCarousel(buyLookMediaCarouselCall.carousel);
    };
    onLoad();
    return () => {}
  }, []);
  async function getSection(id, stage) {
    try {
      let apiReturn = await API.post('products', 'getsection', {
        body: { id, stage }
      }).then(response => response).catch(error => error)
      if (apiReturn) {
        return apiReturn
      }
    } catch (e) {
      console.log(e)
    }
  }
  async function handleAddToXOnClick(productId, nameEn, pImage) {
    setIsAddingToCart(true);
    try {
      await Auth.currentCredentials()
      let newItem = {
        productId,
        sku: productId,
        quantity: 1,
        title: nameEn,
        image: webMedia + '/200x200/' + pImage,
        url: window.location.href
      };
      const apiReturn = await API.post('carts', 'addtocart', {
        body: newItem
      }).then(response => response).catch(error => error)
      if (apiReturn.nostock) {
        setIsAddingToCart(false);
        alert(apiReturn.message)
        window.location.reload(true);
        return
      }
      if (apiReturn.unique) {
        setIsAddingToCart(true);
        alert(apiReturn.message)
        return
      }
      setCartCount(apiReturn.cart.quantityTotal);
      setIsAddingToCart(false);
      setIsAddedToCart(true);
      setQtyAdded(1);
      // start Pinterest
      ReactPinterestTag.track('AddToCart', {
       product_id: 'SKU-' + newItem.sku,
       product_quantity: newItem.quantity
      });
      // end Pinterest
    } catch(e) {
      setIsAddingToCart(false)
      console.log('Error. Please contact us.', e)
    }
  }
  function handleCloseAddedToCart() {
    setIsAddedToCart(false);
    setQtyAdded(1);
  }
 
  return (
    <div className="Home main">
      <CheckAppVersion/>
      <Container fluid className="section-single hero">
        <Row>
          <Col className="lowerleft" >
            <div className="content">
              <h2 className="lower"><span>Plastic-Free Essentials for Your Home</span></h2>
              <div className="text light">Our silicone bags can be used in the freezer, microwave, oven, stove and fridge.</div>
              <Link to="/collection/all-products">
                <Button className="linkbutton white" size="sm" variant="outline-dark" >Shop Now</Button>
              </Link>
            </div>
            <div className="imageback d-md-none" style={{ backgroundImage: `url(" ` + home_landing  + ` ")`}}></div>
            <div className="imageback d-none d-md-block" style={{ backgroundImage: `url("` + home_landing + `")`}}></div>
            <div className="hgradcover"></div>
          </Col>
        </Row>
      </Container>
      <Container fluid className="section-features">
        <Row>
          <Col>
            <Row>
              <Col sm={1}></Col>
              <Col sm={3} className="feat lft">
                <img alt="" src={home_icon_developed + '?' + imageHash}/>
                <p>Developed in<br/>El Paso, TX </p>
              </Col>
              <Col sm={4} className="feat">
                <img alt="" src={home_icon_sustainable + '?' + imageHash}/>
                <p>Sustainable<br/>Materials</p>
              </Col>
              <Col sm={3} className="feat rgt">
                <img alt="" src={home_icon_packaging + '?' + imageHash}/>
                <p>Eco-friendly<br/>Packaging</p>
              </Col>
              <Col sm={1}></Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container className="section-dual">
        <Row>
          <Col sm={6} className="left" >
            <div className="wrap" >
              <img alt="" src={home_toothbrush + '?' + imageHash}/>
            </div>
          </Col>
          <Col sm={6} className="right" >
            <div className="wrap" >
              <h2 className="light">You're about to change your world</h2>
              <div className="separator">
                <img alt="" src={landing_separator}/>
              </div>
              <div className="text">Whether at home or on-the-go, take care of yourself with our products made with natural materials that come in 100% recyclable packaging.</div>
              <Link to="/collection/all-products">
                <Button className="linkbutton light" size="sm" variant="outline-dark" >Shop Now</Button>
              </Link>
            </div>
            <div className="sideimg">
              <img alt="" src={landing_with_love + '?' + imageHash}/>
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid className="subland" >
        <Row>
          <Col>
            <h3>Best Sellers</h3>
            <div className="text">
              Look at the products our community can't stop talking about
            </div>
          </Col>
        </Row>
      </Container>
      {bestSellers && bestSellers.length > 0 &&
        <Container className="best-sellers" >
          <Row>
            <Col md={6} className="mb-5">
              <Row className="sub">
                <Col xs={6} className="h-link">
                  <Link to={bestSellers[0].url + "?variant=" + bestSellers[0].sku}>
                    <img src={webMedia + '/255x255/' + bestSellers[0].images[0] + '?' + imageHash} alt="" />
                    <div className="name">{bestSellers[0].nameen}</div>
                    <div className="price">{'$' + xFormatMoney(parseFloat(bestSellers[0].price)) }</div>
                  </Link>
                  <div className="addtobag">
                    <LoaderButton
                      block
                      className="linkbutton dark"
                      variant="outline-dark"
                      disabled={!true}
                      type="button"
                      size="md"
                      isLoading={isAddingToCart}
                      text={'Add to Bag'}
                      loadingText="Adding.."
                      onClick={(e) => handleAddToXOnClick(bestSellers[0].sku, bestSellers[0].nameen, bestSellers[0].images[0])}
                    />
                  </div>
                </Col>
                <Col xs={6} className="h-link">
                  <Link to={bestSellers[1].url + "?variant=" + bestSellers[1].sku}>
                    <img src={webMedia + '/255x255/' + bestSellers[1].images[0] + '?' + imageHash} alt="" />
                    <div className="name">{bestSellers[1].nameen}</div>
                    <div className="price">{'$' + xFormatMoney(parseFloat(bestSellers[1].price)) }</div>
                  </Link>
                  <div className="addtobag">
                    <LoaderButton
                      block
                      className="linkbutton dark"
                      variant="outline-dark"
                      disabled={!true}
                      type="button"
                      size="md"
                      isLoading={isAddingToCart}
                      text={'Add to Bag'}
                      loadingText="Adding.."
                      onClick={(e) => handleAddToXOnClick(bestSellers[1].sku, bestSellers[1].nameen, bestSellers[1].images[0])}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={6} className="mb-5">
              <Row className="sub">
                <Col xs={6} className="h-link">
                  <Link to={bestSellers[2].url + "?variant=" + bestSellers[2].sku}>
                    <img src={webMedia + '/255x255/' + bestSellers[2].images[0] + '?' + imageHash} alt="" />
                    <div className="name">{bestSellers[2].nameen}</div>
                    <div className="price">{'$' + xFormatMoney(parseFloat(bestSellers[2].price)) }</div>
                  </Link>
                  <div className="addtobag">
                    <LoaderButton
                      block
                      className="linkbutton dark"
                      variant="outline-dark"
                      disabled={!true}
                      type="button"
                      size="md"
                      isLoading={isAddingToCart}
                      text={'Add to Bag'}
                      loadingText="Adding.."
                      onClick={(e) => handleAddToXOnClick(bestSellers[2].sku, bestSellers[2].nameen, bestSellers[2].images[0])}
                    />
                  </div>
                </Col>
                <Col xs={6} className="h-link">
                  <Link to={bestSellers[3].url + "?variant=" + bestSellers[3].sku}>
                    <img src={webMedia + '/255x255/' + bestSellers[3].images[0] + '?' + imageHash} alt="" />
                    <div className="name">{bestSellers[3].nameen}</div>
                    <div className="price">{'$' + xFormatMoney(parseFloat(bestSellers[3].price)) }</div>
                  </Link>
                  <div className="addtobag">
                    <LoaderButton
                      block
                      className="linkbutton dark"
                      variant="outline-dark"
                      disabled={!true}
                      type="button"
                      size="md"
                      isLoading={isAddingToCart}
                      text={'Add to Bag'}
                      loadingText="Adding.."
                      onClick={(e) => handleAddToXOnClick(bestSellers[3].sku, bestSellers[3].nameen, bestSellers[3].images[0])}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      }
      <Container fluid className="section-single">
        <Row>
          <Col className="center" >
            <div className="imagetop one d-sm-none"><img alt="" src={home_candle_pink}/></div>
            <div className="middle">
              <div className="content">
                <h2 className="">#cuatrostyle</h2>
                <div className="text">We'd love to see how you style your spaces with Cuatro</div>
                <Link to="/collection/accessories">
                  <Button className="linkbutton white" size="sm" variant="outline-dark" >See More</Button>
                </Link>
              </div>
              <div className="imageback d-none d-sm-block" style={{ backgroundImage: `url("` + home_candles + `")`}}></div>
            </div>
            <div className="imagetop two d-sm-none"><img alt="" src={home_candle_white}/></div>
          </Col>
        </Row>
      </Container>
      <Container fluid className="shoprange" >
        <Row>
          <Col>
            <h3>Shop By Range</h3>
          </Col>
        </Row>
      </Container>
      <Container className="home-links" >
        <Row>
          <Col md={6} className="mb-4">
            <Row>
              <Col xs={6} className="r-link">
                <Link to="/collection/kitchen">
                  <img src={kitchen + '?' + imageHash} alt="" />
                </Link>
              </Col>
              <Col xs={6} className="r-link">
                <Link to="/collection/personal-care">
                  <img src={personalcare + '?' + imageHash} alt="" />
                </Link>
              </Col>
            </Row>
          </Col>
          <Col md={6} className="mb-4">
            <Row>
              <Col xs={6} className="r-link">
                <Link to="/collection/accessories">
                  <img src={accessories + '?' + imageHash} alt="" />
                </Link>
              </Col>
              <Col xs={6} className="r-link">
                <Link to="/collection/bundles">
                  <img src={bundles + '?' + imageHash} alt="" />
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container className="shopall">
        <Row>
          <Col>
            <Link to="/collection/all-products">
              <Button className="linkbutton dark" size="sm" variant="outline-dark" >Shop All</Button>
            </Link>
          </Col>
        </Row>
      </Container>
      {isAddedToCart &&
        <AddedToCart 
          qtyAdded={qtyAdded} 
          isAddedToCart={isAddedToCart}
          closeAddedToCart={handleCloseAddedToCart}
        />
      }
      {buyLookMediaCarousel &&
        <Carousel 
          data={buyLookMediaCarousel}
          handleAddToXOnClick={handleAddToXOnClick}
          isAddingToCart={isAddingToCart}
         />
      }
    </div>
  );
}
