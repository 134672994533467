import React from "react";
import { Form, Col } from "react-bootstrap";
/* global google */

class PlaceSearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.autocompleteInput = React.createRef();
    this.autocomplete = null;
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
  }
  componentDidMount() {
    this.autocomplete = new google.maps.places.Autocomplete(this.autocompleteInput.current, 
      {
        types: ["geocode"],
        componentRestrictions: {country: 'us'},
      });
    this.autocomplete.addListener('place_changed', this.handlePlaceChanged);
  }
  handlePlaceChanged() {
    const place = this.autocomplete.getPlace();
    this.props.onPlaceLoaded(place);
  }
  render() {
    return (
      <Form className="PlaceSearchBar">
        <Form.Row>
          {/*<Form.Group as={Col} md={3}></Form.Group>*/}
          <Form.Group as={Col} md={12}>
            <Form.Control ref={this.autocompleteInput} type="text" id="autocomplete" placeholder="Enter your delivery address" />
          </Form.Group>
          {/*<Form.Group as={Col} md={3}></Form.Group>*/}
        </Form.Row>
      </Form>
    );
  }
}
export default PlaceSearchBar;