import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { useHistory, useLocation } from "react-router-dom";
import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../lib/contextLib";
import { useFormFields } from "../lib/hooksLib";
import { onError } from "../lib/errorLib";
import "./Signup.css";
import { Auth, API } from "aws-amplify";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CheckAppVersion from "../components/CheckAppVersion";

export default function Signup() {
  const [fields, handleFieldChange] = useFormFields({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    confirmationCode: "",
  });
  const history = useHistory();
  const [newUser, setNewUser] = useState(null);
  const { isAuthenticated, userHasAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const [redirected, setRedirected] = useState(false);
  useEffect(() => {
    async function resendConfirmationCode(username) {
      alert('We will send a new confirmation code to your email.');
      try {
        await Auth.signOut();
        userHasAuthenticated(false);
        await Auth.resendSignUp(username);
        alert('Code successfully sent.');
        setNewUser(location.state.confirmEmail);
        setRedirected(true);
      } catch (e) {
        onError(e);
        history.push("/");
      }
    }
    if (isAuthenticated) {
      history.push("/account");
    }
    if (location.state && location.state.confirmEmail) {
      resendConfirmationCode(location.state.confirmEmail);
    }
  }, [history, location, isAuthenticated, userHasAuthenticated]);
  function validateForm() {
    return (
      fields.name.length > 0 &&
      fields.email.length > 0 &&
      fields.password.length > 0 &&
      fields.password === fields.confirmPassword
    );
  }

  function validateConfirmationForm() {
    return fields.confirmationCode.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    try {
      const customerCall = await API.post("customer", "customer", {
        body: {
          method: 'create',
          name: fields.name,
          email: fields.email
        }
      });
      if (customerCall.status) {
        const newUser = await Auth.signUp({
          username: fields.email,
          password: fields.password,
          attributes: {
            'custom:custid': customerCall.custid 
          }
        });
        setNewUser(newUser);
      } else {
        alert('There was an error. Please contact support or try later.');
      }
    } catch (e) {
      console.log('Error: ', e)
      if (e.code && e.code === "UsernameExistsException") {
        try {
          await Auth.resendSignUp(fields.email);
          alert('This username is already registered. A new confirmation code has been sent.')
          setNewUser('temp');
        } catch (e) {
          onError(e);
        }
      } else {
        onError(e);
      }
    }
    setIsLoading(false);
  }

  async function handleConfirmationSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    if (redirected) {
      try {
        await Auth.confirmSignUp(newUser, fields.confirmationCode);
        history.push("/login");
      } catch (e) {
        onError(e);
        setIsLoading(false);
      }
    } else {
      try {
        await Auth.confirmSignUp(fields.email, fields.confirmationCode);
        await Auth.signIn(fields.email, fields.password);
        userHasAuthenticated(true);
        history.push("/");
      } catch (e) {
        onError(e);
        setIsLoading(false);
      }
    }
  }

  function renderConfirmationForm() {
    return (
      <Form onSubmit={handleConfirmationSubmit}>
        <Form.Group controlId="confirmationCode" size="lg">
          <Form.Label>Confirmation Code</Form.Label>
          <Form.Control
            autoFocus
            type="tel"
            onChange={handleFieldChange}
            value={fields.confirmationCode}
          />
          <Form.Text muted>Please check your email for the code.</Form.Text>
        </Form.Group>
        <LoaderButton
          block
          className="cuatro-primary"
          variant="outline-dark"
          disabled={!validateConfirmationForm()}
          type="submit"
          size="md"
          isLoading={isLoading}
          text={'Verify'}
          loadingText="Verifying.."
        />
      </Form>
    );
  }

  function renderForm() {
    return (
      <Form onSubmit={handleSubmit}>
        <h2>Sign Up</h2>
        <Form.Group controlId="name" size="lg">
          <Form.Label>Full Name</Form.Label>
          <Form.Control
            autoFocus
            type="name"
            value={fields.name}
            onChange={handleFieldChange}
          />
          <div className="feedback"></div>
        </Form.Group>
        <Form.Group controlId="email" size="lg">
          <Form.Label>Email</Form.Label>
          <Form.Control
            autoFocus
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
          />
          <div className="feedback"></div>
        </Form.Group>
        <Form.Group controlId="password" size="lg">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            value={fields.password}
            onChange={handleFieldChange}
          />
          <div className="feedback"></div>
        </Form.Group>
        <Form.Group controlId="confirmPassword" size="lg">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            type="password"
            onChange={handleFieldChange}
            value={fields.confirmPassword}
          />
          <div className="feedback">
            <div className="policy">
              <ul>
                <li>At least 6 characters</li>
                <li>Numbers required</li>
                <li>{"Special character required: ^ $ * . [ ] { } ( ) ? - \" ! @ # % & / , > < ' : ; | _ ~ `"}</li>
                <li>Uppercase letters required</li>
                <li>Lowercase letters required</li>
              </ul>
            </div>
          </div>
        </Form.Group>
        <LoaderButton
          block
          className="cuatro-primary"
          variant="outline-dark"
          disabled={!validateForm()}
          type="submit"
          size="md"
          isLoading={isLoading}
          text={'Create Account'}
          loadingText="Signing up.."
        />
      </Form>
    );
  }

  return (
    <div className="Signup main">
      <CheckAppVersion/>
      <Container>
        <Row>
          <Col>
          {newUser === null ? renderForm() : renderConfirmationForm()}
          </Col>
        </Row>
      </Container>
    </div>
  );
}