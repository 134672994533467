import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAppContext } from "../lib/contextLib";
import "./Account.css";
import { API } from "aws-amplify";
import { Container, Row, Col, Button } from "react-bootstrap";
import { RiAccountPinCircleLine, RiMastercardLine, RiVisaLine } from 'react-icons/ri';
import { GrAmex } from 'react-icons/gr';
import { FaCcDinersClub, FaRegCreditCard, FaSpinner } from 'react-icons/fa';
import { BsCreditCard2Front, BsExclamationCircle, BsCheck2Circle } from 'react-icons/bs';
import { SiDiscover, SiJcb } from 'react-icons/si';
import { FiChevronRight, FiCreditCard } from 'react-icons/fi';
import Breadcrums from "../components/Breadcrums";
import CheckAppVersion from "../components/CheckAppVersion";
import { xFormatDate } from "../components/XFunctions";

export default function Account() {
  const history = useHistory();
  const { isUserEmailVerified } = useAppContext();
  const [userName, setUserName] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [userPayMs, setUserPayMs] = useState([]);
  const [userOrders, setUserOrders] = useState(null);

  useEffect(() => {
    async function customerDetails() {
      const customerCall = await API.post("customer", "customer", {
        body: {
          method: 'retreive'
        }
      });
      if (customerCall.status) {
        let { name, email, payms, orders } = customerCall.details;
        setUserName(name);
        setUserEmail(email);
        const chunks = (a, size) =>
          Array.from(
              new Array(Math.ceil(a.length / size)),
              (_, i) => a.slice(i * size, i * size + size)
              );
        setUserPayMs(chunks(payms, 3));
        setUserOrders(orders);
      } else {
        alert('There was an error. Please contact support or try later.');
      }
    }
    customerDetails();
  }, []);

  async function customerDetails() {
    const customerCall = await API.post("customer", "customer", {
      body: {
        method: 'retreive'
      }
    });
    if (customerCall.status) {
      let { name, email, payms } = customerCall.details;
      setUserName(name);
      setUserEmail(email);
      const chunks = (a, size) =>
        Array.from(
            new Array(Math.ceil(a.length / size)),
            (_, i) => a.slice(i * size, i * size + size)
            );
      setUserPayMs(chunks(payms, 3));
    } else {
      alert('There was an error. Please contact support or try later.');
    }
  }

  function handleNameOnClick(e) {
    e.preventDefault();
    history.push("/account/name");
  }
  function handleEmailOnClick(e) {
    e.preventDefault();
    history.push("/account/email");
  }

  function handlePasswordOnClick(e) {
    e.preventDefault();
    history.push("/account/password");
  }

  function handleVerifyEmailOnClick(e) {
    e.preventDefault();
    history.push({pathname: "/signup", state: { confirmEmail: userEmail } })
  }

  function renderPaymentMethods() {
    const items = [];
    if (userPayMs && userPayMs.length === 0) {
      items.push(
        <Row className="paymrow" key={0}>
          <Col sm={4}>
            <div className="paymcard">
              <div className="primary"></div>
              <div className="info">
                <Row>
                  <Col xs={4}>
                    <div className="imgwrap"><FiCreditCard/></div>
                  </Col>
                  <Col xs={8}>
                    <div className="numexp">
                      <div className="last4">****0000</div>
                      <div className="expires">Expires 00/0000</div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="editdel">
                <Button size="sm" variant="link" onClick={handleAddCardOnClick}>+ Add card</Button>
              </div>
            </div>
          </Col>
        </Row>
      );
      return items;
    }
    for (var i = 0; i < userPayMs.length; i++) {
      let it = userPayMs[i];
      const cols = [];
      for (var j = 0; j < it.length; j++) {
        let cl = it[j];
        cols.push(
          <Col sm={4} key={j}>
            <div className="paymcard">
              <div className="primary"></div>
              <div className="info">
                <Row>
                  <Col xs={4}>
                    <div className="imgwrap">{renderCreditCard(cl.brand)}</div>
                  </Col>
                  <Col xs={8}>
                    <div className="numexp">
                      <div className="last4">{'****' + cl.last4}</div>
                      <div className="expires">{'Expires ' + cl.expmo + '/' + cl.expyr}</div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="editdel">
                {/*<Button size="sm" variant="link" onClick={e => handleAddCardOnClick(true)}>Edit</Button><span className="sep">|</span>*/}
                <Button size="sm" variant="link" onClick={e => handleDeleteCardOnClick(cl.id)}>Delete</Button>
              </div>
            </div>
          </Col>
        )
      }
      items.push(
        <Row key={i} className="paymrow">
          {cols}
        </Row>
      );
    }
    return items;
  }

  function renderCreditCard(brand) {
    let elem = null;
    switch (brand) {
      case 'amex':
        elem = <GrAmex/>
      break;
      case 'diners':
        elem = <FaCcDinersClub/>
      break;
      case 'discover':
        elem = <SiDiscover/>
      break;
      case 'jcb':
        elem = <SiJcb/>
      break;
      case 'mastercard':
        elem = <RiMastercardLine/>
      break;
      case 'unionpay':
        elem = <BsCreditCard2Front/>
      break;
      case 'visa':
        elem = <RiVisaLine/>
      break;
      default:
        elem = <FaRegCreditCard/>
    }
    return elem;
  }

  async function handleAddCardOnClick() {
    const cs = await customerPayIntent();
    history.push({pathname: "/account/addcard", state: { cs: cs } })
  }

  async function handleDeleteCardOnClick(paymid) {
    setUserName(null);
    setUserEmail(null);
    setUserPayMs([]);
    await customerDelCard(paymid);
    await customerDetails()
  }

  async function customerPayIntent() {
    const customerCall = await API.post("customer", "customer", {
      body: {
        method: 'savecard'
      }
    });
    if (customerCall.status) {
      let { cs } = customerCall;
      return cs;
    } else {
      alert('There was an error. Please contact support or try later.');
      return null;
    }
  }

  async function customerDelCard(paymid) {
    const customerCall = await API.post("customer", "customer", {
      body: {
        method: 'delcard',
        paymid: paymid
      }
    });
    if (customerCall.status) {
      let { pm } = customerCall;
      return pm;
    } else {
      alert('There was an error deleting the card. Please try again later.');
      return null;
    }
  }

  return (
    <div className="Account main">
      <CheckAppVersion/>
      <Breadcrums path={[{name: 'Account'}]} />
      <Container>
        <div className="profile">
          <Row>
            <Col sm={2}>
              {userName && userEmail ?
                <div className="pic"><RiAccountPinCircleLine /></div>
              :
                <div className="pic"><FaSpinner className="spin" /></div>
              }
            </Col>
            <Col sm={6}>
              {userName && userEmail &&
                <>
                  <h2 className="name">{userName}</h2>
                  <div className="email">{userEmail}<span></span></div>
                  <div className="verifyemail">
                    {!isUserEmailVerified ? 
                      <Button variant="outline-dark" className="cuatro-alert" onClick={handleVerifyEmailOnClick}>
                        <BsExclamationCircle className="inlineicon large" />Verify your email<FiChevronRight className="inlineicon" />
                      </Button>
                    : 
                      <div className="emailverified"><BsCheck2Circle /> Verified</div>
                    }
                  </div>
                </>
              }
              <div className="pt-3 pb-3 d-sm-block d-md-none"></div>
            </Col>
            <Col sm={4}>
              {userName && userEmail &&
                <>
                  <Button block variant="outline-dark" className="cuatro-back" onClick={handleNameOnClick}>Change name</Button>
                  <Button block variant="outline-dark" className="cuatro-back" onClick={handleEmailOnClick}>Change email</Button>
                  <Button block variant="outline-dark" className="cuatro-back" onClick={handlePasswordOnClick}>Change password</Button>
                </>
              }
            </Col>
          </Row>
        </div>
        {userName && userEmail &&
          <div>
            <div className="payms">
              <Row className="title">
                <Col>
                  <h4>Wallet</h4>
                </Col>
                <Col className="addcard">
                  <Button size="sm" variant="outline-dark" className="cuatro-primary" onClick={handleAddCardOnClick}>Add Card</Button>
                </Col>
              </Row>
              {renderPaymentMethods()}
            </div>
            {userOrders ?
              <div className="orders">
                <Row className="title">
                  <Col>
                    <h4>Orders</h4>
                  </Col>
                </Row>
                <Row className="list">
                  <Col>
                    {userOrders.map((order, oidx) => {
                      return(
                        <div className="order" key={oidx}>
                          <Row className="odets">
                            <Col>
                              <span className="date">{xFormatDate(order.odate, true)}</span>
                              <span className="sep"> | </span>
                              <span className="total">{order.total}</span>
                            </Col>
                            <Col>
                              <Row className="refdets">
                                <Col sm={7}>
                                  <span className="ref">#{order.ref}</span>
                                </Col>
                                <Col sm={5}>
                                  <a alt="" className="btn-link" href={order.receipt} target="_blank" rel="noopener noreferrer">Receipt<FiChevronRight /></a>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row>
                            {order.dets &&
                            <Col>
                              <div className="prodlist">
                                {order.dets.items.map((item, idx) => {
                                  return(
                                    <div key={idx}>
                                      <Row className="prod" >
                                        <Col sm={9}>
                                          <Row className="imgdeliver">
                                            <Col xs={4} className="image">
                                              <img alt="" src={item.image}/>
                                            </Col>
                                            <Col xs={8}>
                                              <div className="status">{order.dets.status}</div>
                                              <div className="deliverdate">{order.dets.sdate}</div>
                                            </Col>
                                          </Row>
                                        </Col>
                                        <Col sm={3} className="trackitem">
                                          {order.dets.tracklink &&
                                          <Button size="sm" variant="outline-dark" className="cuatro-back"><a alt="" href={order.dets.tracklink} target="_blank" rel="noopener noreferrer">Track</a></Button>
                                          }
                                        </Col>
                                      </Row>
                                      {idx < order.dets.items.length - 1 &&
                                        <hr/>
                                      }
                                    </div>
                                  )
                                })}
                              </div>
                            </Col>
                            }
                          </Row>
                        </div>
                      )
                    })}
                  </Col>
                </Row>
              </div>
            :
              <></>
            }
          </div>
        }
      </Container>
    </div>
  );
}