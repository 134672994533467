import { useEffect, useRef } from "react";
import { useAppContext } from "../lib/contextLib";

export default function CheckAppVersion() {
  const { getVersion } = useAppContext();
  var timer = useRef(null);
  const versionRef = useRef(null);
  
  useEffect(() => {
    async function executeOnTimer() {
      let onlineVersion = await getVersion();
      if (versionRef.current) {
        if (versionRef.current && (versionRef.current !== onlineVersion)) {
          window.location.reload(true);
        }
      } else {
        versionRef.current = onlineVersion;
      }
    }
    // Anything in here is fired on component mount.
    const onLoad = () => {
      executeOnTimer()
      timer.current = setInterval(() => { executeOnTimer() }, 30 * 1000);
    }
    const onUnload = () => {
      clearInterval(timer.current);
    }
    onLoad();
    return () => {
      // Anything in here is fired on component unmount.
      onUnload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (null);
}