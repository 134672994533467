import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { FormGroup, FormControl, FormLabel, Container, Row, Col, Button, InputGroup } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../lib/hooksLib";
import { onError } from "../lib/errorLib";
import Breadcrums from "../components/Breadcrums";
import CheckAppVersion from "../components/CheckAppVersion";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import "./ChangePassword.css";

export default function ChangePassword() {
  const history = useHistory();
  const [fields, handleFieldChange] = useFormFields({
    password: "",
    oldPassword: "",
    confirmPassword: "",
  });
  const [isChanging, setIsChanging] = useState(false);
  const [passShow, setPassShow] = useState(false);

  function validateForm() {
    return (
      fields.oldPassword.length > 0 &&
      fields.password.length > 0 &&
      fields.password === fields.confirmPassword
    );
  }

  async function handleChangeClick(event) {
    event.preventDefault();

    setIsChanging(true);

    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(
        currentUser,
        fields.oldPassword,
        fields.password
      );

      history.push("/account");
    } catch (error) {
      onError(error);
      setIsChanging(false);
    }
  }

  function handlePassShowClick(event) {
    event.preventDefault();
    setPassShow(!passShow);
  }

  return (
    <div className="ChangePassword main">
      <CheckAppVersion/>
      <Breadcrums path={[{name: 'Account', to: '/Account'}, {name: 'Change Password'}]} />
      <Container>
        <Row>
          <Col sm={2}></Col>
          <Col sm={8}>
            <div className="narrow">
              <h3>Change Password</h3>
              <form onSubmit={handleChangeClick}>
                <FormGroup controlId="oldPassword">
                  <FormLabel>Old Password</FormLabel>
                  <InputGroup>
                    <FormControl
                      type={passShow ? "text" : "password"}
                      onChange={handleFieldChange}
                      value={fields.oldPassword}
                    />
                    <Button variant="outline-dark" className="cuatro-back" onClick={handlePassShowClick} tabindex="-1">
                      {passShow ? <BsEyeSlash/> : <BsEye/>}
                    </Button>
                  </InputGroup>
                  <div className="feedback error"></div>
                </FormGroup>
                <hr />
                <FormGroup controlId="password">
                  <FormLabel>New Password</FormLabel>
                  <InputGroup>
                    <FormControl
                      type={passShow ? "text" : "password"}
                      onChange={handleFieldChange}
                      value={fields.password}
                    />
                    <Button variant="outline-dark" className="cuatro-back" onClick={handlePassShowClick} tabindex="-1">
                      {passShow ? <BsEyeSlash/> : <BsEye/>}
                    </Button>
                  </InputGroup>
                  <div className="feedback error"></div>
                </FormGroup>
                <FormGroup controlId="confirmPassword">
                  <FormLabel>Confirm Password</FormLabel>
                  <InputGroup>
                    <FormControl
                      type={passShow ? "text" : "password"}
                      onChange={handleFieldChange}
                      value={fields.confirmPassword}
                    />
                    <Button variant="outline-dark" className="cuatro-back" onClick={handlePassShowClick} tabindex="-1">
                      {passShow ? <BsEyeSlash/> : <BsEye/>}
                    </Button>
                  </InputGroup>
                  <div className="feedback">
                    <div className="policy">
                      <ul>
                        <li>At least 6 characters</li>
                        <li>Numbers required</li>
                        <li>{"Special character required: ^ $ * . [ ] { } ( ) ? - \" ! @ # % & / , > < ' : ; | _ ~ `"}</li>
                        <li>Uppercase letters required</li>
                        <li>Lowercase letters required</li>
                      </ul>
                    </div>
                  </div>
                </FormGroup>
                <LoaderButton
                  block
                  className="cuatro-primary"
                  variant="outline-dark"
                  disabled={!validateForm()}
                  type="submit"
                  size="md"
                  isLoading={isChanging}
                  text={'Change Password'}
                  loadingText="Changing.."
                />
              </form>
            </div>
          </Col>
          <Col sm={2}></Col>
        </Row>
      </Container>
    </div>
  );
}