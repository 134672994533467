import React, { useState, useEffect, useRef } from "react";
import './BuyTheLookModal.css'
import { webMedia } from "./Constants";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GrNext, GrPrevious, GrClose } from "react-icons/gr";
import { useAppContext } from "../lib/contextLib";
import { Link } from "react-router-dom";
import { xFormatMoney } from './XFunctions';
import LoaderButton from "../components/LoaderButton";
import separator from '../images/curvesseparator.png';
import { BsDot } from 'react-icons/bs';
import imgplh1kx1k from '../images/1kx1kg.png';

export default function BuyTheLookModal({ show, selected, setShow, isAddingToCart, handleAddToXOnClick, changeSelIdx, carouselLength }) {
  const { imageHash } = useAppContext();
  const [prods, setProds] = useState(null);
  const prevSelected = useRef(null);

  function processProds() {
    let pProds = selected.item.prods;
    if (pProds) {
      pProds = pProds.reduce(function(result, value, index, array) {
        if (index % 2 === 0)
          result.push(array.slice(index, index + 2));
        return result;
      }, []);
      setProds(pProds);
    }
  }

  useEffect(() => {
    function processProds() {
      let pProds = selected.item.prods;
      if (pProds) {
        pProds = pProds.reduce(function(result, value, index, array) {
          if (index % 2 === 0)
            result.push(array.slice(index, index + 2));
          return result;
        }, []);
        setProds(pProds);
      }
    }
    selected.item.prods = selected.item.prods.map(a => ({ ...a, imageloading: true }));
    processProds();
    return () => {}
  },[]);

  useEffect(() => {
    function processProds() {
      let pProds = selected.item.prods;
      if (pProds) {
        pProds = pProds.reduce(function(result, value, index, array) {
          if (index % 2 === 0)
            result.push(array.slice(index, index + 2));
          return result;
        }, []);
        setProds(pProds);
      }
    }
    if (prevSelected.current && (prevSelected.current.index !== selected.index)) {
      selected.item.prods = selected.item.prods.map(a => ({ ...a, imageloading: true }));
      processProds();
    }
    prevSelected.current = selected;
  },[selected]);

  function handleClose() {
    setShow(-1);
  }
  function handleStep(dir) {
    let newIdx = null;
    if (dir === 'next') {
      newIdx = selected.index + 1;
    } else {
      newIdx = selected.index - 1;
    }
    changeSelIdx(newIdx);
  }

  function imageLoaded(sku) {
    selected.item.prods.find(x => String(x.sku) === String(sku)).imageloading = false;
    processProds();
  }

  const { item, index } = selected;
  const nextDisabled = (index + 1 === carouselLength);
  const prevDisabled = (index === 0);

  return (
    <Modal className="BuyTheLookModalModal" show={show} onHide={handleClose} animation={false} size={'xl'} dialogClassName="BuyTheLookModal" >
    {selected &&
      <Modal.Body>
        <Row className="mediainfowrap">
          <Col md={8} className="media">
            <img className="mainimg" src={ webMedia + '/1000x1000/' + item.img + '?' + imageHash } alt="" />
          </Col>
          <Col md={4} className="info">
            <div className="navtool">
              <Button className={"prev" + (prevDisabled ? " disabled" : '')} disabled={prevDisabled} onClick={e => handleStep('prev')}><GrPrevious /></Button>
              <Button className={"next" + (nextDisabled ? " disabled" : '')} disabled={nextDisabled} onClick={e => handleStep('next')}><GrNext /></Button>
              <Button className="close" onClick={handleClose}><GrClose /></Button>
            </div>
            <div className="scrollwrap">
              {prods &&
                <Row className="prods">
                  <Col>
                  {prods.map((pp, ppidx) => {
                    return(
                      <Row key={ppidx} className={"l-" + String(ppidx + 1)}>
                        {pp.map((p, pidx) => {
                          let { sku, url, nameen, images, price, imageloading } = p;
                          let imagesrc = webMedia + '/255x255/' + images[0] + '?' + imageHash;
                          return(
                            <Col key={pidx} xs={6}>
                              <Link to={url + "?variant=" + sku}>
                                <div style={{ display: imageloading ? "block" : "none", position: "relative" }}>
                                  <div className="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                  <img key={pidx} src={imgplh1kx1k} rel="preload" alt=""/>
                                </div>
                                <img key={pidx} src={imagesrc} alt="" onLoad={e => imageLoaded(sku)}  style={{display: imageloading ? "none" : "block"}} />
                                <div className="name"><span className="price mr-2">{'$' + xFormatMoney(parseFloat(price))}</span>{nameen}</div>
                              </Link>
                              <div className="addtobag">
                                <LoaderButton
                                  block
                                  className=""
                                  variant="outline-dark"
                                  disabled={!true}
                                  type="button"
                                  size="md"
                                  isLoading={isAddingToCart}
                                  text={'Add to Bag'}
                                  loadingText="Adding.."
                                  onClick={(e) => handleAddToXOnClick(sku, nameen, images[0])}
                                />
                              </div>
                            </Col>
                          );
                        })}
                      </Row>
                    );
                  })}
                  </Col>
                </Row>
              }
              <div className="separator">
                <img alt="" src={separator}/>
              </div>
              {item.copy &&
                <Row className="copy">
                  <Col>
                    <p>{item.copy}</p>
                  </Col>
                </Row>
              }
              <Row className="meta">
                <Col>
                  <a href={item.url} target="_blank" rel="noopener noreferrer" >
                    <span className="platform">Instagram</span><BsDot /><span className="date">{item.date}</span>
                  </a>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    }
    </Modal>
  );
}