import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { MdChevronRight } from 'react-icons/md';
import "./Breadcrums.css";

export default function Breadcrums({ path }) {

  function renderPathCrums() {
    let crums = [];
    for (var i = 0; i < path.length - 1; i++) {
      let crum = path[i];
      crums.push(<span key={i}><Link to={crum.to} className="crum">{crum.name}</Link><MdChevronRight/></span>)
    }
    return (
      crums
    );
  }

  return (
    <Container>
      <Row>
        <Col>
          <div className="Breadcrums">
            <Link to="/" className="crum first">Home</Link><MdChevronRight/>{renderPathCrums()}<span className="crum">{path[path.length - 1].name}</span>
          </div>
        </Col>
      </Row>
    </Container>
  );
}