import React, { useState, useEffect } from "react";
import "./Checkout.css";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import CheckAppVersion from "../components/CheckAppVersion";
import Breadcrums from "../components/Breadcrums";
import { useAppContext } from "../lib/contextLib";
import { useHistory, Link } from "react-router-dom";
import { API } from "aws-amplify";
import { xFormatMoney } from '../components/XFunctions';
import { FaSpinner } from "react-icons/fa";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import config from "../config";
import BillingForm from "../components/BillingForm";
import cardicon from '../images/card-icon.png';
import facebookicon from '../images/facebook-icon.png';
import instagramicon from '../images/instagram-icon.png';
import { webSource } from "../components/Constants";

const stripePromise = loadStripe(config.billing.STRIPE_KEY);
const fonts = [
  { 
    cssSrc: "https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap",
    family: "Jost",
    style: 'normal' 
  }
]
const appearance = {
  theme: 'flat',
  variables: {
    fontFamily: 'Jost',
    fontSizeBase: '18px',
    borderRadius: '0',
    colorBackground: 'white',
    colorPrimary: '#495057',
    colorPrimaryText: 'white',
  },
  rules: {
    '.Label': {
      marginBottom: '10px'
    },
    '.Tab, .Input, .Block': {
      border: '1px solid #ced4da',
      boxShadow: 'none',
      padding: '6px 10px'
    },
    '.Input': {
      fontSize: '16px'
    }
  }
};

export default function Checkout() {
  const { imageHash, updateCartTotal, placeAddress, isAuthenticated } = useAppContext();
  const [cart, setCart] = useState(null);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [shortSummary, setShortSummary] = useState(true);
  const [updatingFees, setUpdatingFees] = useState(true);
  const [selectedShipOpt, setSelectedShipOpt] = useState(null);
  const [shippingOpts, setShippingOpts] = useState([]);
  const [userName, setUserName] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [userPayMs, setUserPayMs] = useState(null);
  const [cartTotal, setCartTotal] = useState(0.0);
  
  useEffect( () => {
    async function doIt() {
      setIsLoading(true);
      try {
        const customerCall = await API.post("customer", "customer", {
          body: {
            method: 'retreive'
          }
        });
        if (customerCall.status) {
          let { name, email, payms } = customerCall.details;
          setUserName(name);
          setUserEmail(email);
          const chunks = (a, size) =>
            Array.from(
                new Array(Math.ceil(a.length / size)),
                (_, i) => a.slice(i * size, i * size + size)
                );
          setUserPayMs(chunks(payms, 3));
        }
      } catch(e) {

      }
      try {
        // await Auth.currentCredentials()
        const apiReturn = await API.post('carts', 'updatecart', {
          body: { state: 'NONE' }
        }).then(response => response).catch(error => error)
        if (apiReturn && apiReturn.status && !apiReturn.nocart) {
          let pcart = apiReturn.cart;
          if (pcart.cartItems && pcart.cartItems.length > 0) {
            setCart(pcart);
          } else {
            history.push('/cart');
          }
        } else {
          history.push('/cart');
        }
        setIsLoading(false)
      } catch(e) {
        setIsLoading(false);
        console.log('Error. Please contact Geedop.', e);
      }
    }
    doIt();
  }, []);

  async function handleUpdateSummary(state = null, selshipopt = null, promoCode=null, removePromoCode=false) {
    setUpdatingFees(true);
    let shipServiceType = null;
    if (selshipopt) {
      shipServiceType = selshipopt.serviceType;
    } else if (selectedShipOpt) {
      shipServiceType = selectedShipOpt.serviceType;
    }
    try {
      let body = { state, placeAddress, shipServiceType };
      if (promoCode) {
        body.promoCode = promoCode;
      }
      if (removePromoCode) {
        body.removePromoCode = true;
      }
      let updateCart = await API.post('carts', 'updatecart', {
        body
      }).then(response => response).catch(error => error)
      let pshippingOpts = updateCart.shippingOpts;
      let pselectedShipOpt = selshipopt ? selshipopt : updateCart.selectedShipOpt;
      if (pshippingOpts.length <= 0 || (pshippingOpts.length === 1 && pshippingOpts[0].serviceType === 'FREE_STANDARD')) {
        alert('There was a problem with the request. Please try again in a moment.')
        history.push('/cart');
        return;
      }
      let pcart = updateCart.cart;
      let promoDiscount = 0;
      if (pcart && pcart.promoDiscount) {
        promoDiscount = pcart.promoDiscount;
      }
      let cartT = (pcart ? '$ ' + xFormatMoney(pcart.subtotal - promoDiscount + pcart.tax + pcart.shipping) : '');
      setCartTotal(cartT);
      updateCartTotal(cartT);
      if (updateCart.invalidPromoCode) {
        alert('Code not recognized.');
        pcart.promoCode = null;
      }
      setCart(pcart);
      setShippingOpts(pshippingOpts);
      setSelectedShipOpt(pselectedShipOpt);
    } catch {
      setIsLoading(false);
      console.log('Error. Please contact Geedop.')
    }
    setUpdatingFees(false);
  }

  function handleShipOptOnClick(serviceType, state) {
    let pselectedShipOpt = shippingOpts.find((el) => {
      return el.serviceType === serviceType
    })
    setSelectedShipOpt(pselectedShipOpt);
    handleUpdateSummary(state, pselectedShipOpt);
  }

  function handleUpdatingFeesModalOnHide() {
    return;
  }

  async function handleFormSubmit(formData, { token, error }) {
    formData.auxOrigin = window.location.origin
    formData.auxLogo = webSource + 'branding/logoemail.png'
    formData.auxCardIcon = cardicon
    formData.auxFacebookIcon = facebookicon
    formData.auxInstagramIcon = instagramicon
    formData.shipOpt = selectedShipOpt
    if (error) {
      alert(error.message);
      return;
    }
    let source = token;
    if (token.id) {
      source = token.id;
    }
    setIsLoading(true);
    try {
      let apiReturn = await billUser({
        source: source,
        cartId: cart.cartId,
        formData
      });
      history.push({
        pathname: '/order-confirmation',
        state: {
          data: apiReturn
        }
      })
    } catch (e) {
      alert(e);
      setIsLoading(false);
      history.push('/cart');
    }
  }

  async function billUser(details) {
    return API.post('billing', 'billing', {
      body: details
    });
  }

  function renderCartItems() {
    cart.cartItems.sort((a,b) => (a.lineid > b.lineid) ? 1 : ((b.lineid > a.lineid) ? -1 : 0))
    let cartItems = [];
    cartItems = cart.cartItems.map((item, idx) => {
      if (shortSummary && idx >= 2) {
        return null
      } else {
        return (
        <li key={idx + 1}>
          <Row>
            <Col xs={3}>
              <img alt="" className="preview" src={item.image + '?' + imageHash} />
            </Col>
            <Col xs={6}>
              <div className="title">{item.title}</div>
              <div className="price-x-qty">{'$' + xFormatMoney(item.price) + ' x ' + item.quantity}</div>
            </Col>
            <Col xs={3}>
              <div className="line-total">{'$' + xFormatMoney(parseInt(item.quantity) * parseFloat(item.price))}</div>
            </Col>
          </Row>
        </li>
        )
      }
    })
    return cartItems;
  }
  return (
    <div className="Checkout main">
      <CheckAppVersion/>
      <Breadcrums path={[{ name: 'Checkout' }]} />
      <Container className="main">
        <h2 className="center">Secure Checkout</h2>
        {isLoading ?
          <Row>
            <div className="loading"><FaSpinner className="spin" /></div>
          </Row>
        :
          <Row>
            <Col md={4} className="order-summary">
              <div className="stickywrap">
                <Row className="mt-4">
                  <Col>
                    <h5 className="order-summary-title">Order Summary</h5>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ul className="product-list">
                      {cart && renderCartItems()}
                    </ul>
                    {(shortSummary && cart && cart.cartItems.length > 2) &&
                      <div className="show-more"><Button variant="link" onClick={() => setShortSummary(false)}>{cart.quantityTotal} items total <FiChevronDown/></Button></div>
                    }
                    {(!shortSummary && cart && cart.cartItems.length > 2) &&
                      <div className="show-more"><Button variant="link" onClick={() => setShortSummary(true)}>fold <FiChevronUp/></Button></div>
                    }
                    <hr className="subtotal-division" />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="subtotal">Subtotal:<span className="float-right">{(cart ? '$ ' + xFormatMoney(cart.subtotal) : '0.00')}</span></p>
                    {(cart && cart.promoDiscount && cart.promoDiscount !== 0) ?
                      <p className="promoCode">Discount:<span className="float-right">{('$ -' + xFormatMoney(cart.promoDiscount) : '')}</span></p>
                    :
                      <></>
                    }
                    <p className="shipping">Delivery:<span className="float-right">{(cart ? '$ ' + xFormatMoney(cart.shipping) : '0.00')}</span></p>
                    <p className="tax">Tax:<span className="float-right">{(cart ? '$ ' + xFormatMoney(cart.tax) : '0.00')}</span></p>
                    <p className="total">Total USD:<span className="float-right amount">{cartTotal}</span></p>
                    <Link to="/cart" className="edit-cart"><Button variant="link">Edit order</Button></Link>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={8}>
              <Elements
                stripe={stripePromise}
                options={{appearance, fonts}}
              >
                <BillingForm
                  loading={isLoading}
                  placeAddress={placeAddress}
                  updateSummary={handleUpdateSummary}
                  shippingOpts={shippingOpts}
                  selectedShipOpt={selectedShipOpt}
                  handleShipOptOnClick={handleShipOptOnClick}
                  userName={userName}
                  userEmail={userEmail}
                  userPayMs={userPayMs}
                  onSubmit={handleFormSubmit}
                  isAuthenticated={isAuthenticated}
                  promoCodeApplied={cart && cart.promoCode ? cart.promoCode : null}
                />
              </Elements>
            </Col>
          </Row>
        }
        {updatingFees &&
          <Modal
            backdrop="static"
            className="UpdatingFees"
            show={updatingFees}
            size="md"
            centered
            onHide={handleUpdatingFeesModalOnHide}
          >
            <Modal.Body>
              <span className="text-center">Calculating summary...</span>
            </Modal.Body>
          </Modal>
        }
      </Container>
    </div>
  );
}