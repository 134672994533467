import React, { useState, useEffect } from "react";
import "./WellnessShow.css";
import CheckAppVersion from "../components/CheckAppVersion";
import Breadcrums from "../components/Breadcrums";
import { Container, Row, Col } from "react-bootstrap";
import ReactPlayer from 'react-player';
import { webMedia } from "../components/Constants";
import { useAppContext } from "../lib/contextLib";
import { FaRegCircle, FaCheckCircle } from 'react-icons/fa';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { BsPersonSquare } from 'react-icons/bs';
import { MdOutlineVideoLibrary } from 'react-icons/md';
import { MdClose, MdSkipNext, MdSkipPrevious, MdPlayArrow, MdOutlinePause } from 'react-icons/md';

const process = {
  pid:          "cwp0001",     // Process id
  date:         "2022-04-30", // YYYY-MM-DD
  origlan:      "es",         // ISO 639-1 codes
  title:        "Qunoa Salad with Fresh Herbs and Seasonal Fruit",
  titleo:       "Ensalada de Quinua con Cilantro, Hierbabuena y Fruta de la Estación",
  collabid:     "CWA-d8ff8e", // Autogenerated
  collabname:   "Zamira Cantú",
  timeprepmin:  16,           // min = minutes
  timecookmin:  9,            // min = minutes
  loccoord:     [ 20.98144464332492, -89.62363191433903 ], // lat, lon
  locname:      'Merida, Yucatán, México', // City, State/Region, Country
  season:       5,            // 1 = spring, 2 = summer, 3 = fall, 4 = winter, 5 = any
  elevationm:   10,           // m = meters
  humiditypct:  null,         // pct = percent
  tempoutdoorc: null,         // c = celcius
  tempindoorc:  null,         // c = celcius
  ingredients: [
    { name: "Raw quinoa",             nameo: "Quinoa cruda",          unit: "cup",  qty: "1/2",   img: 'raw-quinoa.png'           },
    { name: "Chopped mango",          nameo: "Mango picado",          unit: "cup",  qty: "1/2",   img: 'chopped-mango.png'        },
    { name: "Chopped red onion",      nameo: "Cebolla morada picada", unit: "cup",  qty: "1/4",   img: 'chopped-red-onion.png'    },
    { name: "Chopped pear",           nameo: "Pera picada",           unit: "cup",  qty: "1/4",   img: 'chopped-pear.png'         },
    { name: "Chopped green apple",    nameo: "Manzana verde picada",  unit: "cup",  qty: "1/4",   img: 'chopped-green-apple.png'  },
    { name: "Chopped cilantro",       nameo: "Cilantro picado",       unit: "tbsp", qty: "1",     img: 'chopped-cilantro.png'     },
    { name: "Chopped peppermint",     nameo: "Hierbabuena pidada",    unit: "tbsp", qty: "1",     img: 'chopped-peppermint.png'   },
    { name: "Crushed chipotle",       nameo: "Chipotle triturado",    unit: "tbsp", qty: "1",     img: 'crushed-chipotle.png'     },
    { name: "Nutritional yeast",      nameo: "Levadura nutricional",  unit: "tbsp", qty: "1",     img: 'nutritional-yeast.png'    },
    { name: "Lemon juice",            nameo: "Jugo de limón",         unit: "tbsp", qty: "1-1/2", img: 'lemon-juice.png'          },
    { name: "Olive oil",              nameo: "Aceite de oliva",       unit: "tbsp", qty: "3",     img: 'olive-oil.png'            },
    { name: "Grounded black pepper",  nameo: "Pimienta molida",       unit: "tbsp", qty: "1/2",   img: 'grounded-black-pepper.png'},
    { name: "Salt",                   nameo: "Sal",                   unit: "tbsp", qty: "1/2",   img: 'salt.png'                 }
  ],
  tools: [
    { name: "Knife",                nameo: "Cuchillo",                img: "knife.png"                },
    { name: "Cutting board",        nameo: "Tabla de picar",          img: "cutting-board.png"        },
    { name: "Strainer",             nameo: "Colador",                 img: "strainer.png"             },
    { name: "Immersion blender",    nameo: "Licuadora de inmersion",  img: "immersion-blender.png"    },
    { name: "Glass bowl",           nameo: "Bol de vidrio",           img: "glass-bowl.png"                 },
    { name: "Spoon",                nameo: "Cuchara",                 img: "spoon.png"                },
    { name: "Measuring cups",       nameo: "Tazas medidoras",         img: "measuring-cups.png"       },
    { name: "Wooden spatula",       nameo: "Espátula de madera",      img: "wooden-spatula.png"       },
    { name: "Whisk",                nameo: "Batidor",                 img: "whisk.png"                },
    { name: "Glass remekins",       nameo: "Ramequines de vidrio",    img: "glass-remekins.png"       },
    { name: "Stainless steel mug",  nameo: "Pocillo",                 img: "stainless-steel-mug.png"  }
  ],
  process: {
    clips: [
      { 
        id: '01',
        title: 'I toast the quinoa',
        titleo: 'Tuesto la quinoa',
        video: 'https://d1gdfy8gb2r35v.cloudfront.net/c35ac844-91a9-4a7b-a7aa-3dcc9b42c460/hls/cwp0001-01-toast-quinoa.m3u8',
        cover: '01-toast-quinoa.png'
      },
      {
        id: '02',
        title: 'I dice the red onion',
        titleo: 'Pico la cebolla',
        video: 'https://d1gdfy8gb2r35v.cloudfront.net/539c996a-45f4-4b66-8b65-fbd71cea934f/hls/cwp0001-02-dice-red-onion.m3u8',
        cover: '02-dice-red-onion.png'
      },
      {
        id: '03',
        title: 'I dice the tomato',
        titleo: 'Corto el tomate',
        video: 'https://d1gdfy8gb2r35v.cloudfront.net/9d1ab905-f13a-4847-a653-cdb196cb0346/hls/cwp0001-03-dice-tomato.m3u8',
        cover: '03-dice-tomato.png'
      },
      {
        id: '04',
        title: 'I dice the apple and pear',
        titleo: 'Corto la manzana y pera',
        video: 'https://d1gdfy8gb2r35v.cloudfront.net/ced34f7c-ed6f-464d-9e87-479caab6057e/hls/cwp0001-04-dice-apple-and-pear.m3u8',
        cover: '04-dice-apple-and-pear.png'
      },
      {
        id: '05',
        title: 'I boil, strain and cool the quinoa',
        titleo: 'Escurro y reposo la quinoa',
        video: 'https://d1gdfy8gb2r35v.cloudfront.net/f6f02a9f-7771-479d-bbc6-32bad8dbcfc6/hls/cwp0001-05-boil-strain-and-cool-quinoa.m3u8',
        cover: '05-boil-strain-and-cool-quinoa.png'
      },
      {
        id: '06',
        title: 'I dice the mango',
        titleo: 'Corto el mango en brunoise',
        video: 'https://d1gdfy8gb2r35v.cloudfront.net/4651d8fa-75f3-4e94-8fdc-4413efc6ac05/hls/cwp0001-06-dice-mango.m3u8',
        cover: '06-dice-mango.png'
      },
      {
        id: '07',
        title: 'I blend the mango and add olive oil, pepper, salt and chipotle',
        titleo: 'Hago la emulsión a base de mango, chipotle triturado, levadura nutricional, limón, aceite de oliva, pimienta y sal',
        video: 'https://d1gdfy8gb2r35v.cloudfront.net/92ed0de0-72c3-41f5-86f4-a10894468926/hls/cwp0001-07-blend-mango-add-olive-oil-pepper-salt-chipotle.m3u8',
        cover: '07-blend-mango-add-olive-oil-pepper-salt-chipotle.png'
      },
      {
        id: '08',
        title: 'I chop the cilantro',
        titleo: 'Corto el cilantro y la hierbabuena',
        video: 'https://d1gdfy8gb2r35v.cloudfront.net/03119d0d-ab46-42fe-8979-840630f30329/hls/cwp0001-08-chop-cilantro.m3u8',
        cover: '08-chop-cilantro.png'
      },
      {
        id: '09',
        title: 'I mix everything together',
        titleo: 'Mezclo los ingredientes',
        video: 'https://d1gdfy8gb2r35v.cloudfront.net/b2b8d9c7-a219-4820-ba5a-a58b7b871376/hls/cwp0001-09-mix-together.m3u8',
        cover: '09-mix-together.png'
      },
      {
        id: '10',
        title: 'I enjoy',
        titleo: 'Disfruto',
        video: 'https://d1gdfy8gb2r35v.cloudfront.net/a22d1d63-7c1e-4e92-bb8e-8f5b72a7e276/hls/cwp0001-10-enjoy.m3u8',
        cover: '10-enjoy.png'
      }
    ]
  },
  comment: {
    video:  "https://d1gdfy8gb2r35v.cloudfront.net/8554e9de-3a72-4677-aa86-020825bed6fe/hls/Cuatro Wellness Test0001 B.m3u8",
    cover: "" // image cover
  },
  steps: [
    {
      val:  "Toast the quinoa", 
      valo: "Tuesto la quinoa" 
    },
    {
      val:  "Cook the quona 9 min in 2 parts of water", 
      valo: "Cocino la quinoa 9 min en 2 partes de agua"
    },
    { 
      val:  "Cut the mango in brunoise",
      valo: "Corto el mango en brunoise"
    },
    {
      val:  "Cut the red onion in brunoise",
      valo: "Corto la cebolla morada en brunoise"
    },
    {
      val:  "Drain and rest the quinoa",
      valo: "Escurro y reposo la quinoa"
    },
    {
      val:  "Cut the tomato into brunoise",
      valo: "Corto el tomate en brunoise"
    },
    {
      val:  "I make the emulsion based on mango, crushed chipotle, nutritional yeast, lemon, olive oil, pepper and salt",
      valo: "Hago la emulsión a base de mango, chipotle triturado, levadura nutricional, limón, aceite de oliva, pimienta y sal"
    },
    {
      val:  "Cut the pear and apple into brunoise",
      valo: "Corto la pera y la manzana en brunoise"
    },
    {
      val:  "Cut coriander and mint in chiffonade",
      valo: "Corto el cilantro y la hierbabuena en chiffonade"
    },
    {
      val:  "Mix the ingredients, finish seasoning and enjoy",
      valo: "Mezclo los ingredientes y disfruto"
    }
  ]
}

export default function WellnessShow() {
  const { imageHash } = useAppContext();
  const [listSelected, setListSelected] = useState('ingreds');
  const [formatedQties, setFormatedQties] = useState([]);
  const [readyIngreds, setReadyIngreds] = useState([]);
  const [readyIngredsCt, setReadyIngredsCt] = useState(0);
  const [readyTools, setReadyTools] = useState([]);
  const [readyToolsCt, setReadyToolsCt] = useState(0);
  const [vidModeSelected, setVidModeSelected] = useState('steps');
  const [clipSelected, setClipSelected] = useState(null);
  const [clipPlaying, setClipPlaying] = useState(false);
  
  useEffect(() => {
    function doIt() {
      let newQties = [];
      for (var i = 0; i < process.ingredients.length; i++) {
        let converted = convertToFraction(process.ingredients[i].qty);
        newQties.push(converted);
      }
      setFormatedQties(newQties);
    }
    doIt();
  }, []);
  useEffect(() => { 
    if (clipSelected) { 
      document.body.style.overflow = 'hidden'; 
    } else { 
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    }
  }, [clipSelected]); 

  function convertToFraction(num) {
    let nump = num.split('-');
    let res = null;
    if (nump.length === 1) {
      let formfr = <div className="whole">{nump[0]}</div>;
      if (nump[0].includes('/')) {
        let frspl = nump[0].split('/');
        formfr = <div className="frac"><sup>{frspl[0]}</sup><span className="symb">/</span><sub>{frspl[1]}</sub></div>;
      }
      res = formfr;
    } else {
      let parta = <div className="whole">{nump[0]}</div>;
      let partb = <div className="whole">{nump[1]}</div>;
      if (nump[0].includes('/')) {
        let frspla = nump[0].split('/');
        parta = <div className="frac"><sup>{frspla[0]}</sup><span className="symb">/</span><sub>{frspla[1]}</sub></div>;
      }
      if (nump[1].includes('/')) {
        let frsplb = nump[1].split('/');
        partb = <div className="frac"><sup>{frsplb[0]}</sup><span className="symb">/</span><sub>{frsplb[1]}</sub></div>;
      }
      res = <div className="combo">{parta}{partb}</div>;
    }
    return res;
  }

  function handleIngredOnClick(ix) {
    let items = [...readyIngreds];
    if (items[ix]) {
      items[ix] = false;
    } else {
      items[ix] = true;
    }
    setReadyIngreds(items);
    let ct = 0;
    for (var i = 0; i < items.length; i++) {
      if (items[i]) {
        ct += 1;
      }
    }
    setReadyIngredsCt(ct);
  }
  function handleToolOnClick(tx) {
    let items = [...readyTools];
    if (items[tx]) {
      items[tx] = false;
    } else {
      items[tx] = true;
    }
    setReadyTools(items);
    let ct = 0;
    for (var i = 0; i < items.length; i++) {
      if (items[i]) {
        ct += 1;
      }
    }
    setReadyToolsCt(ct);
  }
  function handleSecTitleTabOnClick(id) {
    setListSelected(id);
  }
  function handleVidModeTabOnClick(id) {
    setVidModeSelected(id);
  }
  function handleClipThumbOnClick(url) {
    setClipSelected(url);
  }
  function handleClipCloseOnClick() {
    setClipSelected(null);
  }
  function handleClipPlayOnClick() {
    setClipPlaying(!clipPlaying);
  }
  function renderedListItems() {
    if (listSelected === 'ingreds') {
      return (
        process.ingredients.map((ing, ix) => {
          return(
            <div className="cardd" key={ix} onClick={e => handleIngredOnClick(ix)}>
              <div className="inner">
                <div>
                  <div className="top">
                    <span className="count">{ (ix + 1) }</span>
                    <span className={"ready" + (readyIngreds[ix] ? " check" : "")}>{ (readyIngreds[ix] ? <FaCheckCircle/> : <FaRegCircle/>) }</span>
                  </div>
                </div>
                <h3 className="name">{ing.name}</h3>
                <div className="item ingredient">
                  <div className="qtyunit">
                    <div className="qty">{(formatedQties && formatedQties[ix]) ? formatedQties[ix] : null}</div>
                    <div className="unit">{ing.unit}</div>
                  </div>
                  <div className="preview-wrap">
                    <div className="preview">
                      <img key={ix} src={ webMedia + '/256x256/' + process.pid + '-i-' + ing.img + '?' + imageHash } alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })
      );
    } else {
      return (
        process.tools.map((tol, tx) => {
          return(
            <div className="cardd" key={tx} onClick={e => handleToolOnClick(tx)}>
              <div className="inner">
                <div>
                  <div className="top">
                    <span className="count">{ (tx + 1) }</span>
                    <span className={"ready" + (readyTools[tx] ? " check" : "")}>{ (readyTools[tx] ? <FaCheckCircle/> : <FaRegCircle/>) }</span>
                  </div>
                </div>
                <h3 className="name">{tol.name}</h3>
                <div className="item tool">
                  <div className="qtyunit"></div>
                  <div className="preview-wrap">
                    <div className="preview">
                      <img key={tx} src={ webMedia + '/256x256/' + process.pid + '-e-' + tol.img + '?' + imageHash } alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })
      );
    }
  }

  function renderVideoGrid() {
    const group = (items, n) => items.reduce((acc, x, i) => {
      const idx = Math.floor(i / n);
      acc[idx] = [...(acc[idx] || []), x];
      return acc;
    }, []);
    const clink = (clip) => (
      <div className="cliplink" onClick={(e) => handleClipThumbOnClick(clip.video)}>
        <div className="preview">
          <img src={ webMedia + '/300x300/' + process.pid + '-' + clip.cover + '?' + imageHash } alt="" />
        </div>
        <div className="text">{clip.title}</div>
      </div>
    )
    return (
      group(process.process.clips, 6).map((c, cx) =>
        <Row key={cx}>
          <Col md={6}>
            <Row className="inner">
              <Col className="thumb">{ c[0] ? clink(c[0]) : null }</Col>
              <Col className="thumb">{ c[1] ? clink(c[1]) : null }</Col>
              <Col className="thumb">{ c[2] ? clink(c[2]) : null }</Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row className="inner">
              <Col className="thumb">{ c[3] ? clink(c[3]) : null }</Col>
              <Col className="thumb">{ c[4] ? clink(c[4]) : null }</Col>
              <Col className="thumb">{ c[5] ? clink(c[5]) : null }</Col>
            </Row>
          </Col>
        </Row>
      )
    );
  }

  return (
    <div className="WellnessShow main">
      <CheckAppVersion/>
      <Breadcrums path={[{ name: "Wellness", to: "/wellness" }, { name: 'Process' }]} />
      {clipSelected && 
        <div className="fsvidplay">
          <div className="scrim">
            <div className="topclose" onClick={handleClipCloseOnClick}>
              <MdClose className="icon" />
            </div>
            <div className="controls">
              <div className="play" onClick={handleClipPlayOnClick}>
                <MdPlayArrow className="icon" />
              </div>
            </div>
          </div>
          <ReactPlayer 
            className='player'
            url={clipSelected} 
            height='100%'
            width='100%'
            controls={false}
            playsinline={true}
            playIcon={<MdPlayArrow/>}
            playing={clipPlaying}
          />
        </div>
      }
      <Container>
        <div className="name mt-2">
          <div className="title">{process.title}</div>
          <div className="coll mt-1">{process.collabname}</div>
        </div>
        <div className="meta mt-1">
          <span className="dura">{'Prep: ' + process.timeprepmin + ' min - Cook: '  + process.timecookmin + ' min'}</span>
          <span className="divi"> - </span>
          <span className="publ">{process.date}</span>
        </div>
      </Container>
      <Container className="sectitle video">
        <Row>
          <Col xs={6} className="meter left">
            <div className={"tab" + (vidModeSelected === "steps" ? " active" : "")} onClick={(e) => handleVidModeTabOnClick('steps')}>
              <div className="icon">
                <MdOutlineVideoLibrary />
              </div>
              <span className="subtitle steps">Steps</span>
            </div>
          </Col>
          <Col xs={6} className="meter right">
            <div className={"tab" + (vidModeSelected === "full" ? " active" : "")} onClick={(e) => handleVidModeTabOnClick('full')}>
              <div className="icon">
                <BsPersonSquare />
              </div>
              <span className="subtitle full">Insights</span>
            </div>
          </Col>
        </Row>
      </Container>
      {vidModeSelected === "full" &&
        <div className="vid">
          <ReactPlayer 
            className='player'
            url='https://d1gdfy8gb2r35v.cloudfront.net/9f4d1883-40a5-4018-9745-3262cb11eaba/hls/Cuatro Wellness Test0001 A.m3u8' 
            height='100%'
            width='100%'
            style={{
              maxWidth: '100vh'
            }}
            config={{
              file: {
                forceDash: true
              }
            }}
            controls={true}
            playsinline={true}
          />
        </div>
      }
      {vidModeSelected === "steps" &&
        <div className="clipgridwrap">
          <Container className="clipgrid">
            {renderVideoGrid()}
          </Container>
        </div>
      }
      <Container className="sectitle tools">
        <Row>
          <Col xs={6} className="meter left">
            <div className={"tab" + (listSelected === "ingreds" ? " active" : "")} onClick={(e) => handleSecTitleTabOnClick('ingreds')}>
              <div className="ingredsReadyProg">
                <CircularProgressbar 
                  value={readyIngredsCt} 
                  maxValue={process.ingredients.length} 
                  text={`${readyIngredsCt}`}
                  strokeWidth={12}
                />
              </div>
              <span className="subtitle">Ingredients</span>
            </div>
          </Col>
          <Col xs={6} className="meter right">
            <div className={"tab" + (listSelected === "tools" ? " active" : "")} onClick={(e) => handleSecTitleTabOnClick('tools')}>
              <div className="toolsReadyProg">
                <CircularProgressbar 
                  value={readyToolsCt} 
                  maxValue={process.tools.length} 
                  text={`${readyToolsCt}`}
                  strokeWidth={12}
                />
              </div>
              <span className="subtitle tools">Tools</span>
            </div>
          </Col>
        </Row>
      </Container>
      {listSelected === "ingreds" &&
        <div className="cardd-container-wrap ingreds">
          <div className="cardd-container">
            {renderedListItems()}
          </div>
        </div>
      }
      {listSelected === "tools" &&
        <div className="cardd-container-wrap tool">
          <div className="cardd-container">
            {renderedListItems()}
          </div>
        </div>
      }
    </div>
  );
}